import {Injectable} from '@angular/core';
import {AbstractKolibriScriptEventHandler} from '@wspsoft/frontend-backend-common';
import {ModelService} from '../../../api';
import {KolibriScriptExecutor} from './kolibri-script-executor';

@Injectable()
export class KolibriScriptEventHandler extends AbstractKolibriScriptEventHandler {

  public constructor(modelService: ModelService, scriptExecutor: KolibriScriptExecutor) {
    super(modelService, scriptExecutor);
  }
}
