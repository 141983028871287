import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class BackendButtonService extends AbstractService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/backendButton';
  }

  public executeBackendButton(formId: string, buttonId: string, record: KolibriEntity, recordOld: KolibriEntity,
                              recordParent?: KolibriEntity): Promise<KolibriEntity> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/${formId}/${buttonId}`, {
      record, recordOld, recordParent
    }, queryParameters, headers);
  }

  public executeBackendSave(formId: string, record: KolibriEntity): Promise<KolibriEntity> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPut(`${this.basePath}/${formId}`, {
      record
    }, queryParameters, headers);
  }
}
