import {HttpClient} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {AbstractEntityServiceFactory, EntityServiceOptions, KolibriEntity, User, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {Configuration} from '../../configuration';

import {ModelService} from '../coded/model.service';

import {CircularService} from '../util/circular.service';

import {EntityService} from './entity.service';

@Injectable()
export class EntityServiceFactory extends AbstractEntityServiceFactory {
  public constructor(modelService: ModelService, public httpClient: HttpClient, public circularService: CircularService,
                     @Optional() public configuration?: Configuration) {
    super(modelService);
  }

  /**
   *
   */
  public getService<E extends KolibriEntity>(entityName: string, user: User = null, {
    changeTracking = true, descendants = false
  }: EntityServiceOptions = {}): EntityService<E> {
    const {entity, application} = this.getModelData(entityName);
    return new EntityService<E>(this, entity, this.modelService, this.circularService.jsCompiler,
      this.circularService.currentUser,
      `/api/rest/private/${_.decapitalize(application.name)}/${Utility.restifyName(entity.name)}`,
      {descendants, changeTracking});
  }
}
