<login-authentication-page (onEnter)="save()">
  <ng-template #header>
    <ng-container *ngIf="!passwordChangeRequested">
      <span class="one-login__greeting--big one-login__greeting--welcome">{{ 'Login.PasswordReset.Greeting' | translate }} </span>
      <span>{{ 'Login.PasswordReset.Welcome' | translate }}</span>
    </ng-container>

    <ng-container *ngIf="passwordChangeRequested && success">
      <span class="one-login__greeting--big one-login__greeting--success">
        <i class="fa fa-fw fa-check-circle"></i> {{ 'Login.SuccessHeader' | translate }} </span>
    </ng-container>
    <ng-container *ngIf="passwordChangeRequested && success === false">
      <span class="one-login__greeting--big one-login__greeting--error">
        <i class="fa fa-fw fa-exclamation-triangle"></i> {{ 'Login.ErrorHeader' | translate }} </span>
    </ng-container>

  </ng-template>
  <ng-template #content>
    <ng-container *ngIf="!passwordChangeRequested">
      <form #form="ngForm" class="one-login__form" method="post">
        <div class="one-login__form-fields" ngModelGroup="username">
          <ui-input-text [(ngModel)]="username" [updateWhileTyping]="true" [require]="true" label="{{'Login.PasswordReset.Username' | translate}}"
                         name="username"></ui-input-text>
        </div>
        <footer class="one-login__footer">
          <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sync"
                     label="{{'Login.PasswordReset.Submit' | translate}}" styleClass="one-login__button" type="primary"></ui-button>
          <ui-button (clicked)="redirectToLogin()" [spinner]="false" icon="fas fa-fw fa-sign-in-alt" label="{{'Login.PasswordReset.BackToLogin' | translate}}"
                     type="layout"></ui-button>
        </footer>
      </form>
    </ng-container>

    <ng-container *ngIf="passwordChangeRequested">
      <div class="one-login__form">

        <ng-container *ngIf="passwordChangeRequested && success">
          {{ 'Login.PasswordReset.Success' | translate }}
        </ng-container>
        <ng-container *ngIf="passwordChangeRequested && success === false">
          {{ 'Login.PasswordReset.Error' | translate }}
        </ng-container>

        <footer class="one-login__footer">
          <ui-button *ngIf=" success " (clicked)="redirectToLogin()" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt"
                     label="{{'Login.PasswordReset.BackToLogin' | translate}}" styleClass="one-login__button" type="primary"></ui-button>
          <ui-button *ngIf=" success === false" (clicked)="reset($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-undo"
                     label="{{'Login.PasswordReset.Retry' | translate}}" styleClass="one-login__button" type="primary"></ui-button>
        </footer>
      </div>
    </ng-container>
  </ng-template>
</login-authentication-page>
