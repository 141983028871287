import {Injectable} from '@angular/core';
import {AbstractKolibriScriptExecutor, DisplayTransformation, ScriptParams} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {CircularService} from '../../../../api';
import {Converter} from './converter';

@Injectable()
export class DisplayTransformationConverterService<T = any, X = string> implements Converter<T, X> {

  public displayTransform: DisplayTransformation;
  public formId: string;

  public constructor(private circularService: CircularService) {
  }

  public get needsConversionScript(): string {
    return this.displayTransform?.needsConversionScript;
  }

  private get scriptExecutor(): AbstractKolibriScriptExecutor {
    return this.circularService.scriptExecutor;
  }

  public hasNeedsConversion(): MaybePromise<boolean> {
    return !!this.needsConversionScript;
  }

  public needsConversion(params: ScriptParams): MaybePromise<boolean> {
    return this.scriptExecutor.runScript<boolean>(this.needsConversionScript, params, this.formId).result;
  }

  public getAsObject(entitiesString: X): MaybePromise<T>;
  public getAsObject(entitiesString: X[]): MaybePromise<T[]>;
  public getAsObject(entitiesString: X | X[]): MaybePromise<T | T[]> {
    if (!this.displayTransform) {
      return entitiesString as any;
    }


    const result = this.scriptExecutor.runScript<{ value: T | T[] }>(this.displayTransform.transformScript, {newValue: entitiesString},
      this.formId, `TransformationEntity:${this.displayTransform.name}:transformScript`).result;
    return _.isPromise(result) ? (result as Promise<{ value: T | T[] }>).then(
      x => x?.value ?? entitiesString as any) : (result as { value: T | T[] })?.value ?? entitiesString as any;
  }

  public getAsString(entities: T): MaybePromise<X>;
  public getAsString(entities: T[]): MaybePromise<X[]>;
  public getAsString(entities: T | T[]): MaybePromise<X | X[]> {
    if (!this.displayTransform) {
      return entities as any;
    }

    const result = this.scriptExecutor.runScript<{ value: X | X[] }>(this.displayTransform.backwardTransformScript, {newValue: entities},
      this.formId, `TransformationEntity:${this.displayTransform.name}:transformScript`).result;
    return _.isPromise(result) ? (result as Promise<{ value: X | X[] }>).then(
      x => x?.value ?? entities as any) : (result as { value: X | X[] })?.value ?? entities as any;
  }
}
