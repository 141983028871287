/**
 * normalizes all given parameters as versions
 * @param parameters the index of all params to normalize
 * @param versionArr the array to normalize with
 */
export function NormalizeVersion(parameters: number[] = [0, 1], versionArr: string[] = ['23', '0', '0']): MethodDecorator {
  return (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor): any => {
    const originalMethod = descriptor.value;
    // wrapping the original method
    descriptor.value = function (...args: any[]) {
      const newArgs = args
        .map((value, index) => parameters.includes(index) ? normalizeVersion(value as unknown as string) : value);
      return originalMethod.apply(this, newArgs);
    };
  };

  function normalizeVersion(version: string): string {
    // no version given
    if (!version || version === 'N/A') {
      return '0.0.0';
    }
    const vArr = version.split('.').map(v => v === '' ? '0' : v);
    // version should be ok
    if (vArr.length === 3) {
      return version;
    }
    if (vArr.length < 3) {
      // when the values are less than three it is not a semantic version so normalize
      // with version '10.1' and versionArr ['23', '0', '0'] the result will be 23.10.1
      // with version '1' and versionArr ['23', '0', '0'] the result will be 23.0.1
      return [...versionArr.slice(0, -vArr.length), ...vArr].join('.');
    } else {
      // when there are more than 3 the version should be shortened to 3
      return vArr.slice(0, 3).join('.');
    }
  }
}
