import {MaybePromise} from '@wspsoft/underscore';
import {KolibriEntity} from './kolibri-entity';


export abstract class MessageTemplateI18n extends KolibriEntity {
  public body?: any;
  public bodyData?: MaybePromise<any>;
  public locale?: string;
  public messageTemplateId?: any;
}
