import * as moment from 'moment';
import {I18n} from './i18n';
import {KolibriEntity} from './kolibri-entity';

export enum ReportStatus {
  SENT = 'SENT', READ = 'READ', ERROR = 'ERROR', PROCESSING = 'PROCESSING'
}

export class Report extends KolibriEntity {
  public name?: string;
  public deliveredAt?: string | moment.Moment;
  public recipients?: string[];
  public ccRecipients?: string[];
  public status?: ReportStatus;
  public emailText?: I18n;
}
