import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as chroma from 'chroma-js';

@Component({
  selector: 'ui-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PillComponent implements OnInit {
  @Input()
  public rounded: boolean = false;
  @Input()
  public color: string;
  @Input()
  public icon: string;
  @Input()
  public label: string;
  public labelColor: string = 'current';

  public ngOnInit(): void {
    const value = chroma(this.color || '#CCCCCC');
    const lum = value.luminance();
    this.labelColor = value.luminance(lum > 0.5 ? lum - 0.5 : lum + 0.5).hex(); // reduce or raise luminance by 50%
  }
}
