import {Router, UrlTree} from '@angular/router';
import {CriteriaQueryGroupJson} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import * as uuidv4 from 'uuid-random';

export abstract class PortalUtil {
  public static isVisible(renderedExec: { visible?: boolean }[]): boolean {
    return _.some(renderedExec, 'visible');
  }

  public static getUrlWithLayoutId(router: Router, layoutId: string): string {
    const urlTree = router.parseUrl(router.url);
    urlTree.queryParams.layoutId = layoutId;
    return urlTree.toString();
  }

  public static getUrlWithoutRandomParts(router: Router): string {
    return PortalUtil.getUrlTreeWithoutRandomParts(router).toString();
  }

  public static getUrlTreeWithoutRandomParts(router: Router): UrlTree {
    const urlTree = router.parseUrl(router.url);
    delete urlTree.queryParams.layoutId;
    delete urlTree.queryParams.prevNext;
    return urlTree;
  }

  /**
   * Removes any query that is appended to the url
   * @return A url without any queries
   */
  public static getUrlWithoutQuery(router: Router): string {
    const urlTree = router.parseUrl(router.url);
    urlTree.queryParams = {};
    return urlTree.toString();
  }

  /**
   * waits for the content div to appear in the dom
   */
  public static async waitForDivRendering(contentId: string): Promise<void> {
    while (true) {
      if (await new Promise(resolve => {
        if (document.getElementById(contentId)) {
          resolve(true);
        } else {
          setTimeout(() => resolve(false), 300);
        }
      })) {
        break;
      }
    }
  }

  public static addQueryIds(value: CriteriaQueryGroupJson): void {
    value.id ??= uuidv4();

    for (const group of value.groups) {
      this.addQueryIds(group);
    }

    for (const criteriaConditionJson of value.whereCondition) {
      criteriaConditionJson.id ??= uuidv4();
    }
  }
}
