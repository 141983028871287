import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {User, UserHotkeyOverwriteData} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {UiUtil} from '../util/ui-util';

class HotkeyBinding {
  private keys: string[];

  /**
   * @param [id] target button id from model
   * @param [commandFunction] target button selector
   * @param [keys] hotkeys (e.g. Control+f)
   * @param [weight] weight of the hotkey. If there are multiple hotkeyBindings with the same hotkeys combination, the key with the highest
   * weight will be executed
   */
  public constructor(public id: string, keys: string, private commandFunction?: ($event: KeyboardEvent) => MaybePromise<void>, public weight: number = 0) {
    this.keys = (UiUtil.getKeysFromHotkey(keys)).map(key => key === 'Space' ? ' ' : key).map((key) => key.toLowerCase());
  }

  /**
   * resets the key binding
   */
  public reset(): void {
    this.keys.length = 0;
  }

  /**
   * adds the key to the binding
   */
  public addKey(key: string): void {
    this.keys.push(key);
  }

  /**
   * returns boolean for whether the specified key is part of the binding
   */
  public hasKey(key: string): boolean {
    return this.keys.indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * returns bool for whether the specified key combination is a hotkey
   */
  public isHotkey(pressedKeys: string[]): boolean {
    // all keys have to be pressed
    if (pressedKeys.length !== this.keys.length) {
      return false;
    }

    return pressedKeys.every(key => this.hasKey(key));
  }

  /**
   * triggers click event on hotkey target
   * The commandFunction is executed with a delay for angular to register the blur and execute potential actions like validations
   */
  public triggerHotkey($event: KeyboardEvent): void {
    $event.preventDefault();
    // @ts-ignore
    document.activeElement.blur();
    this.commandFunction($event);
  }

  public toString(): string {
    return this.keys.join('+');
  }
}


@Injectable()
export class HotkeyService {

  private pressedKeys: string[] = [];
  private hotkeyDefinitions: HotkeyBinding[] = [];
  private prevHotkeyDefinitions: HotkeyBinding[][] = [];
  private userHotkeyOverwrites: UserHotkeyOverwriteData = {};

  public constructor(private translateService: TranslateService) {
  }

  public static convertHotkeyForOs(key: 'Control' | 'Meta' | 'Alt' | 'Shift' | string): string {
    if (!key) {
      return '';
    }

    if (UiUtil.isOS('Mac')) {
      switch (key.toLowerCase()) {
        case 'control':
          return 'Meta';
        case 'meta':
          return 'Control';
        case 'alt':
        default:
          return key;
      }
    }
    return key;
  }

  private static translateHotkeyForOs(key: 'Control' | 'Meta' | 'Alt' | 'Shift' | string): string {
    if (UiUtil.isOS('Mac')) {
      switch (key?.toLowerCase()) {
        case 'control':
          return 'mac.meta';
        case 'meta':
          return 'mac.control';
        case 'alt':
          return 'mac.alt';
        default:
          return key;
      }
    }
    return key;
  }

  public init(user: User): void {
    this.userHotkeyOverwrites = user.activeProfile.sessionSettings.userHotkeyOverwrites;
  }

  /**
   * Pushes existing hotkeyDefinitions onto array in hotkeyService.
   * This function is used to temporarily shelve hotkeys when opening a blocking dialog/wizard.
   * These hotkeys can be retrieved again if needed. Simply unShelveHotkeys() to get the hotkeys back that were pushed onto the array last.
   **/
  public shelveHotkeys(): void {
    this.prevHotkeyDefinitions.push(this.hotkeyDefinitions);
    this.hotkeyDefinitions = [];
  };

  /**
   * Pops the last hotkeyDefinitions from the array in the hotkeyService.
   * This function is used to unshelve the hotkeys when closing a blocking dialog/wizard.
   */
  public unshelveHotkeys(): void {
    this.hotkeyDefinitions = this.prevHotkeyDefinitions.pop();
  }

  /**
   * add specific hotkey binding
   */
  public addBinding(id: string, commandFunction: (event: KeyboardEvent) => void, keys: string, weight: number = 0): void {
    if (!_.find(this.hotkeyDefinitions, (h) => h.toString() === keys?.toLowerCase() && h.weight === weight && h.id === id)) {
      keys = this.userHotkeyOverwrites[id] ?? keys;
      if (keys) {
        const hotkeyBinding = new HotkeyBinding(id, keys, commandFunction, weight);
        const indexToAdd = _.sortedLastIndexBy(this.hotkeyDefinitions, hotkeyBinding, 'weight');
        this.hotkeyDefinitions.splice(indexToAdd, 0, hotkeyBinding);
      }
    }
  }

  /**
   * remove specific hotkey binding
   */
  public removeBinding(id: string, weight?: number): void {
    if (weight || weight === 0) {
      _.remove(this.hotkeyDefinitions, {id, weight});
    } else {
      _.remove(this.hotkeyDefinitions, {id});
    }
  }

  /**
   * add pressed key to list
   */
  public keydown($event: KeyboardEvent): void {
    if ($event.key) {
      const key = HotkeyService.convertHotkeyForOs($event.key).toLowerCase();
      if (!this.hasKey(key)) {
        this.pressedKeys.push(key);
      }
      // check if any of the registered definitions matches the current key combo and execute heaviest first
      // hotkeys are sorted ascending, so search in reverse
      for (let i = this.hotkeyDefinitions.length - 1; i >= 0; i--) {
        const def = this.hotkeyDefinitions[i];
        if (def.isHotkey(this.pressedKeys)) {
          def.triggerHotkey($event);
          this.keyup();
        }
      }
    }
  }

  /**
   * reset keys
   */
  public keyup(): void {
    this.pressedKeys.length = 0;
  }

  public translateHotkey(key: string): string {
    if (key) {
      const hotkeyParts = UiUtil.getKeysFromHotkey(key);
      const returnValue = [];
      for (let part of hotkeyParts) {
        part = this.isSpecialKey(part) ? this.translateService.instant('Key.' + HotkeyService.translateHotkeyForOs(part).toUpperCase()) : part.toUpperCase();
        returnValue.push(_.capitalize(part.toLowerCase()));
      }
      return `${returnValue.join('+')}`;
    }
  }

  public isSpecialKey(key: string): boolean {
    return key === 'Control' || key === 'Meta' || key === 'Alt' || key === 'Shift';
  }

  private hasKey(key: string): boolean {
    return this.pressedKeys.indexOf(key.toLowerCase()) !== -1;
  }
}
