import {Injectable} from '@angular/core';
import {User, RecordDiff} from '@wspsoft/frontend-backend-common';
import {Subscription} from 'rxjs';
import {BackendSessionService} from '../../../api';

import {SessionService} from '../../../portal/app/service/session.service';
import {KolibriObservableService} from './kolibri-observable.service';

@Injectable()
export class KolibriSession {
  public constructor(private sessionService: SessionService, private kolibriObservableService: KolibriObservableService,
                     private backendSessionService: BackendSessionService) {
    window.addEventListener('storage', (e) => {
      this.kolibriObservableService.emit(e.key, this.getValue(e.key));
    });
  }

  /**
   * returns the current user who owns this session
   *
   * @return the current user
   */
  public get user(): User {
    return this.sessionService.currentUser;
  }

  /**
   * Updates the current user to display changes immediately
   */
  public async updateUser(changes: RecordDiff[]): Promise<void> {
    const appliedDifferences = await this.backendSessionService.updateUser(changes);
    for (const diff of appliedDifferences) {
      this.sessionService.currentUser[diff.field] = diff.newValue;
    }
  }

  /**
   * gets the value of the session variable with the given name
   *
   * @param name the name of the variable
   */
  public getValue<T>(name: string): T {
    const item = localStorage.getItem(name);
    if (item) {
      return JSON.parse(item).value;
    }
  }

  /**
   * sets the value of the session variable with the given name
   *
   * @param name  the name of the variable
   * @param value the value of the variable
   */
  public setValue<T>(name: string, value: T): void {
    localStorage.setItem(name, JSON.stringify({value}));
    this.kolibriObservableService.emit(name, value);
  }

  /**
   * returns the value of the session variable with given name and removes the entry
   *
   * @param name the name of the variable
   * @return the value of the session variable
   */
  public removeValue<T>(name: string): T {
    const result = this.getValue<T>(name);
    localStorage.removeItem(name);
    this.kolibriObservableService.emit(name, result);
    return result;
  }

  /**
   * listen on the name
   *
   * executes a function when a new value is set with the given name
   */
  public on<T>(name: string, fn: (value: T) => any): Subscription {
    return this.kolibriObservableService.on(name, fn) as Subscription;
  }

  /**
   * listen on a name and then REMOVE the listeners
   */
  public once<T>(name: string, fn: (value: T) => any): void {
    this.kolibriObservableService.once(name, fn);
  }
}
