import {Injectable} from '@angular/core';
import {AbstractKolibriScriptLibrary} from '@wspsoft/frontend-backend-common';
import {Socket} from 'socket.io';
import {
  ActivityLogService,
  AttachmentService,
  CriteriaFactory,
  DocumentGeneratorService,
  FileService,
  FlowService,
  ModelDeploymentService,
  ModelService,
  SettingsService
} from '../../../api';
import {DynamicContentService, GuidedTourService, OneDialogService, SideBarService, UiUtil} from '../../../ui';
import {KolibriHttpClient} from './kolibri-http-client';
import {KolibriMessage} from './kolibri-message';
import {KolibriObservableService} from './kolibri-observable.service';
import {KolibriRecordFactory} from './kolibri-record-factory';
import {KolibriRouter} from './kolibri-router';
import {KolibriSecurityService} from './kolibri-security.service';
import {KolibriSession} from './kolibri-session';
import {KolibriTemplate} from './kolibri-template';
import {KolibriTranslate} from './kolibri-translate';
import {KolibriVariableService} from './kolibri-variable.service';

@Injectable()
export class KolibriScriptLibrary extends AbstractKolibriScriptLibrary {
  public socket: Socket;
  public uiUtil: typeof UiUtil = UiUtil;

  public constructor(
    public session: KolibriSession, public dynamicContent: DynamicContentService,
    public router: KolibriRouter, public sideBar: SideBarService, public flowService: FlowService,
    public dialog: OneDialogService, public guidedTour: GuidedTourService, public attachment: AttachmentService, http: KolibriHttpClient,
    record: KolibriRecordFactory, criteria: CriteriaFactory, message: KolibriMessage,
    modelService: ModelService, observableService: KolibriObservableService,
    translate: KolibriTranslate, template: KolibriTemplate, settings: SettingsService,
    activity: ActivityLogService, security: KolibriSecurityService, variables: KolibriVariableService,
    deployment: ModelDeploymentService, documents: DocumentGeneratorService, file: FileService) {
    super(record, criteria, http, message, modelService, observableService, translate, template, activity, deployment, documents, file,
      security, settings, variables);
  }
}
