import {CriteriaOrder} from '../../criteria/criteria-order';
import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {AggregationFunction} from '../xml/aggregation-function';
import {FlowElement} from './flow-element';
import {KolibriEntity} from './kolibri-entity';
import {ShareableEntity} from './shareable-entity';

export abstract class WidgetDataSet extends KolibriEntity {
  public name: string;
  public order: number;
  public aggregationFunction: AggregationFunction;
  public aggregationField: string;
}

export class Widget extends ShareableEntity {
  public type: WidgetType;
  public entity: string;
  public icon: string;

  // query
  public query: CriteriaQueryJson;
  public count: number = 5;
  public limit: boolean = false;
  public descendants: boolean = false;

  // order
  public order: CriteriaOrder = CriteriaOrder.DESC;
  public orderType: WidgetOrderType;
  public orderValues: string[];

  // link
  public url: string;
  public openInNewTab: boolean;

  // table
  public columns: string[] = [];

  // aggregation
  public advancedConfig: boolean = false;
  public aggregationFunction: AggregationFunction;
  public aggregationField: string;
  public datasets: WidgetDataSet[];

  // group by
  public groupBy: string;
  public granularity: WidgetGranularity;
  public startZoom: WidgetGranularity;
  public useOwnColor: boolean;

  // drilldown
  public disableDrilldown: boolean;
  public drilldownListId: string;

  // custom
  public cssContent: string;
  public htmlContent: string;
  public jsContent: string;

  // layout
  public layout: string;
  public initScript: FlowElement[];

  // gauge
  public gradient: Gradient;
  public gaugeType: GaugeType;
  public gaugeMaxType: GaugeMaxType;
  public gaugeThreshold: GaugeThreshold;
  public fixedGaugeValue: number;
  public scriptGaugeValue: FlowElement[];
  public customThreshold: number;
  public thresholdInPercent: boolean;
}

export enum WidgetType {
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  COLUMN_CHART = 'COLUMN_CHART',
  COUNT = 'COUNT',
  BAR_CHART = 'BAR_CHART',
  LINK = 'LINK',
  TABLE = 'TABLE',
  PIE_CHART = 'PIE_CHART',
  LINE_CHART = 'LINE_CHART',
  GAUGE_CHART = 'GAUGE_CHART',
  DUMBBELL_CHART = 'DUMBBELL_CHART',
  CUSTOM = 'CUSTOM',
  LAYOUT = 'LAYOUT',
}

export enum WidgetGranularity {
  Hour = 'hour',
  DayOfMonth = 'dayOfMonth',
  Weekday = 'weekday',
  WeekOfYear = 'weekOfYear',
  Month = 'month',
  Year = 'year',
  Date = 'date',
}

export enum Gradient {
  redToLeft = 'redToLeft',
  redToRight = 'redToRight',
}

export enum GaugeType {
  absolute = 'absolute',
  percent = 'percent',
}

export enum GaugeMaxType {
  fixed = 'fixed',
  script = 'script',
}

export enum GaugeThreshold {
  none = 'none',
  custom = 'custom',
}

export enum WidgetOrderType {
  custom = 'custom', label = 'label', result = 'result',
}

export default Widget;
