import {ScriptResult} from '@wspsoft/frontend-backend-common';
import {Subscription} from 'rxjs';
import {ViewData} from '../../../portal';

export abstract class ScriptUtil {

  /**
   * Saves subscriptions into the provided subscriptions-object for all usedFields of the ScriptResult object
   * and executes the provided function when a change is detected.
   */
  // noinspection DuplicatedCode
  public static subscribeToChanges(subscriptions: { [key: string]: { [key: string]: Subscription } }, type: string,
                                   result: ScriptResult<any>, fn: () => void, viewData?: ViewData): void {
    if (!(type in subscriptions)) {
      subscriptions[type] = {};
    }

    for (const key of Object.keys(result.usedFields)) {
      // prevent duplicated subscribe
      if (!(key in subscriptions[type])) {
        const observable = result.usedFields[key];
        subscriptions[type][key] = observable.subscribe(fn);
      }
    }

    if (!('submit' in subscriptions[type]) && viewData?.currentForm) {
      subscriptions[type].submit = viewData.currentForm.refresh.subscribe(fn);
    }

    if (!('selectionMode' in subscriptions[type]) && viewData?.currentList) {
      subscriptions[type].selectionMode = viewData.currentList.selectionModeChanged.subscribe(fn);
    }
  }
}
