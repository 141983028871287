import {KolibriEntity} from '../kolibri-entity';

export class ImportDataSourceDefinition extends KolibriEntity {
  public type: string;
  public readMethod: string;
  public onFinishMethod: string;
}

export class ImportDataSource extends KolibriEntity {

}
