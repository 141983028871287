import {_} from '@wspsoft/underscore';
import {VariableJson} from '../../../model/database/json/variable-json';
import {VariableEntity} from '../../../model/database/variable/variable-entity';
import {LazyLoaderHandler} from './lazy-loader-handler';
import {EnhancementLevel} from './transient-handler';

export class VariableHandler<T extends VariableEntity> {
  public readonly enhanced: EnhancementLevel = EnhancementLevel.Variable;
  public currentVariables: Record<string, VariableJson>;

  public constructor(public record: LazyLoaderHandler<T>, field: string) {
    this.init(record.record[field]);
  }

  public init(variables: any): void {
    // here it is still a raw value
    this.currentVariables = {};
    if (!_.isNullOrEmpty(variables)) {
      this.setupVariables(Array.isArray(variables) ? variables : Object.values(variables));
    }
  }

  public setupVariables(variables: VariableJson[]): void {
    for (const variable of variables) {
      this.setupVariable(variable);
    }
  }

  public isEmpty(): boolean {
    return _.isEmpty(this.currentVariables);
  }

  public clone(): Record<string, VariableJson> {
    return _.cloneDeep(this.currentVariables);
  }

  public toJSON(): Record<string, VariableJson> {
    return this.isEmpty() ? null : this.currentVariables;
  }

  public keys(): string[] {
    return Object.keys(this.currentVariables);
  }

  private setupVariable(variable: VariableJson): void {
    this.currentVariables[variable.name] ??= variable;
    Object.defineProperty(this, variable.name, {
      get() {
        this.record._onRead?.(variable.name);
        return this.currentVariables[variable.name].value;
      },
      set(value: any) {
        const oldValue = this.currentVariables[variable.name].value;
        this.currentVariables[variable.name].value = value;
        if (value !== oldValue) {
          this.record._onWrite?.(variable.name, value, oldValue);
        }
      },
      enumerable: true,
      configurable: true
    });
  }
}
