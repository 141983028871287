<div cdkDropListGroup class="one-kanban {{parentViewMode !== LayoutViewMode.PRESENTATION ? 'one-kanban--standard' : 'one-kanban--widget'}}">
  <header class="one-section__header">
    <h2 *ngIf="title" class="one-kanban__title">{{title}}</h2>
    <ng-container *ngIf="header" [ngTemplateOutlet]="header"></ng-container>

    <div class="one-section__tools">
      <ng-container *ngIf="tools" [ngTemplateOutlet]="tools"></ng-container>
      <ng-container *ngTemplateOutlet="toolbarButton ; context: {table: this}"></ng-container>
      <ui-guided-tour-step reservedStepName="resDataViewGlobalSearchStep">
        <ui-search-input (search)="doGlobalSearch($event)" *ngIf="globalSearch" [value]="state.globalFilter" name="search"></ui-search-input>
      </ui-guided-tour-step>
    </div>
  </header>
  <div *ngIf="subHeader" class="one-section__subheader">
    <ng-container [ngTemplateOutlet]="subHeader"></ng-container>
  </div>
  <div class="one-kanban__content">
    <div *ngFor="let column of columns; let c = index" class="one-kanban__column {{column.state.expanded ? 'one-kanban__column--expanded' :
    'one-kanban__column--collapsed' }}"
         style="--kanbanColumnColor: {{getColumnColor(column.color)}}; --kanbanColumnColorContrast: {{getInvertedColor(column.color)}}">
      <header (click)="toggle(column)" class="one-kanban__header">
        <div class="one-kanban__header-icon">
          <i class="{{column.icon}}"></i>
        </div>
        <div class="one-kanban__header-title">
          <span [innerHTML]="column.label"></span>
          <ng-container *ngIf="column.value.length">
            <div class="p-badge">{{column.value.length}}</div>
          </ng-container>
        </div>
        <div class="one-kanban__header-button">
          <ui-button (clicked)="$event.cb(); add(column)" *ngIf="column.add" [stopPropagate]="true" class="one-kanban__button" icon="fas fa-fw fa-plus"
                     type="icon-only" label="{{'List.Actions.Create' | translate}}"></ui-button>
        </div>
      </header>
      <div (cdkDropListDropped)="moveItem(column, $event)" (scrolled)="loadEntries(column, true)" *ngIf="column.state.expanded" [cdkDropListData]="column.value"
           [cdkDropListDisabled]="!column.move" [cdkDropListEnterPredicate]="moveCondition(column)" [cdkDropListSortPredicate]="sortCondition(column)"
           [cdkDropListSortingDisabled]="!column.sort" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" cdkDropList
           class="one-kanban__item-list" infiniteScroll>
        <div *ngIf="!column.value.length" class="one-kanban__item">
          <div class="one-kanban__item-content">
            {{'AutoComplete.NoResults' | translate}}
          </div>
        </div>
        <div (mouseenter)="hovered = !dragging ? c + item.id : ''" (mouseleave)="hovered = ''" (cdkDragStarted)="dragging = true"
             (cdkDragEnded)="dragging = false" *ngFor="let item of column.value; let i = index" [cdkDragData]="item" cdkDrag
             class="one-kanban__item one-kanban__item--draggable">
          <div [ngClass]="{'one-kanban__item-content--clickable': column.edit}" class="one-kanban__item-content">
            <ng-container *ngIf="itemTemplate">
              <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
            </ng-container>
            <ng-container *ngIf="!itemTemplate">
              <ui-field-translator [data]="item" [field]="column.displayField"></ui-field-translator>
            </ng-container>
          </div>
          <div *ngIf="column.edit && hovered === c + item.id" class="one-kanban__item-overlay">
            <ui-button (clicked)="edit(item, column, $event.originalEvent)" [spinner]="false" [stopPropagate]="true" label="{{'List.Actions.Edit' | translate}}"
                       class="one-kanban__button one-kanban__item-button" icon="fas fa-fw fa-pen" type="icon-only"></ui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
