import {DesignerEntity} from './designer.entity';
import {LayoutSection} from './layout-section';
import {SectionTab} from './section-tab';

/**
 * Connection between configured fields and sections
 */
export abstract class SectionTabToLayoutSection extends DesignerEntity {
  public layoutSection?: LayoutSection;
  public layoutSectionId?: string;
  public sectionTab?: SectionTab;
  public sectionTabId?: string;
  public x: number;
  public y: number;
  public rows: number;
  public cols: number;
}
