import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractKolibriHttpClient} from '@wspsoft/frontend-backend-common';

@Injectable()
export class KolibriHttpClient extends AbstractKolibriHttpClient {
  public constructor(httpService: HttpClient) {
    super(httpService);
  }
}
