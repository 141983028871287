import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {KolibriEntity, User} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class AttachmentService extends AbstractService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/attachment';
  }

  public async setBaseToAttachment(record: KolibriEntity, field: string, publish: boolean = false, user: User): Promise<void> {
    record[field] = await this.replaceAnyBase64(record[field], record.id, publish, user);
  }

  public async replaceAnyBase64(content: string | string[], recordId: string, publish: boolean = false, user: User): Promise<string | string[]> {
    const {queryParameters, headers} = this.getHeaders();

    return (await this.doPost<{ payload: string }>(`${this.basePath}/replaceAnyBase64`, {content, recordId, publish, user}, queryParameters, headers)).payload;
  }
}
