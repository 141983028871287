import {LegacyTemplate, LegacyTemplateType, TemplateEntryJson} from '../../model/database/json/template';
import {TemplatePayload} from '../../model/database/template';
import {_} from '@wspsoft/underscore';

export enum TemplateEntryOperator {
  clear = 'clear', set = 'set', add = 'add', script = 'script'
}

/**
 * interface to define one entry
 */
export interface TemplateEntry {
  /**
   * the label of the field to select
   */
  key?: string;
  /**
   * the value to set for the field
   */
  value?: any;
  /**
   * clear, set or add
   */
  operator?: TemplateEntryOperator;
}

export class TemplateEntity {
  public static readonly TEMPLATE_VERSION: string = '2.0';
  public static DEFAULT_OPERATOR = TemplateEntryOperator.set;
  public entries: TemplateEntry[];

  public constructor(template?: TemplatePayload) {
    if (template) {
      this.setEntries(template);
    } else {
      this.setEntries({});
    }
  }

  public getJson(): TemplatePayload {
    const template = {
      version: TemplateEntity.TEMPLATE_VERSION
    };

    for (const entry of this.entries) {
      if (entry.key && entry.key !== 'null' && entry.key !== 'undefined') {
        template[entry.key] = {value: entry.value, operator: entry.operator} as TemplateEntryJson;
      }
    }

    return template;
  }

  public removeEntry(entry: TemplateEntry, cb: () => void): void {
    _.remove(this.entries, (e) => e === entry);
    cb();
  }

  public addEntry(cb?: () => void): void {
    this.entries.push({operator: TemplateEntity.DEFAULT_OPERATOR});
    cb?.();
  }

  private setEntries(template: TemplatePayload | LegacyTemplate): void {
    this.entries = [];

    switch (template.version) {
      case '2.0':
        for (const value of Object.entries(template)) {
          const propertyName = value[0];
          if (propertyName === 'version') {
            continue;
          }
          const templateEntryJson = value[1] as TemplateEntryJson;
          this.entries.push({key: propertyName, value: templateEntryJson.value, operator: (templateEntryJson.operator || TemplateEntity.DEFAULT_OPERATOR)});
        }
        break;
      default:
        // convert template from older version
        if ((template as LegacyTemplate).fields) {
          // @ts-ignore
          for (const templateEntry of template.fields as LegacyTemplateType) {
            this.entries.push(
              {key: templateEntry.key, value: templateEntry.value || templateEntry.fieldId || templateEntry.fieldIds, operator: TemplateEntryOperator.set});
          }
        }
        break;
    }
  }
}
