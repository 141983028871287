import {KolibriEntity} from './kolibri-entity';
import {User} from './user';

export enum ThirdPartyAccount {
  gitlab = 'gitlab',
  microsoftProvider = 'microsoftProvider'
}

export abstract class ThirdPartyCredential extends KolibriEntity {
  public account?: ThirdPartyAccount;
  public domain?: string;
  public password?: string;
  public username?: string;
  public name?: string;
  public expiresAt?: string;
  public user?: User;
  public config?: any;

  // oauth specific
  public identityProvider?: ThirdPartyCredential;
  public accessUrl?: string;
  public redirectUrl?: string;
  public authorizationUrl?: string;
  public accessToken?: string;
  public refreshToken?: string;
  public clientId?: string;
  public clientSecret?: string;
  public scope?: string[];
}
