import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {ChartResult, Widget} from '@wspsoft/frontend-backend-common';
import moment from 'moment';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class ChartService extends AbstractService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/charts';
  }

  public getChartData(widget: Widget, opts: any): Promise<ChartResult[]> {
    const {queryParameters, headers} = this.getHeaders();

    if (widget === null || widget === undefined) {
      throw new Error('Required parameter widget was null or undefined when calling getChartData.');
    }

    const utcOffset = moment().utcOffset() / 60;
    return this.doPost<ChartResult[]>(`${this.basePath}/${widget.entity}`, {
        widget: {
          ...widget.toJSON(),
          datasets: widget.datasets
        }, utcOffset, ...opts
      },
      queryParameters, headers);
  }
}
