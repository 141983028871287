import {KolibriEntity} from './kolibri-entity';
import {MessageTemplate} from './message-template';

export abstract class TemplateParameter extends KolibriEntity {
  public messageTemplate?: MessageTemplate;
  public messageTemplateId?: string;
  public name?: string;
  public entityName?: string;
  public multiple?: boolean;
}
