import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DateUnit} from '@wspsoft/frontend-backend-common';
import {ModelTranslationService} from '../../../../../api';

import {DateRangePreset} from '../../../entities/date-range-preset';
import {Converter} from '../../converter/converter';
import {CustomInput} from '../custom-input';

class DateUnitUi {
  public constructor(
    public unit: DateUnit,
    public label: string) {
  }
}


@Component({
  selector: 'ui-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateRangeSelectorComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeSelectorComponent extends CustomInput<DateRangePreset> implements OnInit {
  public availablePresets: DateRangePreset[] = [];
  public availableDateUnits: DateUnitUi[] = [];

  public constructor(public translate: TranslateService, private translationService: ModelTranslationService, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get dateUnitConverter(): Converter<DateUnitUi> {
    return {
      getAsString: (value: DateUnitUi) => {
        if (value) {
          return value.unit;
        }
      },
      getAsObject: (s: string) => {
        if (s) {
          const value = DateUnit[s];
          const translation = this.translationService.translateDateUnit(value);
          return new DateUnitUi(value, translation);
        }
      }
    };
  }

  public ngOnInit(): void {
    this.availablePresets = [{
      unit: DateUnit.day,
      fromToday: false,
      amount: 0,
      name: 'Today',
      label: this.translate.instant('DateRange.Today')
    },
      {
        unit: DateUnit.day,
        fromToday: false,
        amount: 1,
        name: 'Yesterday',
        label: this.translate.instant('DateRange.Yesterday')
      },
      {
        unit: DateUnit.week,
        fromToday: false,
        amount: 1,
        name: 'LastWeek',
        label: this.translate.instant('DateRange.LastWeek')
      },
      {
        unit: DateUnit.month,
        fromToday: false,
        amount: 0,
        name: 'ThisMonth',
        label: this.translate.instant('DateRange.ThisMonth')
      },
      {
        unit: DateUnit.month,
        fromToday: false,
        amount: 1,
        name: 'LastMonth',
        label: this.translate.instant('DateRange.LastMonth')
      },
      {
        unit: DateUnit.month,
        fromToday: false,
        amount: 3,
        name: 'LastThreeMonths',
        label: this.translate.instant('DateRange.LastThreeMonths')
      },
      {
        unit: DateUnit.month,
        fromToday: false,
        amount: 6,
        name: 'LastSixMonths',
        label: this.translate.instant('DateRange.LastSixMonths')
      },
      {
        unit: DateUnit.month,
        fromToday: false,
        amount: 12,
        name: 'LastTwelveMonths',
        label: this.translate.instant('DateRange.LastTwelveMonths')
      },
      {
        unit: DateUnit.day,
        fromToday: false,
        amount: 1,
        name: 'Custom',
        label: this.translate.instant('DateRange.Custom')
      }];

    this.getAllDateUnits();
  }

  public updateValue(): void {
    this.value = {...this.value};
  }

  private getAllDateUnits(): void {
    for (const operator in DateUnit) {
      if (operator in DateUnit) {
        const name = DateUnit[operator];
        const translation = this.translationService.translateDateUnit(name);
        this.availableDateUnits.push(new DateUnitUi(name, translation));
      }
    }
  }
}

