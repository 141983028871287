import {ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DisplayButtonType} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ButtonClickEvent} from '../entities/button-entities';
import {ButtonService} from '../service/button.service';


@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class AbstractButton implements OnInit, OnDestroy {
  @Input()
  public label: string = '';
  @Input()
  public hotkeyTooltip: string;
  @Input()
  public tooltipPosition: string = 'top';
  @Input()
  public styleClass: string = '';
  @Input()
  public disable: boolean = false;
  @Input()
  public spinner: boolean = true;
  @Input()
  public iconPosition: 'left' | 'right' | 'top' | 'bottom' = 'left';
  @Input()
  public inline: boolean = false;
  @Input()
  public inProgress: boolean = false;
  // when icon only display label as tooltip
  public iconOnly: boolean = false;
  public defaultStyleClass: string = '';
  /**
   * default sync click event, call $event.cb() when your function is ready
   */
  @Output()
  public clicked: EventEmitter<ButtonClickEvent> = new EventEmitter<ButtonClickEvent>();
  private pType: DisplayButtonType | DisplayButtonType[] | string | string[];

  protected constructor(protected buttonService: ButtonService, protected cdr: ChangeDetectorRef) {
  }

  public get ngClass(): string {
    return `${this.inProgress || this.disable ? 'one-button--disabled' : ''} ${this.defaultStyleClass} ${this.styleClass}`;
  }

  public get type(): DisplayButtonType | DisplayButtonType[] | string | string[] {
    return this.pType;
  }

  /**
   * sets the button type and some flags corresponding to it like icon only and default classes
   * @param type type of the button
   */
  @Input()
  public set type(type: DisplayButtonType | DisplayButtonType[] | string | string[]) {
    this.pType = type;
    const classes = [];
    let types = [];
    if (Array.isArray(type)) {
      types = type;
    } else {
      types.push(type);
    }
    for (const t of types) {
      classes.push(`one-button--${t}`);
      if (t === DisplayButtonType.ICON_ONLY || t === DisplayButtonType.TRAY || t === DisplayButtonType.TAB_ICON_ONLY) {
        this.iconOnly = true;
      }
      if (t === DisplayButtonType.INLINE) {
        this.inline = true;
        this.iconOnly = true;
      }
      if (t === DisplayButtonType.ICON_RIGHT) {
        this.iconPosition = 'right';
      }
      if (t === DisplayButtonType.FAB) {
        this.iconOnly = true;
        this.tooltipPosition = 'left';
      }
    }
    this.defaultStyleClass += (this.inline ? ' ' : 'one-button ') + classes.join(' ');
  }

  public get usedTooltip(): string {
    return this.iconOnly ? `${this.label}${this.hotkeyTooltip ? ` (${this.hotkeyTooltip})` : ''}` : '';
  }

  public get usedLabel(): string {
    return this.iconOnly ? ' ' : this.label;
  }

  public set progress(value: boolean) {
    this.inProgress = value;
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.buttonService.buttons.push(this);
  }

  public ngOnDestroy(): void {
    _.remove(this.buttonService.buttons, this);
  }
}
