import {Injectable} from '@angular/core';
import {
  AbstractKolibriScriptExecutor,
  AbstractKolibriTemplate,
  KolibriEntity,
  ScriptParams,
  Template,
  TemplatePayload,
  User
} from '@wspsoft/frontend-backend-common';
import {CircularService, CriteriaFactory, EntityServiceFactory, ModelService} from '../../../api';
import {RelationService} from '../../../api/app/service/util/relation.service';
import {LayoutService} from '../../../portal/app/service/layout.service';
import {KolibriMessage} from './kolibri-message';

@Injectable()
export class KolibriTemplate extends AbstractKolibriTemplate {
  public constructor(criteriaFactory: CriteriaFactory, protected message: KolibriMessage, relationService: RelationService, modelService: ModelService,
                     entityServiceFactory: EntityServiceFactory, private circularService: CircularService, private layoutService: LayoutService) {
    super(criteriaFactory, message, relationService, modelService, entityServiceFactory);
  }

  protected get scriptExecutor(): AbstractKolibriScriptExecutor {
    return this.circularService.scriptExecutor;
  }

  public async apply<T extends KolibriEntity>(recordsToApply: T | T[], templateOrId: string | Template | TemplatePayload<T>, user?: User,
                                              scriptParams?: ScriptParams, formId?: string, appendSave?: boolean): Promise<any> {
    const result = await super.apply(recordsToApply, templateOrId, user, scriptParams, formId);

    if (result && appendSave) {
      for (const r of result) {
        this.layoutService.addSave(formId, r);
      }
      return true;
    }
    return result;
  }
}
