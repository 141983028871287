import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {_} from '@wspsoft/underscore';

import {StructureService} from '../../../service/structure.service';
import {UiUtil} from '../../../util/ui-util';

@Component({
  selector: 'ui-main-structure',
  templateUrl: './main-structure.component.html',
  styleUrls: ['./main-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainStructureComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  public main: boolean = true;
  @Input()
  public formId: string;
  @Output()
  public viewportChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('headerButtonsContainer')
  public headerButtonsContainer: ElementRef;
  @ContentChild('oneContentLeft', {static: true})
  public contentLeft: TemplateRef<any>;
  @ContentChild('headerButtons', {static: true})
  public headerButtons: TemplateRef<any>;
  @ContentChild('oneContentMain', {static: true})
  public contentMain: TemplateRef<any>;
  @ContentChild('oneContentRight', {static: true})
  public contentRight: TemplateRef<any>;
  @ContentChild('onePageFunctional', {static: true})
  public onePageFunctional: TemplateRef<any>;
  @HostBinding('class.one-content--tablet')
  public isViewportTablet: boolean;
  @HostBinding('class.one-content--phone')
  public isViewportPhone: boolean;
  @HostBinding('class.one-content')
  private cssClass: boolean = true;
  @HostBinding('class.one-content--mobile')
  private mobile: boolean;
  @HostBinding('class.one-content--nested')
  private nested: boolean;

  public constructor(private structureService: StructureService, private zone: NgZone, private elementRef: ElementRef,
                     private cdr: ChangeDetectorRef) {
  }

  public get isViewportMobile(): boolean {
    return this.isViewportPhone || this.isViewportTablet;
  }

  public ngOnInit(): void {
    this.initialize();

    // main uses windows and is able to check immediately to avoid flickering
    if (this.main) {
      this.checkViewport();
    }
    this.checkViewport = _.debounce(this.checkViewport, 300, true).bind(this);
  }

  public ngAfterViewInit(): void {
    if (this.main) {
      document.getElementById('oneHeaderTools').replaceChildren(this.headerButtonsContainer.nativeElement);
    }
    this.cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    delete this.structureService.mainStructures[this.formId];
  }

  public initialize(): void {
    this.nested = !this.main;
    if (this.main) {
      this.structureService.mainStructure = this;
    }
    this.structureService.mainStructures[this.formId] = this;
  }

  public checkViewport(): void {
    const oldMobile = this.mobile;

    const width = (this.main ? window.innerWidth : 960 /* always force phone viewport in pageRight */);
    this.isViewportPhone = UiUtil.isMobile(width);
    this.isViewportTablet = width <= 1200;
    this.mobile = this.isViewportMobile;

    if (oldMobile !== this.mobile) {
      this.viewportChange.emit(this.mobile);
    }
    this.cdr.detectChanges();
  }
}
