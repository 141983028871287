import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CriteriaQuery, KolibriEntity, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {MessageService, SelectItem} from 'primeng/api';
import {EntityServiceFactory, ModelService, ModelTranslationService, TypeService} from '../../../../../../api';
import {HotkeyService} from '../../../../service/hotkey.service';
import {OneDialogService} from '../../../../service/one-dialog.service';
import {UiUtil} from '../../../../util/ui-util';
import {DialogComponent} from '../../../dialog/dialog/dialog.component';
import {DatatableColumn, DatatableComponent} from '../datatable/datatable.component';

@Component({
  selector: 'ui-datatable-exporter',
  templateUrl: './datatable-exporter.component.html',
  styleUrls: ['./datatable-exporter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableExporterComponent extends DialogComponent<any[]> implements OnInit {
  @Input()
  public allowAllFields: boolean = true;
  @Input()
  public allowJsonExport: boolean = true;
  @Input()
  public allowPdfExport: boolean = true;
  @Input()
  public allowXlsExport: boolean = true;
  @Input()
  public allowCsvExport: boolean = true;
  @Input()
  public columns: DatatableColumn[];
  @Input()
  public query: CriteriaQuery<KolibriEntity>;
  @Input()
  public name: string;
  @Input()
  public table: DatatableComponent;
  @HostBinding('class.one-helper-hidden--phone')
  public cssClass: boolean = true;
  public exportActions: SelectItem[];
  public selectedExportType: string;
  public translateValues: boolean = true;
  public useDisplayTransformation: boolean = false;
  public allAttributes: boolean = false;
  public showExportDialog: boolean;
  public csvSeparator: string = ',';
  @ViewChild(DialogComponent, {static: false})
  private dialog: DialogComponent<KolibriEntity>;
  private allAvailableFields: DatatableColumn[];

  public constructor(private translate: TranslateService, private modelTranslationService: ModelTranslationService,
                     private entityServiceFactory: EntityServiceFactory, private modelService: ModelService,
                     public typeUtility: TypeService, cdr: ChangeDetectorRef,
                     dialogService: OneDialogService, private messageService: MessageService, hotkeyService: HotkeyService) {
    super(dialogService, cdr, hotkeyService);
  }

  private get exportFields(): DatatableColumn[] {
    return this.allAttributes ? this.allAvailableFields : this.columns;
  }

  public ngOnInit(): void {
    this.setupExportMenu();
    // call ngOnInit of super class for registration of component in oneDialogService
    super.ngOnInit();
  }

  public show(data?: any): void {
    this.dialog.show(data);
    this.cdr.detectChanges();

    // create an array for all json fields, in case the user wants to import it with all attributes
    const entityMeta = this.modelService.getEntity(this.query.entityName);
    this.allAvailableFields = this.typeUtility.convertFieldToColumn(entityMeta, '',
      (this.modelService.getFields(entityMeta.id)).fields) as DatatableColumn[];
  }

  public async export(): Promise<void> {
    if (!this.selectedExportType) {
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: this.translate.instant('List.Export.NoExportTypeSelectedMessage'),
        key: 'growl'
      });
      return;
    }

    if (this.selectedExportType === 'csv' && !this.csvSeparator) {
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: this.translate.instant('List.Export.NoCSVSeparatorSelectedMessage'),
        key: 'growl'
      });
      return;
    }

    this.showExportDialog = false;

    this.messageService.add({
      severity: 'info',
      summary: '',
      sticky: true,
      detail: this.translate.instant('List.Export.BackgroundExport'),
    });

    this.cdr.detectChanges();

    switch (this.selectedExportType) {
      case 'csv':
        await this.exportCsv();
        break;
      case 'xls':
        await this.exportExcel();
        break;
      case 'pdf':
        await this.exportPdf();
        break;
      case 'json':
        await this.exportJson();
        break;
    }
  }

  private setupExportMenu(): void {
    const exportActions: SelectItem[] = [];

    if (this.allowCsvExport) {
      exportActions.push({
        label: this.translate.instant('List.Export.CSV'),
        icon: 'far fa-fw fa-file-code',
        value: 'csv'
      });
    }

    if (this.allowXlsExport) {
      exportActions.push({
        label: this.translate.instant('List.Export.Excel'),
        icon: 'far fa-fw fa-file-excel',
        value: 'xls'
      });
    }

    if (this.allowPdfExport) {
      exportActions.push({
        label: this.translate.instant('List.Export.PDF'),
        icon: 'far fa-fw fa-file-pdf',
        value: 'pdf'
      });
    }

    if (this.allowJsonExport) {
      exportActions.push({
        label: this.translate.instant('List.Export.JSON'),
        icon: 'far fa-fw fa-file-code',
        value: 'json'
      });
    }

    this.exportActions = exportActions;
  }

  private async exportPdf(): Promise<void> {
    const jsPDF = await import('jspdf');
    const autoTable = await import('jspdf-autotable');
    const doc = new jsPDF.default({
      orientation: 'landscape',
    });
    autoTable.default(doc, {
      styles: {
        minCellWidth: 25,
        overflow: 'linebreak'
      },
      columns: this.exportFields.map(col => {
        const titleAndKey = this.translateValues ? col.header : Utility.isToOneRelation(col.meta) ? Utility.parameterizeEntityName(col.field) : col.field;
        return {
          title: titleAndKey,
          dataKey: titleAndKey
        };
      }),
      body: await this.getExportValues()
    });
    // noinspection TypeScriptValidateJSTypes
    await doc.save(this.name + '.pdf');
  }

  private async exportExcel(): Promise<void> {
    const xlsx = await import('xlsx');
    const worksheet = xlsx.utils.json_to_sheet(await this.getExportValues(true));
    const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
    await this.saveAsExcelFile(excelBuffer, this.name);
  }

  private async saveAsExcelFile(buffer: any, fileName: string): Promise<void> {
    const FileSaver = (await import('file-saver')).default;
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  /**
   * function converts columnHeaders and the array of Export Values into a csv readable file by iterating through arrays and
   * adding a seperator between each object. If the object is undefined or null the cell will be blank to ensure a clean csv file.
   * then downloads that file with given filename (and adds .csv extension)
   */
  private async exportCsv(): Promise<void> {
    const filename = this.name;
    const keys = this.exportFields.map(
      col => (this.translateValues ? col.header : Utility.isToOneRelation(col.meta) ? Utility.parameterizeEntityName(col.field) : col.field));
    const rows = await this.getExportValues(true);

    const csvContent =
      keys.join(this.csvSeparator) +
      '\n' +
      rows.map(row => keys.map(k => Utility.escapeCsvData(row[k] === null || row[k] === undefined ? '' : row[k])).join(this.csvSeparator)).join('\n');
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename + '.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /**
   * convert export data to json file
   */
  private async exportJson(): Promise<void> {
    const filename = this.name;
    const rows = await this.getExportValues();
    const blob = new Blob([JSON.stringify(rows, undefined, 2)], {type: 'application/csv;charset=utf-8;'});
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename + '.json');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /**
   * get the Values of the Datatable for export
   * by "cloning" the Query so the offset of the original query won't be affected by iterating through results
   * loading all results at a time to translate the values and then pushing it into the result array
   * continues to do so until there is no records left in the datatable.
   * @returns returns array of results of all the contents in the Database with query XY
   */
  private async getExportValues(convertArrayToString: boolean = false): Promise<any[]> {
    const result = [];
    const clonedQuery = this.query?.clone();

    if (clonedQuery && this.allAttributes) {
      clonedQuery.selectFields = [];
    }

    let sortMeta = this.table?.multiSortMeta ?? [];
    // Sorting is not always included in the query, so we need to look into the table sortMeta
    if (!_.isNullOrEmpty(this.table?.tableState?.multiSortMeta)) {
      sortMeta = this.table?.tableState?.multiSortMeta;
    }
    for (const sort of sortMeta) {
      const column = _.find(this.columns, {field: sort.field});
      this.typeUtility.addOrder(sort, column?.meta, clonedQuery);
    }

    // todo evaluate whether we can use better pagination that preserves row order
    const dotwalks = UiUtil.getDotWalkFields(this.exportFields);
    const dataQuery = clonedQuery ? await clonedQuery.offset(0).limit(undefined).getResults() : this.data;
    await UiUtil.loadRelationData(dataQuery, this.entityServiceFactory.getService(clonedQuery.entityName), dotwalks);
    for (const value of dataQuery) {
      const exportValue = {};
      const fields = [];
      const promises = [];
      for (const column of this.exportFields) {
        const fieldAccess = !this.translateValues && Utility.isToOneRelation(column.meta) ? Utility.parameterizeEntityName(column.field) : column.field;
        if (this.translateValues) {
          switch (this.modelService.getTypeName(column.meta)) {
            case 'Number':
              // we need to handle numbers in a specific way
              // if there is no transform symbol for the column, we ensure the number stays in number format for later use in the exported file
              const transform = this.modelService.getDisplayTransformation(column.meta.displayTransformId);
              if (!transform?.symbol) {
                promises.push(value[fieldAccess]);
              } else {
                promises.push(this.modelTranslationService.translateObjectValue(value, fieldAccess, this.translate.currentLang, column.meta, null, null, null,
                  this.useDisplayTransformation));
              }
              break;
            case 'Date':
              if (_.isNullOrEmpty(value[fieldAccess])) {
                promises.push(value[fieldAccess]);
              } else {
                promises.push(new Date(value[fieldAccess]));
              }
              break;
            default:
              promises.push(this.modelTranslationService.translateObjectValue(value, fieldAccess, this.translate.currentLang, column.meta, null, null, null,
                this.useDisplayTransformation));
              break;
          }
        } else {
          promises.push(new Promise(resolve => Utility.doDotWalk(value, fieldAccess, (x) => {
            if (Utility.isToManyRelation(column.meta)) {
              resolve(x.map((singleRecord: KolibriEntity) => singleRecord.id));
            } else {
              resolve(x === undefined ? value[`${fieldAccess}Data`] : x);
            }
          })));
        }
        fields.push(this.translateValues ? column.header : fieldAccess);
      }
      const results = await Promise.all(promises);
      for (let i = 0; i < fields.length; i++) {
        if (convertArrayToString && Array.isArray(results[i])) {
          // arrays need to be converted to a concatenated string, otherwise some formats can't work with an array
          exportValue[fields[i]] = results[i].join(',');
        } else {
          exportValue[fields[i]] = results[i];
        }
      }
      result.push(exportValue);
    }

    return result;
  }
}
