import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Host, Input, Optional, SkipSelf} from '@angular/core';
import {ControlContainer, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {_} from '@wspsoft/underscore';
import {HotkeyService} from '../../../service/hotkey.service';
import {UiUtil} from '../../../util/ui-util';
import {I18nInput} from '../i18n-input';

@Component({
  selector: 'ui-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true
  }],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: (container: ControlContainer) => container,
    deps: [[new Optional(), new Host(), new SkipSelf(), ControlContainer]],
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextComponent extends I18nInput<any> {
  @Input()
  public type: string = 'text';
  /**
   * make input to hotkey input
   */
  @Input()
  public keyInput: boolean;
  /**
   * don't clear the input when the value is no valid hotkey
   */
  @Input()
  public oneInput: boolean = true;
  @Input()
  public keyInputStay: boolean = false;
  @Input()
  public maxLength: number = 255;
  @Input()
  public symbol: string;
  @Input()
  public autocapitalize: string = 'on';
  @Input()
  public placeholder: string = '';

  public constructor(translateService: TranslateService, cdr: ChangeDetectorRef, private hotkeyService: HotkeyService) {
    super(translateService, cdr);
  }

  public get value(): any {
    if (this.keyInput && this.rawValue) {
      return UiUtil.getKeysFromHotkey(this.rawValue as string).map(key => this.hotkeyService.translateHotkey(key)).join('+');
    }

    return super.value;
  }

  public set value(value: any) {
    super.value = value;
  }

  public get filled(): boolean {
    return !_.isNullOrEmpty(this.value);
  }

  public keydown(event: KeyboardEvent): void {
    if (!this.keyInput) {
      return;
    }
    // normal hotkey logic
    this.value = this.getValueByKeyEvent(event);

    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * clear the input when no valid value is set
   * @param $event the keyboard event
   */
  public keyup($event: KeyboardEvent): void {
    // only when keyInput is set, and the keyInputStay is not set, clear the input
    if (!this.keyInput || this.keyInputStay) {
      return;
    }
    // if value ends with +, remove the value
    if ((this.rawValue as string)?.endsWith('+') && !(this.rawValue as string)?.endsWith('++')) {
      // this if-statement has to be here, otherwise the value will be removed when the user presses the + key
      if (!$event.altKey && !$event.ctrlKey && !$event.metaKey && !$event.shiftKey) {
        this.value = undefined;
      } else {
        this.value = this.getValueByKeyEvent($event);
      }
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  /**
   * returns the value that should be set depending on the key event
   * @param event the keyboard event
   * @private
   */
  private getValueByKeyEvent(event: KeyboardEvent): string {

    const ctrl = UiUtil.hasKeyPressedForOs(event, 'Control') ? `${HotkeyService.convertHotkeyForOs('Control')}+` : '';
    const alt = UiUtil.hasKeyPressedForOs(event, 'Alt') ? `${HotkeyService.convertHotkeyForOs('Alt')}+` : '';
    const shift = UiUtil.hasKeyPressedForOs(event, 'Shift') ? `${HotkeyService.convertHotkeyForOs('Shift')}+` : '';
    const meta = UiUtil.hasKeyPressedForOs(event, 'Meta') ? `${HotkeyService.convertHotkeyForOs('Meta')}+` : '';
    let keyName = event.key === ' ' ? 'Space' : event.key;

    if (keyName === 'Control' || (event.metaKey && keyName === 'Meta') || keyName === 'Alt' || keyName === 'Shift') {
      keyName = '';
    } else {
      console.log(keyName);
    }

    return ctrl + alt + shift + meta + keyName;
  }
}
