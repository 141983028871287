import {_} from '@wspsoft/underscore';
import {CriteriaQueryJson} from '../../../criteria/json/criteria-query-json';
import {I18n} from '../i18n';

import {Variable} from '../variable/variable';

import {VariableRule} from '../variable/variable-rule';

import {VariableScript} from '../variable/variable-script';

import {VariableScriptType} from '../variable/variable-script-type';

export class VariableJson extends Variable {
  public readonlyCondition?: CriteriaQueryJson[] = [];
  public mandatoryCondition?: CriteriaQueryJson[] = [];
  public renderCondition?: CriteriaQueryJson[] = [];
  public onSubmitScripts?: string[] = [];
  public onLoadScripts?: string[] = [];
  public onChange?: string[] = [];
  public filterScript?: CriteriaQueryJson;
  public multiple?: boolean;
  public isI18n?: boolean;
  // choice or picklist or entity
  public target?: string;
  public targetForChoice?: string;
  public values?: string[];
  public helpMessages?: I18n;

  // value stored in db
  public value?: any;

  private constructor() {
    super();
  }

  public static getInstance(variable: Variable, rules: VariableRule[], scripts: VariableScript[]): Variable {
    const variableJson = new VariableJson();
    Object.assign(variableJson, variable.record);

    for (const rule of _.filter(rules, {variableId: variableJson.id})) {
      if (rule.readonly) {
        variableJson.readonlyCondition.push(rule.readonly);
      }
      if (rule.mandatory) {
        variableJson.mandatoryCondition.push(rule.mandatory);
      }
      if (rule.rendered) {
        variableJson.renderCondition.push(rule.rendered);
      }
    }

    for (const script of _.filter(scripts, {variableId: variableJson.id, type: VariableScriptType.onChange})) {
      variableJson.onChange.push(script.script);
    }

    for (const script of _.filter(scripts, {variableId: variableJson.id, type: VariableScriptType.onSubmit})) {
      variableJson.onSubmitScripts.push(script.script);
    }

    for (const script of _.filter(scripts, {variableId: variableJson.id, type: VariableScriptType.onLoad})) {
      variableJson.onLoadScripts.push(script.script);
    }

    variableJson.value = undefined;

    return variableJson;
  }
}
