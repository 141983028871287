import {CriteriaQueryJson, FlowElement} from '../..';

import {ShareableEntity} from '../database/shareable-entity';

export class PreDefinedCondition extends ShareableEntity {
  public script?: FlowElement[];
  public query?: CriteriaQueryJson;
  public typeId?: string;
  public entityName?: string;
}
