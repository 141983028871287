import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractUserService, EmailMessage, KolibriEntity, PrefQuery, User} from '@wspsoft/frontend-backend-common';
import {lastValueFrom} from 'rxjs';
import {environment} from '../../../../config/environments/environment';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

import {EntityServiceFactory} from '../generated/entity-service-factory';

@Injectable()
export class UserService extends AbstractService implements AbstractUserService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration, private entityServiceFactory: EntityServiceFactory) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/users/me';
  }

  /**
   * @see AbstractUserService.globalSearch
   */
  public globalSearch(query: string, limit: number, offset: number, dateRange?: string[], entityClasses?: string[]): Promise<KolibriEntity[]> {
    const {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/globalSearch`;

    return this.doPost<KolibriEntity[]>(url, {
      entityClasses: entityClasses.length ? entityClasses.join() : undefined,
      dateRange: dateRange?.join(),
      offset, limit, query
    }, queryParameters, headers);
  }

  public async getMe(): Promise<User> {
    const {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}`;

    return this.entityServiceFactory.getService<User>('PlatformUser').entityEnhancer
      .firstLevelEnhancing(await this.doGet<User>(url, queryParameters, headers));
  }

  public saveUserPassword(password: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/password`;

    return this.doPost<void>(url, {password}, queryParameters, headers);
  }

  public checkTimeoutResponse(): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();
    const url = `${environment.serverAdd}${this.basePath}`;

    return lastValueFrom(this.httpClient.get(url, {params: queryParameters, observe: 'response', headers}))
      .then(resp => resp.status);
  }

  /**
   * @see AbstractUserService.getPrefQueries
   */
  public getPrefQueries(scope: string, prefQueryName?: string): Promise<PrefQuery[]> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/prefQueries`;

    if (prefQueryName) {
      queryParameters = queryParameters.set('prefQueryName', prefQueryName);
    }
    queryParameters = queryParameters.set('scope', scope);

    return this.doGet<PrefQuery[]>(url, queryParameters, headers);
  }

  /**
   * @see AbstractUserService.getPrefQuery
   */
  public async getPrefQuery(id: string): Promise<PrefQuery> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/prefQueries/${id}`;

    headers = headers.set('enrichWithSecurityInfo', 'true');

    return this.entityServiceFactory.getService<PrefQuery>('PrefQuery').entityEnhancer
      .firstLevelEnhancing(await this.doGet<PrefQuery>(url, queryParameters, headers));
  }

  public requestPasswordReset(username: string): Promise<boolean> {
    const {queryParameters, headers} = this.getHeaders();
    const url = `${this.basePath}/passwordReset`;

    return this.doPost<boolean>(url, {username}, queryParameters, headers);
  }
}
