import {ScriptParams} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';

export interface Converter<S, T = string> {
  /**
   * evaluates the need conversion script if there is one
   */
  hasNeedsConversion?(): MaybePromise<boolean>;

  /**
   * evaluates the need conversion script if there is one
   */
  needsConversion?(params: ScriptParams): MaybePromise<boolean>;

  /**
   * converts the given object to a string.
   * @param objectToConvert the object that should be converted to a string.
   */
  getAsString(objectToConvert: S | S[]): MaybePromise<T | T[]>;

  /**
   * converts the given string to an object.
   * @param stringToConvert the string that should be converted to an object.
   */
  getAsObject(stringToConvert: T | T[]): MaybePromise<S | S[]>;
}

export class DefaultConverter implements Converter<string> {
  public getAsObject(s: string | string[]): string | string[] {
    return s;
  }

  public getAsString(x: string | string[]): string | string[] {
    return x;
  }

}
