import {TranslateService} from '@ngx-translate/core';
import {
  AggregationFunction,
  BundleKeyGenerator,
  CriteriaFunction,
  CriteriaQuery,
  CriteriaQueryGroup,
  EntityModel,
  KolibriEntity
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {ModelService, TypeService} from '../../../api';
import {DatatableColumn} from '../components/structure/datatable/datatable/datatable.component';


export abstract class ListUtil {
  /**
   * handle a single prime ng column filter and adds it to the query
   */
  public static applyColumnFilter($event: LazyLoadEvent, group: CriteriaQueryGroup<KolibriEntity>, columns: DatatableColumn[], typeUtility: TypeService): void {
    if ($event.filters) {
      for (const key of Object.keys($event.filters)) {
        const filter = $event.filters[key];

        // skip empty strings
        if (_.isNull(filter.value) || filter.value === '' || key === 'global') {
          continue;
        }
        const column = _.find(columns, {field: key});
        typeUtility.addFilter(group, column, filter, false);
      }
    }
  }

  public static applySortMeta($event: LazyLoadEvent, query: CriteriaQuery<KolibriEntity>, columns: DatatableColumn[], typeUtility: TypeService): void {
    if ($event.multiSortMeta) {
      for (const sortMeta1 of $event.multiSortMeta) {
        const column = _.find(columns, {field: sortMeta1.field});
        typeUtility.addOrder(sortMeta1, column?.meta, query);
      }
    }
  }

  public static throwNoColumnMessage(fieldName: string, entityMeta: EntityModel, modelService: ModelService,
                                     translate: TranslateService, messageService: MessageService): void {
    const field = modelService.getField(entityMeta.name, fieldName);
    const translatedField = translate.instant(BundleKeyGenerator.fieldToKey(field, entityMeta));
    messageService.add({
      severity: 'error',
      detail: translate.instant('Datatable.GroupBy.NoColumn', {fieldName: translatedField}),
      summary: '',
      key: 'growl',
      sticky: false
    });
  }

  public static async calculateFullAggregation(column: DatatableColumn, query: CriteriaQuery<KolibriEntity>): Promise<number>;
  public static async calculateFullAggregation(column: DatatableColumn[], query: CriteriaQuery<KolibriEntity>): Promise<number[]>;
  public static async calculateFullAggregation(column: DatatableColumn | DatatableColumn[], query: CriteriaQuery<KolibriEntity>): Promise<number | number[]> {
    let wasArray = true;
    if (!Array.isArray(column)) {
      wasArray = false;
      column = [column];
    }

    const aggregationQuery = query.clone().limit(undefined).offset(0);

    // add aggregation for all columns and execute
    if (column.some(col => col.aggregation && col.showAggregation)) {
      this.addAggregation(column, aggregationQuery, false);

      const result = (await aggregationQuery.addGroupBy('groupBySomeField').execute())[0];
      if (result) {
        const mapped = column.map(c => result[this.getAggregationName(c)]);
        return wasArray ? mapped : mapped[0];
      }
    }

    return;
  }

  public static addAggregation(columns: DatatableColumn | DatatableColumn[], query: CriteriaQuery<KolibriEntity>, withTransformOrigin: boolean = true): void {
    if (!Array.isArray(columns)) {
      columns = [columns];
    }
    for (const column of columns) {
      const aggregationName = this.getAggregationName(column);
      if (column.aggregation && column.showAggregation) {
        if (withTransformOrigin) {
          query.addSelectField(column.field);
        }
        query
          .addTransform(column.field, aggregationName)
          .addSelectField(aggregationName, this.aggregationToCriteria(column.aggregation));
      }
    }
  }

  public static getAggregationName(column: DatatableColumn): string {
    return `${column.field}`;
  }

  private static aggregationToCriteria(aggregationFn: AggregationFunction): CriteriaFunction {
    switch (aggregationFn) {
      case AggregationFunction.COUNT:
        return CriteriaFunction.COUNT;
      case AggregationFunction.AVG:
        return CriteriaFunction.AVG;
      case AggregationFunction.SUM:
        return CriteriaFunction.SUM;
    }
  }
}
