import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CriteriaConditionJson, CriteriaQueryJson, Entity} from '@wspsoft/frontend-backend-common';
import {Subject} from 'rxjs';
import {RuntimeModelService} from '../../../../../api/app/service/coded/runtime-model.service';

@Component({
  selector: 'ui-typed-search',
  templateUrl: './typed-search.component.html',
  styleUrls: ['./typed-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypedSearchComponent implements OnInit, OnChanges {
  @Input()
  public multiple: boolean = false;
  @Input()
  public name: string;
  @Input()
  public isCompact: boolean = false;
  @Input()
  public inline: boolean;
  @Input()
  public icon: string = 'fas fa-search';
  @Input()
  public value: CriteriaQueryJson = {};
  @Input()
  public searchValue: string;
  @Output()
  public search: EventEmitter<CriteriaQueryJson> = new EventEmitter<CriteriaQueryJson>();
  public modelChanged: Subject<string> = new Subject<string>();
  public showInput: boolean = true;
  @Input()
  public entities: Entity[];

  public constructor(public model: RuntimeModelService) {
  }

  public get whereConditions(): CriteriaConditionJson[] {
    return this.value?.whereCondition ?? [];
  }

  public async ngOnInit(): Promise<void> {
    this.entities ??= await this.model.getEntitiesLocalized();
  }

  /**
   * If the resolution changes, the isCompact flag may change so check if it does
   *
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCompact) {
      this.showInput = !this.isCompact;
    }
  }

}
