import { KolibriEntity } from './kolibri-entity';

export enum CSPDirectives {
  defaultSrc = 'default-src',
  scriptSrc = 'script-src',
  scriptSrcAttr = 'script-src-attr',
  styleSrc = 'style-src',
  imgSrc = 'img-src',
  fontSrc = 'font-src',
  connectSrc = 'connect-src',
  mediaSrc = 'media-src',
  objectSrc = 'object-src',
  frameSrc = 'frame-src',
  workerSrc = 'worker-src',
  childSrc = 'child-src',
  frameAncestors = 'frame-ancestors',
  formAction = 'form-action',
  baseUri = 'base-uri',
  manifestSrc = 'manifest-src',
  upgradeInsecureRequests = 'upgrade-insecure-requests'
}

export abstract class ContentSecurityPolicy extends KolibriEntity {
  public directive: CSPDirectives;
  public value: string[];
}
