import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {FlowElementCategory, FlowElementConfiguration} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class FlowService extends AbstractService {
  public cache: { elements: { [p: string]: FlowElementConfiguration }; categories: FlowElementCategory[] };

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/flow';
  }


  public async flowElementCache(): Promise<{ elements: { [flowEntity: string]: FlowElementConfiguration }; categories: FlowElementCategory[] }> {
    return this.cache = await this.doGet<{ elements: { [flowEntity: string]: FlowElementConfiguration }; categories: FlowElementCategory[] }>(
      `${this.basePath}/cache`);
  }
}
