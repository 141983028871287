import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Attribute, ChoiceValue, Field, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ModelService} from '../../../../../api';

@Component({
  selector: 'ui-choice-translation',
  templateUrl: './choice-translation.component.html',
  styleUrls: ['./choice-translation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChoiceTranslationComponent {
  @Input()
  public data: any;
  @Input()
  public meta: Field;
  @Input()
  public field: string;
  @Input()
  public index: number;
  @Input()
  public multiple: boolean;
  @Input()
  public translatedValue: string;

  public constructor(public modelService: ModelService) {
  }

  private pchoiceValue: ChoiceValue;

  public get choiceValue(): ChoiceValue {
    if (!this.pchoiceValue) {
      const meta = this.metaWithRealDotWalkValues(this.data, this.field) as Attribute;
      Utility.doDotWalk(this.data, this.field, v => {
        this.pchoiceValue = this.modelService.getChoiceValue(meta.typeId, Array.isArray(v) ? v[this.index] : v);
      });
    }

    return this.pchoiceValue;
  }

  /**
   * calculates the field meta by real dotwalk data (INC000000005)
   * @param data the record
   * @param fieldWithDotWalk the field with dotwalk
   */
  public metaWithRealDotWalkValues(data: any, fieldWithDotWalk: string): Field {
    let meta = this.meta;
    if (Utility.isDotWalk(fieldWithDotWalk) || _.isPromise(data[fieldWithDotWalk])) {
      // read bulk loaded field with initial dotwalk (e.g. (createdBy)//.username) then the actual field
      const value = data[Utility.wordifyDotWalk(fieldWithDotWalk, true)];
      if (value) {
        // if the field is overridden by a descending entity the meta must be edited if not use existing
        meta = this.modelService.getField(value.entityClass, Utility.getDotWalkTarget(fieldWithDotWalk));
      }
    }
    return meta;
  }
}
