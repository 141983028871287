import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataViewType, KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {ConfirmationService, LazyLoadEvent, MessageService, SortMeta, TableState, TreeNode} from 'primeng/api';
import {OneConfirmService} from '../../../service/one-confirm.service';
import {TableComponent} from '../table.component';

type Layout = 'list' | 'grid';

@Component({
  selector: 'ui-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataViewComponent extends TableComponent implements OnInit {
  @Input()
  public dataViewType: DataViewType;
  @Input()
  public sortMeta: SortMeta[];
  @Input()
  public currentDataViewType: Layout;
  @Output()
  public onStateRestore: EventEmitter<TableState & { nodes?: TreeNode[] }> = new EventEmitter();
  @Output()
  public onStateSave: EventEmitter<TableState & { layout: Layout }> = new EventEmitter();
  @ContentChild('gridItemContent', {static: true})
  public gridItemContent: TemplateRef<any>;
  @ContentChild('rowItemContent', {static: true})
  public rowItemContent: TemplateRef<any>;
  public tableState: TableState & { layout: Layout };

  public constructor(translate: TranslateService, confirmationService: ConfirmationService,
                     messageService: MessageService, oneConfirmService: OneConfirmService,
                     cdr: ChangeDetectorRef) {
    super(translate, confirmationService, messageService, oneConfirmService, cdr);
  }

  public get hasSwitchOption(): boolean {
    return this.dataViewType === DataViewType.both;
  }

  @Input()
  public trackBy: TrackByFunction<any> = (index) => index;

  public ngOnInit(): void {
    this.rowsPerPageOptions = _.sortBy(_.uniq([...this.rowsPerPageOptions, this.rows]));
    this.setupContextMenu();

    if (this.customActions) {
      this.addCustomActions(this.customActions);
    }

    // restore prev state
    this.restoreState();
    void this.loadPage(this.tableState);
  }

  public refresh(clearSelection?: boolean, externalRefresh: boolean = true): Promise<KolibriEntity[]> {
    this.onRefresh.emit(externalRefresh);
    return this.loadPage({});
  }

  public count(): Promise<KolibriEntity[]> {
    return this.loadPage({count: true});
  }

  public async loadPage({first, rows, globalFilter, count}:
                          { first?: number; rows?: number; globalFilter?: string; count?: boolean }): Promise<KolibriEntity[]> {
    try {
      this.onBeforeLoad.emit();
      this.saveTableState({first, rows, globalFilter, count});
      return await this.list.load({...this.tableState, count} as LazyLoadEvent);
    } finally {
      this.onAfterLoad.emit();
    }
  }

  public saveTableState({first, rows, globalFilter, count, layout}:
                          { first?: number; rows?: number; globalFilter?: string; count?: boolean; layout?: Layout }): void {
    if (!_.isNull(first)) {
      this.tableState.first = first;
    }
    if (!_.isNull(rows)) {
      this.tableState.rows = rows;
    }
    if (!_.isNull(globalFilter)) {
      this.tableState.filters.global = {value: globalFilter, matchMode: 'contains'};
      (this.tableState as LazyLoadEvent).globalFilter = globalFilter;
    }
    if (!_.isNull(layout)) {
      this.tableState.layout = layout;
    }
    this.onStateSave.emit(this.tableState);
  }

  private restoreState(): void {
    const item = sessionStorage.getItem(this.name);
    this.tableState = item ? JSON.parse(item) : {};
    this.tableState.filters ??= {};
    this.tableState.rows ??= this.rows;
    this.tableState.first ??= 0;
    this.tableState.multiSortMeta ??= this.sortMeta;

    if (this.tableState.layout) {
      this.currentDataViewType = this.tableState.layout;
    }

    this.onStateRestore.emit(this.tableState);
  }
}
