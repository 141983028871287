import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Entity, OneTableState} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import {DatatableColumn, UiUtil} from '../../../../..';

import {CustomInput} from '../../../data/custom-input';

@Component({
  selector: 'ui-column-picker',
  templateUrl: './column-picker.component.html',
  styleUrls: ['./column-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColumnPickerComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnPickerComponent extends CustomInput<DatatableColumn[]> implements OnInit, OnDestroy {
  @Input()
  public entityMeta: Entity;
  @Input()
  public availableColumns: DatatableColumn[];
  @Input()
  public dialog: boolean = true;
  @Output()
  public onSave: EventEmitter<OneTableState> = new EventEmitter<OneTableState>();
  @Output()
  public onReset: EventEmitter<void> = new EventEmitter<void>();
  public targetColumns: DatatableColumn[] = [];
  public sourceColumns: DatatableColumn[];
  public tmpColumns: DatatableColumn[] = [];
  public displayColumnToggler: boolean;
  private subscription: Subscription;

  public constructor(cdr: ChangeDetectorRef, private translate: TranslateService, private messageService: MessageService) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.subscription = this.convertChange.subscribe(() => {
      this.init();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public show(): void {
    this.displayColumnToggler = true;
    this.init();
    this.cdr.detectChanges();
  }

  public close(): void {
    if (this.value.length < 1) {
      this.messageService.add({
        severity: 'error',
        summary: '',
        detail: this.translate.instant('Error.EmptyPicklistSaveMessage'),
      });
    } else {
      this.onSave.emit({
        columns: this.value.map(val => val.field)
      });
      this.displayColumnToggler = false;
    }
    this.cdr.detectChanges();
  }

  public onCloseWithoutSave(): void {
    this.value = [...this.tmpColumns];
    this.displayColumnToggler = false;
  }

  public reset(): void {
    this.displayColumnToggler = false;
    this.onReset.emit();
  }

  private init(): void {
    this.targetColumns = [].concat(this.value);
    this.tmpColumns = [...this.value];
    this.sourceColumns = _.sortBy(_.differenceWith(this.availableColumns, this.value, (o1, o2) => o1.meta.id === o2.meta.id), 'header');
  }

  public get viewportMobile(): boolean {
    return UiUtil.isMobile();
  }
}
