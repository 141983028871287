import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {LangChangeEvent, TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import {_} from '@wspsoft/underscore';
import {isObservable, Subscription} from 'rxjs';

@Pipe({
  name: 'massTranslate',
  pure: false
})
export class MassTranslatePipe implements PipeTransform, OnDestroy {
  private onTranslationChange: Subscription;
  private onLangChange: Subscription;
  private onDefaultLangChange: Subscription;
  private value: string = '';
  private lastKey: string[];

  public constructor(private translate: TranslateService, private ref: ChangeDetectorRef) {
  }

  // skipping the args part
  public transform(query: string[], ...args: any[]): any {
    if (!query || !query.length) {
      return query;
    }

    // if we ask another time for the same key, return the last value
    if (_.isEqual(query, this.lastKey)) {
      return this.value;
    }


    // store the query, in case it changes
    this.lastKey = query;

    // set the value
    this.updateValue(query);

    // if there is a subscription to onLangChange, clean it
    this._dispose();

    // subscribe to onTranslationChange event, in case the translations change
    if (!this.onTranslationChange) {
      this.onTranslationChange = this.translate.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
        if (this.lastKey && event.lang === this.translate.currentLang) {
          this.lastKey = null;
          this.updateValue(query, event.translations);
        }
      });
    }

    // subscribe to onLangChange event, in case the language changes
    if (!this.onLangChange) {
      this.onLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        if (this.lastKey) {
          this.lastKey = null; // we want to make sure it doesn't return the same value until it's been updated
          this.updateValue(query, event.translations);
        }
      });
    }

    // subscribe to onDefaultLangChange event, in case the default language changes
    if (!this.onDefaultLangChange) {
      this.onDefaultLangChange = this.translate.onDefaultLangChange.subscribe(() => {
        if (this.lastKey) {
          this.lastKey = null; // we want to make sure it doesn't return the same value until it's been updated
          this.updateValue(query);
        }
      });
    }

    return this.value;
  }

  public ngOnDestroy(): void {
    this._dispose();
  }

  private updateValue(key: string[], translations?: any): void {
    const onTranslation = (res: string[]): void => {
      const trans = key;
      const values = Object.values(res || {});
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        if (typeof value === 'string') {
          trans[i] = value;
        }
      }
      this.value = trans.join(' ');
      this.lastKey = key;
      this.ref.detectChanges();
    };
    if (translations) {
      const res = this.translate.getParsedResult(translations, key);
      if (isObservable(res.subscribe)) {
        res.subscribe(onTranslation);
      } else {
        onTranslation(res);
      }
    }
    this.translate.get(key).subscribe(onTranslation);
  }

  /**
   * Clean any existing subscription to change events
   */
  private _dispose(): void {
    if (typeof this.onTranslationChange !== 'undefined') {
      this.onTranslationChange.unsubscribe();
      this.onTranslationChange = undefined;
    }
    if (typeof this.onLangChange !== 'undefined') {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
    if (typeof this.onDefaultLangChange !== 'undefined') {
      this.onDefaultLangChange.unsubscribe();
      this.onDefaultLangChange = undefined;
    }
  }
}
