import {EventEmitter, Injectable} from '@angular/core';
import GlobalHotkeys from '../../GlobalHotkeys.json';
import {LayoutViewMode} from '../entities/layout-view-mode';
import {ButtonHotkeyWeightOffset, UiUtil} from '../util/ui-util';
import {HotkeyService} from './hotkey.service';


@Injectable()
export class SideBarService {
  public activeSideBar: string;
  public data: any;
  public fullScreen: boolean;
  public showsLayout: boolean = true;
  public options: any;
  public field: string;
  public position: string;
  public onChange: EventEmitter<void> = new EventEmitter<void>();
  private buttonHotkeyWeightOffset: ButtonHotkeyWeightOffset = ButtonHotkeyWeightOffset.System;
  private shelvedHotkeys: boolean = false;
  private onClose: () => any;

  public constructor(private hotkeyService: HotkeyService) {
  }

  private pvisible: boolean;

  public get visible(): boolean {
    return this.pvisible;
  }

  public set visible(value: boolean) {
    this.pvisible = value;
    this.onChange.emit();
    if (value) {
      if (!this.shelvedHotkeys) {
        this.hotkeyService.shelveHotkeys();
        this.shelvedHotkeys = true;
      }
      this.addDefaultHotkeys();
    } else {
      this.onClose();
      this.removeDefaultHotkeys();
      if (this.shelvedHotkeys) {
        this.hotkeyService.unshelveHotkeys();
        this.shelvedHotkeys = false;
      }
    }
  }

  public open<T>(sideBarName: string, data: T,
                 {
                   position,
                   fullscreen,
                   field,
                   options,
                   onClose = () => undefined
                 }: { position: string; fullscreen: boolean; field?: string; options?: any; onClose?: () => any }): void {
    this.onClose = onClose;
    this.activeSideBar = sideBarName;
    this.data = data;
    this.field = field;
    this.options = options;
    this.fullScreen = fullscreen;
    this.position = position;
    this.showsLayout = this.options?.showsLayout ?? true;
    this.visible = true;
  }

  /**
   * Adds default hotkeys for the sidebar
   * E.g. Close on Escape
   */
  private addDefaultHotkeys(): void {
    this.hotkeyService.addBinding(GlobalHotkeys.SidebarClose.id, () => {
      this.visible = false;
    }, GlobalHotkeys.SidebarClose.keys, UiUtil.getHotkeyWeightBasedOnViewMode(LayoutViewMode.FULL_PAGE) + this.buttonHotkeyWeightOffset);
  }

  /**
   * Removes default hotkeys from the sidebar
   * E.g. Close on Escape
   */
  private removeDefaultHotkeys(): void {
    this.hotkeyService.removeBinding(GlobalHotkeys.SidebarClose.id);
  }
}
