import {Injectable} from '@angular/core';
import {AbstractKolibriVariableService} from '@wspsoft/frontend-backend-common';
import {CircularService, EntityServiceFactory} from '../../../api';
import {JsCompilerService} from '../util/js-compiler.service';

@Injectable()
export class KolibriVariableService extends AbstractKolibriVariableService {

  public constructor(entityServiceFactory: EntityServiceFactory, private circularService: CircularService) {
    super(entityServiceFactory);
  }

  public get jsCompilerService(): JsCompilerService {
    return this.circularService.jsCompiler as JsCompilerService;
  }
}
