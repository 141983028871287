import {Attachment} from '../../model/database/attachment';
import {User} from '../../model/database/user';

export enum DocumentTypes {
  pdf = 'pdf',
  html = 'html',
  xml = 'xml',
  css = 'css',
  txt = 'txt',
}

export enum Status {
  SCHEDULED = 'SCHEDULED', STARTED = 'STARTED', COMPLETED = 'COMPLETED', ERROR = 'ERROR'
}

export interface JobStatus {
  status: Status;
  fileId?: string | string[];
}

export interface TemplateData {
  pug: string;
  css?: string;
  meta?: {
    usedFields: string[];
    widgets?: string[];
  };
}

export interface TemplateDataOptions extends DocumentData {
  fileType?: DocumentTypes;
  skipStyles?: boolean;
}

export interface PdfData {
  body?: {
    attachment?: Attachment;
    pug?: string;
    css?: string;
  };
}

/**
 * @param {PdfData} documentData with attachment of the pug and height declarations for header and footer
 * @param data to dynamically insert into the pug
 * @param {string} fileName fileName of file to be generated
 */
export interface DocumentData {
  documentData?: PdfData;
  data: { [key: string]: any };
  fileName: string;
}

export interface AbstractDocumentService {
  /**
   * generate html from given grapes pug template
   */
  generateTemplate(options: TemplateDataOptions, data: TemplateData): Promise<string>;

  generateTemplate(options: TemplateDataOptions[], data: TemplateData): Promise<string[]>;

  generateTemplate(options: TemplateDataOptions | TemplateDataOptions[], data: TemplateData): Promise<string | string[]>;

  /**
   * generate html from given template name
   */
  generateTemplateByName(nameOrId: string, options: TemplateDataOptions, user: User): Promise<string>;

  generateTemplateByName(nameOrId: string, options: TemplateDataOptions[], user: User): Promise<string[]>;

  generateTemplateByName(nameOrId: string, options: TemplateDataOptions | TemplateDataOptions[], user: User): Promise<string | string[]>;
}
