import {Moment} from 'moment';
import {KolibriEntity} from './kolibri-entity';

export interface WorkflowInstance extends KolibriEntity {
  name?: string;
  cancelCondition?: string;
  startCondition?: string;
  payload?: string;
  state?: WorkflowState;
  instanceStatus?: InstanceStatus;
  workflow?: WorkflowProcessModel;
  recordId?: string;
  recordEntityClass?: string;
}

export interface WorkflowProcessModel {
  id?: string;
  workflowName?: string;
  startCondition?: string;
  cancelCondition?: string;
  nodes?: { [id: string]: WorkflowNode };
  diagram?: any; // todo rework type
}

export enum TimerDateType {
  date = 'date', delay = 'delay', dateScript = 'dateScript'
}

export interface WorkflowNode {
  id?: string;
  type?: string;
  name?: string;
  incoming?: string[];
  outgoing?: string[];
  condition?: string;
  nodes?: { [id: string]: WorkflowNode };
  subProcessState?: WorkflowState;
  loop?: Loop;
  taskListenerDefinitions?: any[];
  inputParameter?: { [key: string]: any };
  // temporary for legacy stuff
  inputParameterScript?: string;
  executionListener?: any;

  state?: WorkflowState;
  started?: Moment;
  startedBy?: string;
  finished?: Moment;
  finishedBy?: string;
  takenAt?: Moment;
  from?: string;
  to?: string;
  order?: number;
  script?: string;
  sourceId?: string;
  targetId?: string;
  takeScript?: string;
  resultVariable?: string;

  // flowTimer
  date?: string;
  delay?: string;
  dateScript?: string;
  dateType?: TimerDateType;

  // flowIf
  thenOutgoing?: string;
  elseOutgoing?: string;

  // flowUserTask
  onCreate?: string;
  onUpdate?: string;
  onComplete?: string;
  onDelete?: string;
  taskEntityVariable?: string;

  // flowLoop
  outputVariableName?: string;
  isSequential?: boolean;
  elementsScript?: string;
  whileCondition?: string;
}

export interface WorkflowState {
  activeNodes?: { [key: string]: WorkflowNode };
  path?: WorkflowNode[];
  processVariables?: { [key: string]: any };
}

export interface Loop {
  collectionName?: string;
  elementVariable?: string;
  isSequential?: boolean;
  completionCondition?: string;
}

export enum InstanceStatus {
  running = 'running', stalled = 'stalled', error = 'error', finished = 'finished', cancelled = 'cancelled'
}
