import {KolibriEntity} from '../kolibri-entity';

export class ImportMapToController extends KolibriEntity {
  public importMapDest: string;
  public importControllerId: string;
  public order?: number;
}

export class ImportController extends KolibriEntity {
  // eslint-disable-next-line id-blacklist
  public number: string;
  public importMaps?: ImportMapToController[];
  public importDataTTL?: string;
}
