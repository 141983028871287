import {Injectable} from '@angular/core';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import * as uuidv4 from 'uuid-random';

type DelayedMethod = (record: KolibriEntity) => MaybePromise<any>;

interface MethodStorage {
  [formId: string]: {
    fn: DelayedMethod;
    permanent: boolean;
    id: string;
  }[];
}

export interface ConflictData {
  [field: string]: { newValue: any; oldValue: any; conflict?: boolean };
}

interface ConflictStorage {
  [formId: string]: ConflictData;
}

@Injectable()
export class LayoutService {
  public conflicts: ConflictStorage = {};
  public delayedSaves: MethodStorage = {};
  public beforeSave: MethodStorage = {};
  public afterSave: MethodStorage = {};

  public constructor() {
  }

  public getConflicts(formId: string): ConflictData {
    this.conflicts[formId] ??= {};
    return this.conflicts[formId];
  }

  /**
   * add method to call when record is saved.<br />
   * <b>Attention:</b> permanent saves should be removed after destroying component!
   * @param parentFormId the formId will be used as index
   * @param saveMethod the method to call
   * @param permanent keep the method after record is saved
   * @returns id to find method again
   */
  public addSave(parentFormId: string, saveMethod: DelayedMethod, permanent: boolean = false): string {
    if (!(parentFormId in this.delayedSaves)) {
      this.delayedSaves[parentFormId] = [];
    }
    const id = uuidv4();
    this.delayedSaves[parentFormId].push({
      fn: saveMethod,
      permanent,
      id
    });
    return id;
  }

  /**
   * removes the method with the id from the saves of the form
   * @param parentFormId id of the form to remove
   * @param methodId id of the method to remove
   */
  public removeSave(parentFormId: string, methodId: string): void {
    if (this.delayedSaves[parentFormId]) {
      _.remove(this.delayedSaves[parentFormId], {id: methodId});
    }
  }

  public executeSaves(id: string, record: KolibriEntity): Promise<void> {
    return this.executeSavesByType(id, 'delayedSaves', record);
  }

  /**
   * add method to call after record is saved.
   * <b>Attention:</b> permanent saves should be removed after destroying component!
   * @param parentFormId the formId will be used as index
   * @param afterSaveMethod the method to call
   * @param permanent keep the method after record is saved
   * @returns id to find method again
   */
  public addAfterSave(parentFormId: string, afterSaveMethod: DelayedMethod, permanent: boolean = false): string {
    if (!(parentFormId in this.afterSave)) {
      this.afterSave[parentFormId] = [];
    }
    const id = uuidv4();
    this.afterSave[parentFormId].push({
      fn: afterSaveMethod,
      permanent,
      id
    });
    return id;
  }

  /**
   * add method to call before record is saved.
   * <b>Attention:</b> permanent saves should be removed after destroying component!
   * @param parentFormId the formId will be used as index
   * @param beforeSaveMethod the method to call
   * @param permanent keep the method after record is saved
   * @returns id to find method again
   */
  public addBeforeSave(parentFormId: string, beforeSaveMethod: DelayedMethod, permanent: boolean = false): string {
    if (!(parentFormId in this.beforeSave)) {
      this.beforeSave[parentFormId] = [];
    }
    const id = uuidv4();
    this.beforeSave[parentFormId].push({
      fn: beforeSaveMethod,
      permanent,
      id
    });
    return id;
  }

  /**
   * removes the method with the id from the before saves of the form
   * @param parentFormId id of the form to remove
   * @param methodId id of the method to remove
   */
  public removeBeforeSave(parentFormId: string, methodId: string): void {
    if (this.beforeSave[parentFormId]) {
      _.remove(this.beforeSave[parentFormId], {id: methodId});
    }
  }

  /**
   * removes the method with the id from the after saves of the form
   * @param parentFormId id of the form to remove
   * @param methodId id of the method to remove
   */
  public removeAfterSave(parentFormId: string, methodId: string): void {
    if (this.afterSave[parentFormId]) {
      _.remove(this.afterSave[parentFormId], {id: methodId});
    }
  }

  /**
   * executes all methods that are added before with the same parentFormId.
   */
  public executeBeforeSaves(parentFormId: string, record: KolibriEntity): Promise<void> {
    return this.executeSavesByType(parentFormId, 'beforeSave', record);
  }

  /**
   * executes all methods that are added after with the same parentFormId.
   */
  public executeAfterSaves(parentFormId: string, record: KolibriEntity): Promise<void> {
    return this.executeSavesByType(parentFormId, 'afterSave', record);
  }

  public reset(formId: string): void {
    delete this.delayedSaves[formId];
    delete this.beforeSave[formId];
    delete this.afterSave[formId];
    delete this.conflicts[formId];
  }

  /**
   * remove all saves from form
   */
  public removeSaves(type: 'beforeSave' | 'afterSave' | 'delayedSaves', id: string): void {
    // remove data from service and component
    this[type][id] = this[type][id].filter(x => x.permanent);
  }

  /**
   * executes methods form the storage and removes them
   */
  private async executeSavesByType(id: string, type: 'beforeSave' | 'afterSave' | 'delayedSaves', record: KolibriEntity): Promise<void> {
    if (!(this[type][id])) {
      return;
    }

    const addMethods = [];
    const removeMethods = [];
    for (const method of Object.values(this[type][id])) {
      if (method.fn.name.includes('delete') || method.fn.name.includes('remove')) {
        removeMethods.push(method);
      } else {
        addMethods.push(method);
      }
    }

    // both of these need to run sequential, otherwise the viewData gets overridden and every record that was added to the delayedSaves is the same
    // execute all delayed add methods for every component
    for (const addMethod of addMethods) {
      await addMethod.fn(record);
    }
    // execute all delayed remove methods for every component
    for (const removeMethod of removeMethods) {
      await removeMethod.fn(record);
    }

    this.removeSaves(type, id);
  }
}
