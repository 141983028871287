import * as uuidv4 from 'uuid-random';
import {CriteriaFunction} from './criteria-function';
import {CriteriaOrder} from './criteria-order';

export class CriteriaOrderBy {
  private id? = uuidv4();

  public constructor(public columnName: string, public order?: CriteriaOrder,
                     public criteriaFunction?: CriteriaFunction, public options?: any) {
  }

  public static isEqual(order1: CriteriaOrderBy, order2: CriteriaOrderBy): boolean {
    return order1.id ? order1.id === order2.id : order1.columnName === order2.columnName;
  }
}
