import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractDocumentService,
  AttachmentUtil,
  DocumentData,
  JobStatus,
  Status,
  TemplateData,
  TemplateDataOptions,
  User
} from '@wspsoft/frontend-backend-common';
import {MessageService} from 'primeng/api';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class DocumentGeneratorService extends AbstractService implements AbstractDocumentService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration, private messageService: MessageService, private translate: TranslateService) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/public/portal/documents';
  }

  public async generatePdfsAndDownload(files: DocumentData[], zip: boolean, zipName?: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    this.messageService.add({
      severity: 'info',
      summary: '',
      sticky: true,
      detail: this.translate.instant('CustomMessages.Documents.BackgroundDownload')
    });

    const {jobId} = await this.doPost<{ jobId: string }>(`${this.basePath}/generate/pdf`, {files, zip}, queryParameters, headers);
    const intervalId = setInterval(async () => {
      const jobStatus = await this.doGet<JobStatus>(`${this.basePath}/jobStatus/${jobId}`, queryParameters, headers);
      switch (jobStatus.status) {
        case Status.COMPLETED:
          return this.downloadFiles(intervalId as any as number, jobStatus.fileId, zip, zipName, jobId);
        case Status.ERROR:
          clearInterval(intervalId);
          return this.messageService.add({
            severity: 'error',
            summary: '',
            sticky: false,
            detail: this.translate.instant('CustomMessages.Documents.JobError')
          });
      }
    }, 5000);
  }

  /**
   * @see AbstractDocumentService.generateTemplateByName
   */
  public generateTemplateByName(nameOrId: string, options: TemplateDataOptions, user: User): Promise<string>;
  public generateTemplateByName(nameOrId: string, options: TemplateDataOptions[], user: User): Promise<string[]>;
  public generateTemplateByName(nameOrId: string, options: TemplateDataOptions | TemplateDataOptions[], user: User): Promise<string | string[]> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/template/${nameOrId}`,
      {options, user}, queryParameters, headers, Array.isArray(options) ? 'json' : 'text');
  }

  /**
   * @see AbstractDocumentService.generateTemplate
   */
  public generateTemplate(options: TemplateDataOptions, data: TemplateData): Promise<string>;
  public generateTemplate(options: TemplateDataOptions[], data: TemplateData): Promise<string[]>;
  public generateTemplate(options: TemplateDataOptions | TemplateDataOptions[], data: TemplateData): Promise<string | string[]> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost(`${this.basePath}/template`, {options, data}, queryParameters, headers, Array.isArray(options) ? 'json' : 'text');
  }

  private async downloadFiles(intervalId: number, fileId: string | string[], zip: boolean, zipName: string, jobId: string): Promise<void> {
    try {
      // make sure to remove the interval again
      clearInterval(intervalId);

      // create anchor tag which will be programmatically clicked in order to download the files
      const a = document.createElement('a');
      if (zip) {
        await this.downloadFile(fileId as string, 'zip', zipName, a);
      } else {
        for (const id of fileId) {
          // remove jobId from fileName
          await this.downloadFile(id, 'pdf', id.replace(jobId, ''), a);
        }
      }

      a.remove();
    } catch (e) {
      console.error(`Could not load file for job ${jobId}`, e);
      this.messageService.add({
        severity: 'error',
        summary: '',
        sticky: false,
        detail: this.translate.instant('CustomMessages.Documents.JobError')
      });
    }
  }

  private async downloadFile(fileId: string, fileType: 'zip' | 'pdf', fileName: string, anchor: HTMLAnchorElement): Promise<void> {
    const response = await fetch(`/files/documentJob/${encodeURIComponent(fileId)}`);
    anchor.href = `data:application/${fileType};base64,${AttachmentUtil.arrayBufferToBase64(await response.arrayBuffer())}`;
    anchor.download = `${fileName}.${fileType}`;
    anchor.click();
  }
}
