import {Injectable} from '@angular/core';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {KolibriEntityConverterService} from './kolibri-entity-converter.service';

@Injectable()
export class KolibriEntityDeadValueConverterService extends KolibriEntityConverterService {
  public getAsString(objectToConvert: KolibriEntity[]): string[];
  public getAsString(objectToConvert: KolibriEntity): string;
  public getAsString(objectToConvert: KolibriEntity | KolibriEntity[]): string | string[] {
    if (Array.isArray(objectToConvert)) {
      return super.getAsString(objectToConvert);
    } else {
      return super.getAsString(objectToConvert);
    }
  }

  public async getAsObject(stringToConvert: string): Promise<KolibriEntity>;
  public async getAsObject(stringToConvert: string[]): Promise<KolibriEntity[]>;
  public async getAsObject(stringToConvert: string | string[]): Promise<KolibriEntity | KolibriEntity[]> {
    if (!Array.isArray(stringToConvert)) {
      const val = await super.getAsObject(stringToConvert);
      // if dead value return fake entity
      return _.isNullOrEmpty(val) && !_.isNullOrEmpty(stringToConvert) ? {id: stringToConvert, representativeString: stringToConvert} : val;
    }

    return super.getAsObject(stringToConvert);
  }

}
