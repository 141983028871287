import {KolibriEntity} from './kolibri-entity';
import {Role} from './role';

export interface AccessControlToRole extends KolibriEntity {
  shTitle?: string;
  role?: Role;
  roleId?: string;
  accessControl?: AccessControl;
  accessControlId?: string;
}

export interface AccessControl extends KolibriEntity {
  adminOverrides?: boolean;
  filterScript?: Record<any, any>;
  shTitle?: string;
  order?: number;
  level?: string;
  resource?: string;
  operation?: string[];
  type?: AccessControlType;
  conditions?: Record<any, any>;
  description?: string;
  AccessControlToRoleAccessControls?: AccessControlToRole[];
  AccessControlToRoleAccessControlsId?: string;
}

export enum AccessControlType {
  entity = 'entity', share = 'share', custom = 'custom', tenancy = 'tenancy', navigation = 'navigation', layout = 'layout'
}

