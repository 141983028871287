<ng-template #queryBuilderContent>
  <div *ngIf="parent" class="one-conditionbuilder__query-conditions one-conditionbuilder__query-conditions--top">
    <p-radioButton (onClick)="forceUpdate()" [(ngModel)]="value.useOr" [disabled]="disable" [value]="false"
                   class="one-conditionbuilder__condition-label one-conditionbuilder__condition-label--left" label="{{'QueryBuilder.Group.And' | translate}}"
                   name="{{value.id}}_radio"></p-radioButton>
    <p-radioButton (onClick)="forceUpdate()" [(ngModel)]="value.useOr" [disabled]="disable" [value]="true"
                   class="one-conditionbuilder__condition-label one-conditionbuilder__condition-label--right" label="{{'QueryBuilder.Group.Or' | translate}}"
                   name="{{value.id}}_radio"></p-radioButton>

    <ui-button (onDragEnd)="moveGroup()" (onDragStart)="moveGroup(value)" *ngIf="draggable && !disable" [spinner]="false" class="one-conditionbuilder__drag"
               dragEffect="move" icon="fas fa-fw fa-arrows-alt" pDraggable="queryElement" type="icon-only"
               label="{{'QueryBuilder.Group.MoveGroup' | translate}}"></ui-button>
  </div>

  <dd class="one-conditionbuilder__group-content">
    <ul (onDrop)="moveElement($event, 0)" class="one-conditionbuilder__rules-list" dropEffect="relocate" pDroppable="queryElement">
      <ng-container *ngFor="let aCondition of value.whereCondition; index as i; first as first; last as last;">
        <li (onDrop)="moveElement($event, i)" (onUpdate)="forceUpdate()" [(valueBinding)]="value.whereCondition[i]" [deletable]="!first || !!parent"
            [disable]=disable [first]="first" [only]="first && last" [group]="this" [ngClass]="{'one-conditionbuilder__rule--small': first}"
            class="one-conditionbuilder__rule Rule{{i}}" dropEffect="relocate" pDroppable="queryElement" ui-query-builder-rule></li>
      </ng-container>

      <ng-container *ngFor="let group of value.groups; index as i;">
        <dl (onDrop)="moveElement($event,i)" (onUpdate)="forceUpdate()" [(valueBinding)]="value.groups[i]" [disable]="disable" [draggable]="!disable && !parent"
            [parent]="this" class="one-conditionbuilder__content Group{{i}}" dropEffect="relocate" pDroppable="queryElement" ui-query-builder-group></dl>
      </ng-container>

      <li *ngIf="!disable" class="one-conditionbuilder__actions">
        <ui-button (clicked)="addRule()" [spinner]="false" class="one-conditionbuilder__action" data-add="rule" icon="fas fa-fw fa-plus"
                   label="{{'QueryBuilder.Group.AddRule' | translate}}" type="layout"></ui-button>
        <ui-button (clicked)="addGroup()" [spinner]="false" class="one-conditionbuilder__action" data-add="group" icon="fas fa-fw fa-folder"
                   label="{{'QueryBuilder.Group.AddGroup' | translate}}" type="layout"></ui-button>
        <ui-button (clicked)="removeGroup()" *ngIf="parent" [spinner]="false" [type]="['layout', 'red']" class="one-conditionbuilder__action"
                   data-delete="group" icon="fas fa-fw fa-trash" label="{{'QueryBuilder.Group.RemoveGroup' | translate}}"></ui-button>
      </li>
    </ul>
  </dd>
</ng-template>
<ng-template #breadCrumbContent>
  <ng-container *ngFor="let condition of getNonEmptyConditions(); index as i; first as first; last as last;">
    <div (onExecute)="onExecute.emit()" (onUpdate)="forceUpdate()" [(valueBinding)]="nonEmptyConditions[i]" [breadcrumb]="true" [disable]="disable"
         [first]="first" [only]="first && last" [group]="this" [ngClass]="{'one-filter-nav__item--inactive': !condition.active}"
         class="one-filter-nav__item Rule{{i}}" ui-query-builder-rule></div>
  </ng-container>

  <ng-container *ngFor="let group of getNonEmptyGroups(); index as i; first as first; last as last;">
    <span class="one-filter-nav__item-text one-filter-nav__item-text--group">
      <ng-container>{{ 'QueryBuilder.Group.' + (group.useOr ? 'Or' : 'And') | translate }}</ng-container>
    </span>
    <div (onExecute)="onExecute.emit()" [(valueBinding)]="nonEmptyGroups[i]" [breadcrumb]="true" [disable]="disable" [parent]="this"
         [ngClass]="{'one-filter-nav__item-group--inactive': !group.active}" class="one-filter-nav__item-group Group{{i}}" ui-query-builder-group></div>
  </ng-container>
  <ng-container *ngIf="parent && !disable">
    <p-menu #menu [model]="breadcrumbMenu" [popup]="true" appendTo="body"></p-menu>
    <ui-button (clicked)="menu.toggle($event.originalEvent); $event.cb();" class="one-filter-nav__item-button" icon="fa fa-fw fa-ellipsis-v"
               type="icon-only"></ui-button>
  </ng-container>
</ng-template>

<ng-container *ngIf="breadcrumb" [ngTemplateOutlet]="breadCrumbContent"></ng-container>
<ng-container *ngIf="!breadcrumb" [ngTemplateOutlet]="queryBuilderContent"></ng-container>


