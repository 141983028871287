import {_} from '@wspsoft/underscore';
import {CompilerReturnType} from '../compiler/compiler-return-type';
import {AbstractJsCompiler} from '../compiler/js-compiler';
import {VariableJson} from '../model/database/json/variable-json';
import {Variable} from '../model/database/variable/variable';
import {VariableEntity} from '../model/database/variable/variable-entity';
import {VariableRule} from '../model/database/variable/variable-rule';
import {VariableScript} from '../model/database/variable/variable-script';
import {VariableSet} from '../model/database/variable/variable-set';
import {AbstractEntityServiceFactory} from '../service/generated/abstract-entity-service-factory';
import {Utility} from '../util/utility';

export abstract class AbstractKolibriVariableService {
  protected constructor(protected entityServiceFactory: AbstractEntityServiceFactory) {
  }


  public abstract get jsCompilerService(): AbstractJsCompiler;

  /**
   * creates variable instances from given variables, rules, scripts and sets
   * the scripts will be compiled if necessary
   *
   * @param variables the variable / variables to generate instances for
   * @param variableRules the rules to generate the instances with
   * @param variableScripts the scripts to generate the instances with
   * @param variableSets the variable sets to generate the instances with
   */
  public async getInstances(variables: Variable, variableRules: VariableRule[], variableScripts: VariableScript[],
                            variableSets: VariableSet[]): Promise<VariableJson>;
  public async getInstances(variables: Variable[], variableRules: VariableRule[], variableScripts: VariableScript[],
                            variableSets: VariableSet[]): Promise<VariableJson[]>;
  public async getInstances(variables: Variable | Variable[], variableRules: VariableRule[] = [], variableScripts: VariableScript[] = [],
                            variableSets: VariableSet[] = []): Promise<VariableJson | VariableJson[]> {

    if (_.isNullOrEmpty(variables)) {
      return variables;
    }

    if (!Array.isArray(variables)) {
      return (await this.getInstances([variables], variableRules, variableScripts, variableSets))[0];
    }

    const varObj = await this.handleVariableSets(variableSets, variables, variableRules, variableScripts);
    return this.createInstances(varObj.variables, varObj.variableRules, varObj.variableScripts);
  }

  /**
   * creates variable instances from given VariableEntity - Record
   * @param currentRecord record with variables, rules, scripts and sets
   */
  // noinspection JSUnusedGlobalSymbols used in app scripts
  public async loadVariableInstances(currentRecord: VariableEntity): Promise<VariableJson[]> {
    const variableObject = await this.loadVariables(currentRecord);
    const {variables, variableRules, variableScripts} =
      await this.handleVariableSets(variableObject.variableSets, variableObject.variables, variableObject.variableRules, variableObject.variableScripts);

    return this.createInstances(variables, variableRules, variableScripts);
  }

  public async loadVariables(currentRecord: VariableEntity): Promise<{
    variables: Variable[];
    variableRules: VariableRule[];
    variableScripts: VariableScript[];
    variableSets: VariableSet[];
  }> {
    // load all entity variables, scripts, rules and sets
    // eslint-disable-next-line prefer-const
    let [variables, variableRules, variableScripts, variablesLinks] = await Promise.all([
      currentRecord.variables,
      currentRecord.variableRules,
      currentRecord.variableScripts,
      currentRecord.variableSetToEntities,
    ]);
    // destroy array pointer to allow modification
    variables = [...(variables ?? [])];
    variableRules = [...(variableRules ?? [])];
    variableScripts = [...(variableScripts ?? [])];
    variablesLinks = [...(variablesLinks ?? [])];
    const variableSets: VariableSet[] = [];
    const variableLinkService = this.entityServiceFactory.getService('VariableSetToEntity');
    // load sets in bulk
    await Utility.bulkDotWalk(variablesLinks, 'variableSet', variableLinkService);
    for (const variablesLink of variablesLinks) {
      variablesLink.variableSet.order = variablesLink.order;
      variableSets.push(variablesLink.variableSet);
    }

    return {variables, variableRules, variableScripts, variableSets};
  }

  private createInstances(variables: Variable[], variableRules: VariableRule[], variableScripts: VariableScript[]): VariableJson[] {

    // compile all scripts
    for (const script of variableScripts) {
      if (typeof script.script !== 'string') {
        script.script = this.jsCompilerService.compile(script.script, CompilerReturnType.SCRIPT);
      }
    }

    const result: VariableJson[] = [];
    for (const variable of variables) {
      // convert to full json structure
      result.push(VariableJson.getInstance(variable, variableRules, variableScripts));
    }
    return result;
  }

  private async handleVariableSets(variableSets: VariableSet[], variables: Variable[], variableRules: VariableRule[],
                                   variableScripts: VariableScript[]): Promise<{
    variables: Variable[];
    variableRules: VariableRule[];
    variableScripts: VariableScript[];
  }> {
    const variableSetService = this.entityServiceFactory.getService<VariableSet>('VariableSet');

    // add sets to the arrays for in place replacement
    variables.push(...variableSets);
    variableRules.push(...variableSets);
    variableScripts.push(...variableSets);

    // sort everything by order
    variables = _.sortBy(variables, 'order');
    variableRules = _.sortBy(variableRules, 'order');
    variableScripts = _.sortBy(variableScripts, 'order');

    // load set data
    await Utility.bulkDotWalk(variableSets, 'variables', variableSetService);
    await Utility.bulkDotWalk(variableSets, 'variableRules', variableSetService);
    await Utility.bulkDotWalk(variableSets, 'variableScripts', variableSetService);

    // combine all vars, rules and script of sets into one list
    for (const variableSet of variableSets) {
      // also sort internally by order
      variables.splice(variables.indexOf(variableSet), 1, ..._.sortBy(_.differenceBy(variableSet.variables, variables, 'name'), 'order'));
      variableRules.splice(variableRules.indexOf(variableSet), 1, ..._.sortBy(_.differenceBy(variableSet.variableRules, variableRules, 'name'), 'order'));
      variableScripts.splice(variableScripts.indexOf(variableSet), 1,
        ..._.sortBy(_.differenceBy(variableSet.variableScripts, variableScripts, 'name'), 'order'));
    }

    variables = _.uniqBy(variables, 'name');

    return {variables, variableRules, variableScripts};
  }
}
