import {KolibriEntity} from '../database/kolibri-entity';
import {DesignerEntity} from './designer.entity';


export abstract class DesignerIntegrityCheck extends DesignerEntity {
  public isTask?: boolean;
  public isResult?: boolean;
  public recordData?: KolibriEntity;
  public recordClass?: string;
  public subTasks?: DesignerIntegrityCheck[];
  public operation?: DesignerIntegrityOperation;
}

export enum DesignerIntegrityOperation {
  UNREFERENCED_DUMP_DATA = 'UNREFERENCED_DUMP_DATA', // unused data in the dump
  MISSING_DUMP_DATA = 'MISSING_DUMP_DATA', // missing data in the dump
}
