import {EventEmitter, Injectable} from '@angular/core';
import {OneMenuState, User} from '@wspsoft/frontend-backend-common';
import {MainStructureComponent} from '../components/structure/main-structure/main-structure.component';

@Injectable()
export class StructureService {
  public showMobileLeftMenu: boolean = false;
  public flagChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  public layoutChange: EventEmitter<void> = new EventEmitter<void>();
  public onScroll: EventEmitter<void> = new EventEmitter<void>();
  public mainStructures: { [formId: string]: MainStructureComponent } = {};
  public leftMenuState: OneMenuState;
  public mainStructureChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor() {
  }

  private pmainStructure: MainStructureComponent;

  public get mainStructure(): MainStructureComponent {
    return this.pmainStructure;
  }

  public set mainStructure(value: MainStructureComponent) {
    this.pmainStructure = value;
    this.mainStructureChange.emit(!!value);
  }

  public init(user: User): void {
    this.leftMenuState = user.activeProfile.sessionSettings.leftMenuState;
  }
}
