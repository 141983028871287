import {Injectable} from '@angular/core';
import {AbstractJsCompiler, AbstractKolibriScriptContext} from '@wspsoft/frontend-backend-common';
import {CircularService, EntityEnhancer, ModelService} from '../../../api';

import {SessionService} from '../../../portal/app/service/session.service';


import {KolibriScriptLibrary} from '../api/kolibri-script-library.service';

@Injectable()
export class KolibriScriptContext extends AbstractKolibriScriptContext {

  public constructor(kolibriScriptLibrary: KolibriScriptLibrary, sessionService: SessionService, modelService: ModelService,
                     private circularService: CircularService) {
    super(kolibriScriptLibrary, sessionService, modelService);
    this.entityEnhancer = new EntityEnhancer();
  }


  public get jsCompiler(): AbstractJsCompiler {
    return this.circularService.jsCompiler;
  }
}
