import {Injectable} from '@angular/core';
import {CodeEditorLanguages, CodeEditorOptions, ConditionBuilderOptions, CriteriaOperator, EntityModel, Field} from '@wspsoft/frontend-backend-common';
import {MaybePromise} from '@wspsoft/underscore';
import {QueryOperator} from '../../../../entities/query-operator';

@Injectable()
export class QueryBuilderDataService {
  public entityMeta: EntityModel;
  public recordEntity: string;
  public additionalOperators: QueryOperator[] = [];
  public codeEditorOptions: CodeEditorOptions[];
  public additionalFields: (Field & { validOperators: (CriteriaOperator)[] })[] = [];
  public localized: boolean;
  public allowScriptedValue: boolean;
  public allowScriptedRule: boolean;
  public conditionBuilderOptions: ConditionBuilderOptions[];
  public scriptLanguage: CodeEditorLanguages;
  public allowDotWalk: boolean = true;
  public search: (result: Field[], queryString?: string) => MaybePromise<Field[]>;

  public constructor() {
  }
}
