import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JoyrideService} from 'ngx-joyride';
import {CircularService, ModelService} from '../../../api';
import {UiUtil} from '../util/ui-util';

@Injectable()
/** Service Class for the GuidedTour functions*/
export class GuidedTourService {
  private waitingTime = 1;
  private themeColor: string = UiUtil.getVariable('lightbox').trim().substring(0, 7);

  public constructor(private readonly joyrideService: JoyrideService, private modelService: ModelService,
                     private router: Router, private circularService: CircularService) {
  }

  /**
   * Starts a guidedTour
   * @param {string} tourName - The Name of the Tour that is supposed to start
   * */
  public startTour(tourName: string): void {
    const stepData = this.modelService.getGuidedTour(tourName).mapping.map(e => this.modelService.getGuidedTourStep(e.guidedTourStepId));
    const stepsArray = [];
    // searches all steps of the tour with the given tourName and create a proper redirect string if needed
    const stepLocations = {};
    for (const st of stepData) {
      if (st.redirectPath) {
        this.waitingTime = 400;
        stepLocations[st.name] = st.redirectPath;
      }
      stepsArray.push(st.name);
    }
    this.joyrideService.startTour(
      {
        steps: stepsArray,
        themeColor: this.themeColor,
        // needed when navigating to anotherPage because of Loading times, defaults to = 1
        waitingTime: this.waitingTime
      }
    ).subscribe(s => {
      if (stepLocations[s.name]) {
        // navigate if it is different from the current location
        if (this.router.url !== stepLocations[s.name]) {
          void this.router.navigateByUrl(stepLocations[s.name]);
        }
      } else {
        stepLocations[s.name] = this.router.url;
      }
      const script = this.modelService.getGuidedTourStep(s.name).actionScript;
      this.circularService.scriptExecutor.runScript(script, {}, this.circularService.sessionService.primaryFormId,
        `GuidedTourStep:${s.name}`);
    });
  }

  /**
   * Starts a Tour with one Step only
   * */
  public startTourSingleStep(name: string): void {
    this.joyrideService.startTour(
      {
        steps: [name],
        themeColor: this.themeColor
      }
    );
  }
}

