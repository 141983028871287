import {Injectable} from '@angular/core';
import {MomentUtil} from '@wspsoft/frontend-backend-common';
import * as moment from 'moment';
import {Converter} from './converter';

@Injectable()
export class DateConverterService implements Converter<Date | Date[], string | string[]> {
  public timeOnly: boolean;

  public getAsObject(s: string | string[]): Date | Date[] {
    if (Array.isArray(s)) {
      if (!s.length) {
        return s as any as Date[];
      }
      return s.map(s1 => moment(s1.replace(MomentUtil.UTC_FLAG, '')).toDate());
    }
    if (s) {
      return moment(s.replace(MomentUtil.UTC_FLAG, '')).toDate();
    }
    return null;
  }

  public getAsString(x: Date | Date[]): string | string[] {
    if (x) {
      if (Array.isArray(x)) {
        if (!x.length) {
          return x as any as string[];
        }
        return x.map(x1 => this.getAsString(x1) as string);
      }
      if (this.timeOnly) {
        const moment1 = moment(x);
        if (moment1.isDST()) {
          moment1.add(1, 'h');
        }
        return moment.utc(moment1).year(1970).month(0).date(1).toISOString();
      }
      // always write back as utc
      return moment.utc(x).toISOString();
    }
    return null;
  }
}
