import {KolibriEntity} from './kolibri-entity';
import {ShareableEntity} from './shareable-entity';
import {UserGroup} from './user-group';

/**
 * mapping entity for m2m ShareableEntity to UserGroup.
 */
export abstract class ShareableEntityToUserGroup extends KolibriEntity {
  public shareableEntity: ShareableEntity;
  public shareableEntityId?: string;
  public userGroup: UserGroup;
  public userGroupId?: string;
}
