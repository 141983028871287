import {EventEmitter, Injectable} from '@angular/core';
import {AbstractKolibriObservableService} from '@wspsoft/frontend-backend-common';
import {Subscription} from 'rxjs';

@Injectable()
export class KolibriObservableService extends AbstractKolibriObservableService<EventEmitter<any>> {

  protected listenOn(event: string, fn: (data: any) => void): Subscription {
    return this.observables[event].subscribe((data) => fn(data));
  }

  protected listenOnce(event: string, fn: (data: any) => void): void {
    const subscription = this.observables[event].subscribe((data) => {
      fn(data);
      subscription.unsubscribe();
    });
  }

  protected getEventEmitter(): EventEmitter<any> {
    return new EventEmitter();
  }

  protected emitEvent(event: string, data: any): void {
    this.observables[event].emit(data);
  }
}
