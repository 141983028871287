import {EventEmitter, NgZone} from '@angular/core';
import {
  AbstractJsCompiler,
  AbstractKolibriScriptExecutor,
  AbstractModelService,
  EnhancementLevel,
  EntityModel,
  EntityServiceOptions,
  FirstLevelEntityEnhancer,
  KolibriEntity,
  LazyLoaderHandler,
  ScriptAction,
  ScriptTrigger,
  User,
  Utility
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';

export class EntityEnhancer<E extends KolibriEntity> extends FirstLevelEntityEnhancer<E> {
  public constructor(entityServiceFactory?: any, options?: EntityServiceOptions, entityMeta?: EntityModel,
                     modelService?: AbstractModelService, jsCompiler?: AbstractJsCompiler,
                     scriptExecutor?: AbstractKolibriScriptExecutor, user?: User) {
    super(entityServiceFactory, options, entityMeta, modelService, jsCompiler, scriptExecutor, user);
  }

  private static addObservable(formId: string, key: string, usedFields: { [key: string]: { [key: string]: EventEmitter<any> } },
                               sessionService: any): void {
    // this ignores any errors when the observable does not exists
    // so in case a script is not automatically updated, check here
    const currentRecordObservable = sessionService.viewData[formId]?.currentRecordObservables;
    if (currentRecordObservable && currentRecordObservable[key]) {
      usedFields[key] = currentRecordObservable[key];
    }
  }

  /**
   * registers observables for every field and fire field change event
   *
   * params are, any. we don't have access to these, but we cannot move this class and i wanted the functions in one place
   */
  public secondLevelEnhancing(record: KolibriEntity, formData: any, eventHandler: any, zone: NgZone,
                              liveSync?: (field: string, value: any) => void): KolibriEntity {
    const debounces = {};
    const extendedEntity = this.cloneEnhancer(record);
    extendedEntity.enhanced = EnhancementLevel.Second;
    const meta = this.getMeta(record);
    // in case the prev record is also extended
    extendedEntity._onRead = (record as any)._onRead;
    extendedEntity._onWrite = function (key, newValue, oldValue) {
      // in case the prev record is also extended
      (record as any)._onWrite?.(key, newValue, oldValue);

      const relationName: any = Utility.unparameterizeEntityName(key);
      const relation = this._enhancer.modelService.getRelation(record.entityClass, relationName);
      const isRelation = Utility.isRelation(relation);
      const isToMany = relation && Utility.isToManyRelation(relation);

      // debounce necessary (field points to itself)
      debounces[key] ??= _.debounce((oldValue1: any, newValue1: any) =>
        zone.run(() =>
          eventHandler.fire(ScriptAction.DISPLAY, ScriptTrigger.ON_FIELD_CHANGE, {
              oldValue: oldValue1,
              newValue: newValue1,
              field: key
            },
            meta, isRelation ? relationName : key)
        ), 50);
      // @ts-ignore
      if (!this.skipFieldChange) {
        debounces[key](oldValue, newValue);
      }

      formData.currentRecordObservables[key]?.emit(newValue);
      if (!this.record.localExports.syncs || !this.record.localExports.syncs[key]) {
        liveSync(key, newValue);
      } else {
        delete this.record.localExports.syncs[key];
      }

      // auto complete fields trigger the field (without) id directly
      if (isRelation && !isToMany) {
        const parameterizeEntityName = Utility.parameterizeEntityName(relationName);
        if (key === relationName) {
          formData.currentRecordObservables[parameterizeEntityName]?.emit(newValue);
          // the db insert just fills in the id so we have to emit the object instead
        } else if (key === parameterizeEntityName) {
          _.maybeAwait(this[relationName], e => {
            formData.currentRecordObservables[relationName]?.emit(e);
          });
        }
      }
    };
    // start setting up observables for every field
    for (const key of Object.keys(_.getAllProperties(record))) {
      formData.currentRecordObservables[key] ??= new EventEmitter<any>();
    }

    return extendedEntity as any as E;
  }

  /**
   * adds tracking additions for the accessed fields
   */
  public thirdLevelEnhancing(formId: string, record: KolibriEntity,
                             usedFields: { [key: string]: { [key: string]: EventEmitter<any> } }, sessionService: any): KolibriEntity {
    const extendedEntity = this.cloneEnhancer(record);
    extendedEntity.enhanced = EnhancementLevel.Third;
    // in case the prev record is also extended
    extendedEntity._onWrite = (record as any)._onWrite;
    extendedEntity._onRead = function (key) {
      // in case the prev record is also extended
      (record as any)._onRead?.(key);
      EntityEnhancer.addObservable(formId, key, usedFields, sessionService);
    };
    return extendedEntity as any as E;
  }

  public cloneEnhancer(record: KolibriEntity): LazyLoaderHandler<KolibriEntity> {
    const handler = record as any as LazyLoaderHandler<KolibriEntity>;
    const extendedEntity = new FirstLevelEntityEnhancer.instanceDefinitions[record.entityClass](record.record ?? record, handler._enhancer ?? this, false,
      record._securityInfo);
    extendedEntity._recordOld = record.recordOld;
    extendedEntity._relationCache = handler._relationCache;
    extendedEntity._securityInfo = handler._securityInfo;
    extendedEntity._currentVariables = handler._currentVariables;
    for (const variableHandler of Object.values(handler._currentVariables)) {
      variableHandler.record = extendedEntity;
    }
    return extendedEntity;
  }
}
