import {Directive, ElementRef, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {UiUtil} from './ui-util';

// TypeScript cannot resolve Element correctly, and does not recognize that checkVisibility does exist in HTMLElement
type HTMLElementExtended = HTMLElement & { checkVisibility: (options?: {
    checkOpacity?: boolean;
    checkVisibilityCSS?: boolean;
  }) => boolean;
};

/**
 * abstract class for all components that utilize a ng form
 */
@Directive()
export abstract class FormComponent {
  @ViewChild('form', {static: false})
  public form: NgForm;
  protected showSuccessMessage: boolean = true;
  protected entityName: string = 'Form.Record';
  protected recordName: string;

  protected constructor(protected messageService: MessageService, protected translateService: TranslateService,
                        protected elementRef: ElementRef) {
  }

  public async save(cb?: () => void, validate: boolean = true, securedSave: boolean = true): Promise<boolean> {
    const success = await this.checkForm(true, validate, securedSave);

    this.addSuccessMessage(success);

    cb?.();

    return success;
  }

  public async checkForm(save: boolean = true, validate: boolean = true, securedSave: boolean = true): Promise<boolean> {
    // if valid do actual save
    if (!validate || await this.validate()) {
      if (save) {
        return this.doSave(securedSave);
      }
      return true;
    }
    return false;
  }

  public abstract doSave(securedSave: boolean): Promise<boolean>;

  public addSuccessMessage(success: boolean): void {
    if (success && this.showSuccessMessage) {
      this.messageService.add({
        severity: 'success',
        summary: '',
        detail: this.translateService.instant('Form.SaveMessage',
          {entityName: this.translateService.instant(this.entityName), recordName: this.recordName || ''})
      });

      this.markAsUntouched();
    }
  }

  public validate(showMessage: boolean = true, autoFocus: boolean = true): Promise<boolean> {
    this.markAsDirty();
    const promise = new Promise<boolean>(resolve => {
      const subscription = this.form.statusChanges.subscribe(value => {
        if (value !== 'PENDING') {
          this.checkFormIsValid(resolve, showMessage, autoFocus);
          // we are done with validation, lo longer need to subscript
          subscription.unsubscribe();
        }
      });
    });
    this.form.form.updateValueAndValidity();
    return promise;
  }

  /**
   * show a message that validation failed
   */
  protected showValidationFailedMessage(): void {
    this.messageService.add({
      severity: 'error',
      summary: '',
      detail: this.translateService.instant('Form.FailedValidationMessage'),
      key: 'growl'
    });
  }

  protected focusFirstEmptyInput(): void {
    // do not refocus!
    // @ts-ignore
    if (!document.activeElement || document.activeElement !== document.body || window.e2eEnvironment) {
      return;
    }

    const activeClasses = document.activeElement.className;
    // only grab focus when not focusing an input already
    if (activeClasses.includes('ng-valid') || activeClasses.includes('ng-invalid')) {
      return;
    }
    const entry = this.findInputFormGroup(this.form, (value: FormGroup) => (value.controls.native || value.controls.ui)?.value === undefined);
    // try to find first invalid input
    (document.evaluate(`.//*[@name="${entry.key}"]//*[@name="native"]`, this.elementRef.nativeElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE,
      null)
      .singleNodeValue as HTMLElement)
      ?.focus();
  }

  /**
   * check if form is valid and show a message if not
   */
  private checkFormIsValid(resolve: (value?: (Promise<boolean> | boolean)) => void, showMessage: boolean = true, autoFocus: boolean = true): void {
    let result = true;
    // if valid do actual save
    if (!this.form.valid) {
      if (autoFocus) {
        this.focusFirstInvalidInput();
      }
      if (showMessage) {
        this.showValidationFailedMessage();
      }
      result = false;
    }
    // resolve this promise and continue execution
    resolve(result);
  }

  /**
   * mark all form elements as dirty
   */
  private markAsDirty(): void {
    UiUtil.markAsDirty(this.form.controls);
  }

  /**
   * mark all form elements as untouched
   */
  private markAsUntouched(): void {
    function markAsUnTouchedRecursive(controls: { [p: string]: AbstractControl }): void {
      for (const control of Object.values(controls)) {
        if (control instanceof FormGroup) {
          markAsUnTouchedRecursive(control.controls);
        } else {
          control.markAsPristine();
          control.markAsUntouched();
          control.updateValueAndValidity();
        }
      }
    }

    markAsUnTouchedRecursive(this.form.controls);
  }

  /**
   * find in the form tree the group that matches the criteria
   */
  private findInputFormGroup(control: FormGroup | NgForm, conditionFn: (control: FormGroup) => boolean): { key: string; value: AbstractControl } {
    for (const [key, value] of Object.entries(control.controls)) {
      if (value instanceof FormGroup) {
        if ((!!value.controls.ui || !!value.controls.native || !!value.controls.portal) && conditionFn(value)) {
          return {key, value};
        }
        const result = this.findInputFormGroup(value, conditionFn);
        if (result) {
          return result;
        }
      }
    }
  }

  private focusFirstInvalidInput(): void {
    // try to find first invalid input
    const entry = this.findInputFormGroup(this.form, (e: FormGroup) => e.invalid);
    if (entry) {
      // try to find first invalid input
      const labelElement: HTMLElementExtended = (document.evaluate(`.//*[@name="${entry.key}"]//label`,
        this.elementRef.nativeElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as HTMLElementExtended);

      if (labelElement) {
        const nativeOrLabel = (document.evaluate(`.//*[@name="${entry.key}"]//*[@name="native" or contains(@class, 'fakeNative')]`,
          this.elementRef.nativeElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as HTMLElementExtended) ?? labelElement;
        // if element is visible then focus on the element
        if (nativeOrLabel.checkVisibility()) {
          this.focusElement(nativeOrLabel);
          return;
        }

        // else wait until the tab header is marked with error and then focus the element
        let tabEl = null;
        void UiUtil.waitFor(() => {
            // get the first tab header that is marked as error depending on the element and its tab section the element is in
            tabEl = (document.evaluate('./ancestor::portal-tab-section//h4[contains(@class, \'p-state-error\')]',
              labelElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue as HTMLElement);
            // tab element is null if there is no h4 in tab section that is marked as error
            return !!tabEl;
          },
          () => {
            tabEl.click();
            /*
              the click does not mean the element is visible directly so the visibility must be checked first.
              This logic waits max. 200ms until the given element is visible and set the focus
             */
            void UiUtil.waitFor(
              () => nativeOrLabel.checkVisibility(),
              () => this.focusElement(nativeOrLabel),
              null, 200, 10);
        }, () => this.focusElement(nativeOrLabel) , 200, 10);
      }
    }
  }

  /**
   * scrolls the given element into view and set the focus to it
   * @param element the html element to set the focus to
   * @private
   */
  private focusElement(element: HTMLElement): void {
    element.scrollIntoView({block:'center', behavior: 'smooth'});
    // wait until the scroll has ended, then to the stuff
    UiUtil.waitForScrollEnd().then(() => {
      if (element.className.indexOf('fakeNative') >= 0) {
        // click event breaks animation so wait some time
        element.click();
      } else {
        element.focus({preventScroll: true});
      }
      const elementsByTagName = element.getElementsByTagName('input');
      if (elementsByTagName?.length) {
        elementsByTagName[0]?.focus({preventScroll: true});
      }
    });
  }
}
