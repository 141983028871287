import {Injectable} from '@angular/core';
import {AbstractJsCompiler, AbstractKolibriScriptExecutor, User} from '@wspsoft/frontend-backend-common';

@Injectable()
export class CircularService {
  public currentUser: User;
  public scriptExecutor: AbstractKolibriScriptExecutor;
  public layoutConditionService: any;
  public sessionService: any;
  public jsCompiler: AbstractJsCompiler;

  public constructor() {
  }
}
