import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractModelTranslationService, EntityModel, Field} from '@wspsoft/frontend-backend-common';

import {ModelService} from '../coded/model.service';
import {EntityServiceFactory} from '../generated/entity-service-factory';

@Injectable()
export class ModelTranslationService extends AbstractModelTranslationService {
  private alreadyTranslatedField: { [label: string]: Field } = {};

  public constructor(translateService: TranslateService, modelService: ModelService,
                     entityServiceFactory: EntityServiceFactory) {
    super(translateService, modelService, entityServiceFactory);
  }

  /**
   * check and modify the field label if there are duplicates of translations on the current site
   * if the originalEntity is given, it will check the label for entity only,
   *   so that e.g. The column header in a dialog will not directly be harmed from the column header of the parent list
   *
   * @param field the field to possibly modify if it is still on the page
   * @param originalEntity the original entityModel of the site (or dialog)
   */
  public translateDuplicate(field: Field, originalEntity?: EntityModel): void {
    const key = field.label + (originalEntity ? '_' + originalEntity.name : '');
    if (!this.alreadyTranslatedField.hasOwnProperty(key)) {
      this.alreadyTranslatedField[key] = field;
    }

    const alreadyTranslatedFieldElement = this.alreadyTranslatedField[key];
    const duplicate = alreadyTranslatedFieldElement && alreadyTranslatedFieldElement.id !== field.id;
    if (duplicate) {
      // the entity of the field is not necessarily the original entity
      field.label += ` (${this.translateEntity(this.modelService.getEntity(field.entityId))})`;
    }
  }

  public reset(): void {
    this.alreadyTranslatedField = {};
  }
}
