<div class="condition-container">
  <ng-container *ngIf="value">
    <div class="condition">
      <div *ngIf="selectedEntity" class="condition__entity">
        <div class="condition__entity__value">
          <span class="condition__entity__value__text">{{selectedEntity}}</span>
        </div>
      </div>
      <div class="condition__field">
        <div *ngIf="value.columnName" class="condition__field__value">
          <span class="condition__field__value__text">{{value.columnName}}</span>
        </div>
      </div>
      <div (click)="operatorOverlayVisible = true" class="condition__operator">
        <div *ngIf="value.operator" class="condition__operator__value">
          <span class="condition__operator__value__text">{{value.operator}}</span>
        </div>
      </div>
      <div (click)="valuesOverlayVisible = true" class="condition__value">
        <div *ngIf="value.value" class="condition__value__value">
          <span class="condition__value__value__text">{{value.value}}</span>
        </div>
      </div>
    </div>
    <div class="condition__buttons">
      <button (click)="clearCondition()" class="condition__buttons__button">Add Condition</button>
    </div>
  </ng-container>
</div>
<div class="condition-overlays">
  <p-overlayPanel #entityOverlay *ngIf="multiple">
    <ng-template pTemplate="content">
      <p-listbox [(ngModel)]="selectedEntity" [options]="entities" [style]="{'width':'15rem'}" optionLabel="name">
        <ng-template pTemplate="filtericon">
          <span class="fa fa-search"></span>
        </ng-template>
      </p-listbox>
    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #entityOverlay>
    <ng-template pTemplate="content">
      <p-listbox [(ngModel)]="value.columnName" [options]="fields" [style]="{'width':'15rem'}" optionLabel="name">
        <ng-template pTemplate="filtericon">
          <span class="fa fa-search"></span>
        </ng-template>
      </p-listbox>
    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #entityOverlay>
    <ng-template pTemplate="content">
      <p-listbox [(ngModel)]="value.operator" [options]="operators" [style]="{'width':'15rem'}" optionLabel="name">
        <ng-template pTemplate="filtericon">
          <span class="fa fa-search"></span>
        </ng-template>
      </p-listbox>
    </ng-template>
  </p-overlayPanel>
  <p-overlayPanel #entityOverlay>
    <ng-template pTemplate="content">
      <p-listbox [(ngModel)]="value.value" [options]="allValues" [style]="{'width':'15rem'}" optionLabel="name">
        <ng-template pTemplate="filtericon">
          <span class="fa fa-search"></span>
        </ng-template>
      </p-listbox>
    </ng-template>
  </p-overlayPanel>
</div>