import {Injectable, NgZone} from '@angular/core';
import {AbstractKolibriMessage, DynamicContentSize, DynamicContentType} from '@wspsoft/frontend-backend-common';
import {Message, MessageService} from 'primeng/api';
import * as uuidv4 from 'uuid-random';
import {DynamicContentService, OneConfirmService} from '../../../ui';

@Injectable()
export class KolibriMessage extends AbstractKolibriMessage {
  public constructor(private messageService: MessageService, private oneConfirmService: OneConfirmService,
                     private dynamicContentService: DynamicContentService, private ngZone: NgZone) {
    super();
  }

  public addLog(message: string, severity: Message | string = 'info', key: string = 'growl'): void {
    this.ngZone.run(() => {
      this.messageService.add(typeof severity === 'string' ? {
        detail: message,
        severity,
        key
      } : {
        severity: 'info',
        ...severity,
        detail: message,
        key
      });
    });
  }

  /**
   * removes all displayed logs
   */
  public clearLogs(key: string = 'growl'): void {
    this.ngZone.run(() => {
      this.messageService.clear(key);
    });
  }

  /**
   * opens a confirm toast and asks user for yes or no
   */
  public backgroundConfirm(message: string, severity: string = 'warn', scriptAccept?: () => Promise<any>,
                           scriptReject?: () => Promise<any>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.messageService.add({
        closable: false,
        sticky: true,
        severity,
        id: uuidv4(),
        detail: message,
        summary: '',
        data: {
          accept: async () => {
            if (scriptAccept) {
              await scriptAccept();
            }
            this.oneConfirmService.confirmCallback();
            resolve();
          },
          reject: async () => {
            if (scriptReject) {
              await scriptReject();
            }
            this.oneConfirmService.confirmCallback();
            reject({type: 'KolibriScriptError', suppressGrowl: true});
          }
        }
      });
    });
  }

  /**
   * opens a confirm dialog and asks user for yes or no
   * @param message content question
   * @param scriptAccept execute when user presses yes
   * @param scriptReject execute when user presses no
   * @param size determines the size of the dialog
   */
  public confirm(message: string, scriptAccept?: () => Promise<any>, scriptReject?: () => Promise<any>,
                 size: DynamicContentSize = DynamicContentSize.SMALL): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dynamicContentService.add(DynamicContentType.DIALOG, 'scriptConfirm', 'LayoutConfirmationEntity', {
        scriptData: {
          accept: async () => {
            if (scriptAccept) {
              await scriptAccept();
            }
            this.dynamicContentService.remove('scriptConfirm');
            resolve();
          },
          reject: async () => {
            if (scriptReject) {
              await scriptReject();
            }
            this.dynamicContentService.remove('scriptConfirm');
            reject({type: 'KolibriScriptError', suppressGrowl: true});
          }
        },
        data: {
          description: message,
          type: 'confirm',
          entityClass: 'ConfirmationEntity'
        },
        closable: false,
        size
      });
    });
  }

  /**
   * opens a prompt and asks user to fill the textarea
   * @param title header title of the dialog
   * @param message content question
   * @param size determines the size of the dialog
   */
  public prompt(title: string, message: string, size?: DynamicContentSize): Promise<void> {
    return new Promise<void>((resolve) => {
      this.dynamicContentService.add(DynamicContentType.DIALOG, 'scriptPrompt', 'LayoutConfirmationEntity', {
        label: title,
        scriptData: {
          accept: (promptData) => {
            this.dynamicContentService.remove('scriptPrompt');
            resolve(promptData);
          },
          reject: () => {
            this.dynamicContentService.remove('scriptPrompt');
            resolve(null);
          }
        },
        data: {
          description: message,
          type: 'prompt',
          entityClass: 'ConfirmationEntity'
        },
        closable: false,
        size
      });
    });
  }
}
