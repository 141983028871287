import {_} from '@wspsoft/underscore';
import {Attachment} from '../model/database/attachment';

export abstract class AttachmentUtil {

  /**
   * converts a file into a base64 string
   * @param file the file to convert
   */
  public static fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt) => {
          if (evt.target.readyState === FileReader.DONE) {
            resolve(this.arrayBufferToBase64(evt.target.result as ArrayBuffer));
          }
        };
      } catch (e: any) {
        reject(e);
      }
    });
  }

  /**
   * fills the attachment with the data of the file
   * @param attachment the attachment to fill with data
   * @param file the file to use
   */
  public static async fileToAttachment(attachment: Attachment, file: File): Promise<void> {
    const mimeType = _.isNullOrEmpty(file.type) ? 'application/octet-stream' : file.type;
    attachment.fileName = this.getProperFiletype(file.name, mimeType);
    attachment.mimeType = mimeType;
    attachment.payload = await this.fileToBase64(file);
  }

  // this function returns the proper filetype ending for a specific type
  // TODO THIS FUNCTION WILL BE REMOVED AS SOON AS ISSUE #5504 is resolved
  private static getProperFiletype(filename: string, contentType: string): string {
    switch (contentType) {
      case 'message/rfc822':
        return ['.eml', '.mht', '.mhtml', '.mime', '.nws'].some(ending => filename.endsWith(ending)) ? filename : `${filename}.eml`;
      default:
        return filename;
    }
  }


  /**
   * converts an array buffer to a base64 string
   */
  public static arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    let binary = '';
    for (const byte of new Uint8Array(arrayBuffer)) {
      binary += String.fromCharCode(byte);
    }
    return window.btoa(binary);
  }
}
