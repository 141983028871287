import {DesignerEntity} from './designer.entity';

export abstract class MenuItem extends DesignerEntity {

  public command?: any;
  public hotkeys?: string;
  public parent?: MenuItem;
  public parentId?: string;
  public items?: MenuItem[];
  public name?: string;
  public applicationId?: string;
  public order?: number;
}
