import {ChangeDetectorRef, Directive, EventEmitter, OnDestroy} from '@angular/core';
import {RenderConditionExecutor} from './render-condition-executor';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive()
// tslint:disable-next-line:directive-class-suffix
export class RenderConditionContainer<T extends RenderConditionExecutor<F>, F> implements OnDestroy {
  public executors: T[] = [];
  public onChange: EventEmitter<void> = new EventEmitter();

  public constructor(protected cdr?: ChangeDetectorRef) {
  }

  public trackById = (index: number, item: T): string => item.id;

  public init(executor: T[]): void {
    this.executors = executor;
    this.refresh();
  }

  public refresh(): void {
    this.onChange.emit();
    this.cdr?.markForCheck();
  }

  /**
   * destroy all subscriptions
   */
  public ngOnDestroy(): void {
    for (const executor of this.executors || []) {
      executor.destroy();
    }
  }
}
