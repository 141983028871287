<!--oneContent start-->
<div #headerButtonsContainer>
  <ng-container *ngIf="headerButtons && main" [ngTemplateOutlet]="headerButtons"></ng-container>
</div>

<aside class="one-content__left">
  <ng-container *ngIf="contentLeft" [ngTemplateOutlet]="contentLeft"></ng-container>
</aside>

<main (resized)="checkViewport()" *ngIf="contentMain" [id]="formId + 'ContentMain'" [ngClass]="{'one-content__main--nested': !main}" class="one-content__main">
  <ng-container [ngTemplateOutlet]="contentMain"></ng-container>
</main>

<aside [ngClass]="{
  'one-content__right': true,
  'one-content__right--phone': isViewportPhone,
}">
  <ng-container *ngIf="contentRight" [ngTemplateOutlet]="contentRight"></ng-container>
</aside>

<div *ngIf="onePageFunctional" class="one-page-functional">
  <ng-container [ngTemplateOutlet]="onePageFunctional"></ng-container>
</div>
