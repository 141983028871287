import {Location} from '@angular/common';
import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {DynamicContent, KolibriEntity, ListLayout} from '@wspsoft/frontend-backend-common';
import {CircularService, ModelService} from '../../../api';
import {RedirectorService, RouterOptions} from '../../../ui';


@Injectable()
export class KolibriRouter {
  public constructor(private router: Router, private modelService: ModelService, private ngZone: NgZone,
                     private activatedRoute: ActivatedRoute,
                     private location: Location, private redirectorService: RedirectorService, private circularService: CircularService) {
  }

  public get params(): { [key: string]: any } {
    if (this.activatedRoute.children.length) {
      return this.activatedRoute.children[0].snapshot.params;
    }

    return this.activatedRoute.snapshot.params;
  }

  public get queryParams(): { [key: string]: any } {
    return this.activatedRoute.snapshot.queryParams;
  }

  public forward({skipLeaveMessage = false}: RouterOptions = {}): void {
    this.checkSkipLeaveMessage(skipLeaveMessage);

    this.location.forward();
  }

  public back({skipLeaveMessage = false}: RouterOptions = {}): void {
    this.checkSkipLeaveMessage(skipLeaveMessage);

    this.location.back();
  }

  /**
   * same as RedirectorService.redirectToEntity but with ngzone wrapper
   * @see RedirectorService.redirectToEntity
   */
  public redirectToEntity(entity: KolibriEntity, event: MouseEvent | TouchEvent | KeyboardEvent = {} as any, queryString: string = '',
                          layoutList?: ListLayout, options: RouterOptions & Partial<DynamicContent> = {}): Promise<boolean> {
    const {skipLeaveMessage = false} = options;
    this.checkSkipLeaveMessage(skipLeaveMessage);

    return this.ngZone.run(() => this.redirectorService.redirectToEntity(entity, event, queryString, layoutList, options));
  }

  /**
   * same as RedirectorService.openLayout but with ngzone wrapper
   */
  public openLayout(layoutName: string, event: MouseEvent | TouchEvent | KeyboardEvent,
                    options?: RouterOptions & Partial<DynamicContent>): Promise<boolean> {
    return this.ngZone.run(() => this.redirectorService.openLayout(layoutName, event, options));
  }


  /**
   * same as RedirectorService.openRecordInLayout but with ngzone wrapper
   */
  public openRecordInLayout(record: KolibriEntity, layoutName: string, event: MouseEvent | TouchEvent | KeyboardEvent,
                            options?: RouterOptions & Partial<DynamicContent>): Promise<boolean> {
    return this.ngZone.run(() => this.redirectorService.openRecordInLayout(record, layoutName, event, options));
  }


  /**
   * same as RedirectorService.getEntityLayout
   * @see RedirectorService.getEntityLayout
   */
  public getEntityLayout(entity: KolibriEntity, layoutList?: ListLayout, formId?: string): Promise<string> {
    return this.redirectorService.getEntityLayout(entity, layoutList, formId);
  }


  /**
   * same as RedirectorService.getEntityUrl
   * @see RedirectorService.getEntityUrl
   */
  public getEntityUrl(entity: KolibriEntity, layoutList?: ListLayout, formId?: string): Promise<string> {
    return this.redirectorService.getEntityUrl(entity, layoutList, formId);
  }

  public reload({angularOnly = true, skipLeaveMessage = false, skipLocationChange = false}: RouterOptions = {}): Promise<boolean> | void {
    this.checkSkipLeaveMessage(skipLeaveMessage);

    if (angularOnly) {
      return this.ngZone.run(() =>
        this.router.navigateByUrl(this.router.url, {replaceUrl: true, skipLocationChange})
      );
    } else {
      window.location.reload();
    }
  }

  public navigateByUrl(url: string,
                       {
                         angularOnly = true,
                         skipLeaveMessage,
                         extras,
                         newTab
                       }: RouterOptions & { extras?: NavigationExtras; newTab?: boolean } = {}): Promise<boolean> | boolean {
    this.checkSkipLeaveMessage(skipLeaveMessage);

    if (newTab) {
      window.open(url, '_blank')?.blur();
      window.focus();
      return true;
    }
    if (!angularOnly) {
      window.open(url, '_self');
      return true;
    }

    return this.ngZone.run(() =>
      this.router.navigateByUrl(url, extras)
    );
  }

  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.ngZone.run(() =>
      this.router.navigate(commands, extras)
    );
  }

  public openLayoutDesigner(layoutName: string): Promise<boolean> {
    return this.navigate(['DesignerApplication', 'layoutDesigner'], {
      queryParams: {
        layoutId: this.modelService.getLayout(layoutName).id
      }
    });
  }

  public openLifecycleDesigner(entityName: string): Promise<boolean> {
    return this.navigate(['DesignerApplication', 'lifecycleDesigner'], {
      queryParams: {
        entityId: this.modelService.getEntity(entityName).id
      }
    });
  }

  private checkSkipLeaveMessage(skipLeaveMessage: boolean): void {
    if (skipLeaveMessage) {
      this.circularService.sessionService.viewData[this.circularService.sessionService.primaryFormId].forceSkipLeaveMessage = true;
    }
  }
}
