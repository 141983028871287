<ng-container *ngIf="dialog">
  <ui-dialog [(visible)]="displayColumnToggler" [closable]="false" [dialogFooter]="true" header="{{'ColumnPicker.Header' | translate}}">
    <ng-template #content>
      <ng-container [ngTemplateOutlet]="picker"></ng-container>
    </ng-template>
    <ng-template #footer>
      <ui-button (clicked)="onCloseWithoutSave(); $event.cb()" icon="fas fa-fw fa-times" label="{{'Dialog.Cancel' | translate}}" type="cancel"></ui-button>
      <ui-button (clicked)="reset(); $event.cb();" icon="fas fa-fw fa-eraser" label="{{'ColumnPicker.ResetFields' |
    translate}}" type="cancel"></ui-button>
      <ui-button (clicked)="close();$event.cb();" icon="fas fa-fw fa-save" label="{{'Dialog.Save' |
    translate}}" type="primary"></ui-button>
    </ng-template>
  </ui-dialog>
</ng-container>
<ng-container *ngIf="!dialog" [ngTemplateOutlet]="picker"></ng-container>
<ng-template #picker>
  <p-pickList (onMoveAllToSource)="value = targetColumns" (onMoveAllToTarget)="value = targetColumns" (onMoveToSource)="value = targetColumns"
              (onMoveToTarget)="value = targetColumns" (onSourceReorder)="value = targetColumns" (onTargetReorder)="value = targetColumns" [dragdrop]="true"
              [responsive]="true" [showSourceControls]="false" [source]="sourceColumns" [target]="targetColumns" filterBy="header"
              styleClass="{{viewportMobile ? 'one-picklist--mobile' : ''}}"
              sourceFilterPlaceholder="{{'Placeholder.Search' | translate}}" sourceHeader="{{'ColumnPicker.Available' | translate}}"
              targetFilterPlaceholder="{{'Placeholder.Search' | translate}}" targetHeader="{{'ColumnPicker.Selected' | translate}}">
    <ng-template let-field pTemplate="item">
      {{field.header}}
    </ng-template>
    <ng-template pTemplate="targetfiltericon">
      <span class="fa fa-search"></span>
    </ng-template>
    <ng-template pTemplate="sourcefiltericon">
      <span class="fa fa-search"></span>
    </ng-template>
    <ng-template pTemplate="movealltosourceicon">
      <span class="fa fa-angle-double-left"></span>
    </ng-template>
    <ng-template pTemplate="movetosourceicon">
      <span class="fa fa-angle-left"></span>
    </ng-template>
    <ng-template pTemplate="movealltotargeticon">
      <span class="fa fa-angle-double-right"></span>
    </ng-template>
    <ng-template pTemplate="movetotargeticon">
      <span class="fa fa-angle-right"></span>
    </ng-template>
    <ng-template pTemplate="movebottomicon">
      <span class="fa fa-angle-double-down"></span>
    </ng-template>
    <ng-template pTemplate="movedownicon">
      <span class="fa fa-angle-down"></span>
    </ng-template>
    <ng-template pTemplate="movetopicon">
      <span class="fa fa-angle-double-up"></span>
    </ng-template>
    <ng-template pTemplate="moveupicon">
      <span class="fa fa-angle-up"></span>
    </ng-template>
  </p-pickList>
</ng-template>

