import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {AbstractMonacoService} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class MonacoService extends AbstractService implements AbstractMonacoService {
  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/monaco';
  }


  public loadMustacheLibrary(frontend: boolean, backend: boolean): Promise<string> {
    return this.doPost(`${this.basePath}/mustachelibrary`, {frontend, backend}, undefined, undefined, 'text');
  }
}
