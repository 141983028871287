import {Injectable} from '@angular/core';
import {AbstractKolibriRecordFactory} from '@wspsoft/frontend-backend-common';

import {EntityServiceFactory} from '../../../api';

@Injectable()
export class KolibriRecordFactory extends AbstractKolibriRecordFactory {
  public constructor(serviceFactory: EntityServiceFactory) {
    // @ts-ignore
    super(serviceFactory);
  }
}
