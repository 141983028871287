import {NgModule} from '@angular/core';
import {CriteriaFactory} from './app/criteria/criteria-factory';
import {ActivityLogService} from './app/service/coded/activity-log.service';
import {AttachmentService} from './app/service/coded/attachment.service';
import {AuthService} from './app/service/coded/auth.service';
import {BackendButtonService} from './app/service/coded/backend-button.service';

import {BackendSessionService} from './app/service/coded/backend-session.service';
import {ChartService} from './app/service/coded/chart.service';
import {DocumentGeneratorService} from './app/service/coded/document-generator.service';
import {FileService} from './app/service/coded/file.service';
import {FlowService} from './app/service/coded/flow.service';
import {GitService} from './app/service/coded/git.service';
import {ModelDeploymentService} from './app/service/coded/model-deployment.service';
import {ModelService} from './app/service/coded/model.service';
import {MonacoService} from './app/service/coded/monaco.service';
import {RuntimeModelService} from './app/service/coded/runtime-model.service';
import {SettingsService} from './app/service/coded/settings.service';
import {UserService} from './app/service/coded/user.service';
import {VcsService} from './app/service/coded/vcs.service';
import {EntityServiceFactory} from './app/service/generated/entity-service-factory';
import {CircularService} from './app/service/util/circular.service';
import {ModelTranslationService} from './app/service/util/model-translation.service';
import {RelationService} from './app/service/util/relation.service';
import {TypeService} from './app/service/util/type.service';


@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    FileService,
    CriteriaFactory,
    EntityServiceFactory,
    UserService,
    ModelService,
    ModelDeploymentService,
    ChartService,
    BackendButtonService,
    BackendSessionService,
    TypeService,
    ModelTranslationService,
    SettingsService,
    RuntimeModelService,
    CircularService,
    AuthService,
    VcsService,
    ActivityLogService,
    GitService,
    RelationService,
    DocumentGeneratorService,
    MonacoService,
    FlowService,
    AttachmentService
  ]
})
export class ApiModule {
}
