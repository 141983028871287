import {Injectable} from '@angular/core';
import {AbstractJsCompiler, FlowElementConfiguration} from '@wspsoft/frontend-backend-common';
import {CircularService, FlowService, ModelService} from '../../../api';
import {KolibriScriptExecutor} from '../../index';


// additional criteria fields with 'script'
@Injectable()
export class JsCompilerService extends AbstractJsCompiler {

  public constructor(modelService: ModelService, scriptExecutor: KolibriScriptExecutor, circularService: CircularService, private flowService: FlowService) {
    super(modelService, scriptExecutor);
    circularService.jsCompiler = this;
  }

  public get flowConfigurations(): { [p: string]: FlowElementConfiguration } {
    return this.flowService.cache.elements;
  }
}
