import {_} from '@wspsoft/underscore';
import {AbstractKolibriScriptExecutor} from '../api/abstract-kolibri-script-executor';
import {CriteriaQueryJson} from '../criteria/json/criteria-query-json';
import {FlowElement} from '../model/database/flow-element';
import {WorkflowNode} from '../model/database/workflowInstance';
import {AbstractModelService} from '../service/coded/abstract-model.service';
import {CompilerReturnType} from './compiler-return-type';
import {LowCodeJsCompiler} from './low-code-js-compiler';

export abstract class AbstractJsCompiler extends LowCodeJsCompiler {
  protected constructor(modelService: AbstractModelService, scriptExecutor: AbstractKolibriScriptExecutor) {
    super(modelService, scriptExecutor);
  }

  /**
   * compile any criteria query to a runnable js script
   */
  public compile(query: { flowElements: FlowElement[]; workflowModel: any; nestedParentElementId: string },
                 returnType: CompilerReturnType.WORKFLOW): { [id: string]: WorkflowNode };
  public compile(query: CriteriaQueryJson | FlowElement[], returnType?: CompilerReturnType): string;
  public compile(query: CriteriaQueryJson | FlowElement[] | { flowElements: FlowElement[]; workflowModel: any; nestedParentElementId: string },
                 returnType: CompilerReturnType = CompilerReturnType.BOOLEAN): string | { [id: string]: WorkflowNode } {
    if (!query) {
      return '';
    }

    switch (returnType) {
      case CompilerReturnType.WORKFLOW:
        return this.compileWorkflowElements(query as { flowElements: FlowElement[]; workflowModel: any; nestedParentElementId: string });
      case CompilerReturnType.SCRIPT:
        return this.compileFlowElements(query as FlowElement[]);
      case CompilerReturnType.BOOLEAN:
        return this.compileBooleanScript(_.cloneDeep(query as CriteriaQueryJson));
      default:
        return this.compileQueryScript(_.cloneDeep(query as CriteriaQueryJson));
    }
  }
}
