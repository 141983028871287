import * as moment from 'moment';
import {AbstractKolibriScriptExecutor} from '../../api/abstract-kolibri-script-executor';
import {CriteriaQuery} from '../../criteria/criteria-query';
import {AbstractModelService} from '../../service/coded/abstract-model.service';
import {AccessControlCache} from '../../service/util/handler/user-handler';
import {AccessControlType} from './access-control';
import Dashboard from './dashboard';
import {GroupMembership} from './group-membership';
import {KolibriEntity} from './kolibri-entity';
import {Notification} from './notification';
import {TenantMembership} from './tenant-membership';
import {ThirdPartyCredential} from './third-party-credential';
import {UserProfile} from './user-profile';
import {VariableEntity} from './variable/variable-entity';

export interface RecordDiff {
  field: string;
  oldValue: any;
  newValue: any;
}

export abstract class User extends VariableEntity {
  public username: string;
  public password?: string;
  public initials?: string;
  public validFrom?: string;
  public validTill?: string;
  public shTitle?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public salutation?: string;
  public locked?: boolean;
  public autoLocked?: boolean;
  public lockedReason?: string;
  public selfRegistered?: boolean;
  public lockedSince?: string | moment.Moment;
  public lastLogin?: string | moment.Moment;
  public thirdPartyCredentials?: ThirdPartyCredential[];
  public groupMemberships?: GroupMembership[];
  public tenantMemberships?: TenantMembership[];
  public notifications?: Notification[];
  public activeProfile?: UserProfile;
  public activeProfileId?: string;
  public forcePasswordChange?: boolean;
  public passwordLastModifiedOn?: string;

  // generated data
  public language?: string;
  public timezone?: string;
  public aclData?: () => { cache: AccessControlCache; jsContext: AbstractKolibriScriptExecutor; modelService: AbstractModelService };

  // temporary data
  public hasToAuthenticate?: boolean;
  public rootTenantMode?: boolean;
  public currentCustomerApplicationId?: string;
  public loginMethod?: LoginMethod;
  public loginName?: string;
  public loginRetries?: number;
  public impersonatorId?: string;

  // security cache data
  public tenants?: { [tenantId: string]: { active: boolean; id: string; children: { active: boolean; id: string; name: string }[] } };
  public roles?: { [roleIdOrName: string]: { active: boolean; id: string } };
  public groups?: { [groupIdOrName: string]: { active: boolean; id: string } };

  // security functions
  public hasRole?: (role?: string, ignoreAdminRole?: boolean) => boolean;
  public hasRoles?: () => boolean;
  public hasTenant?: (tenant: string) => boolean;
  public hasGroup?: (group: string, ignoreAdminRole?: boolean) => boolean;
  public checkClientSidePermission?: (record: KolibriEntity, operation: string, level: string) => boolean;

  public abstract can?(operation: string, entity?: string, level?: string,
                       options?: {
                         type?: AccessControlType; record?: KolibriEntity;
                         additionalTypes?: AccessControlType[]; query?: CriteriaQuery<KolibriEntity>;
                       }): Promise<boolean>;
  public abstract can?(operation: string[], entity?: string, level?: string,
                       options?: {
                         type?: AccessControlType; record?: KolibriEntity;
                         additionalTypes?: AccessControlType[]; query?: CriteriaQuery<KolibriEntity>;
                       }): Promise<boolean[]>;
}

export enum LoginMethod {
  basic = 'basic', local = 'local', bearer = 'bearer', saml = 'saml', ldap = 'ldap', impersonate = 'impersonate', ldap2 = 'ldap2', saml2 = 'saml2'
}
