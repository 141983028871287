import {Injectable} from '@angular/core';
import {
  AbstractRuntimeModelService,
  Application,
  ApplicationModel,
  Button,
  CustomWebComponent,
  Entity,
  FieldResponse,
  KanbanColumn,
  LayoutSection,
  ListColumn,
  SectionField,
  SectionTab
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';

/**
 * the async function of this have no use in frontend, they are executed
 * by the model service by calling the rest api
 */
@Injectable()
export class RuntimeModelService extends AbstractRuntimeModelService {
  private kanbanColumns: { [idOrName: string]: KanbanColumn } = {};
  private listColumns: { [idOrName: string]: ListColumn } = {};
  private sectionFields: { [idOrName: string]: SectionField } = {};
  private layoutSections: { [idOrName: string]: LayoutSection } = {};
  private sectionTabs: { [idOrName: string]: SectionTab } = {};
  private webComponents: { [id: string]: CustomWebComponent } = {};
  private defaultColumns: { [entityId: string]: { [fieldName: string]: ListColumn } } = {};

  public getClientData(): Promise<ApplicationModel[]> {
    return Promise.resolve([]);
  }

  public getEntitiesLocalized(name?: string): Promise<Entity[]> {
    return Promise.resolve([]);
  }

  public getFieldsLocalized(entity: string, name?: string): Promise<FieldResponse> {
    return Promise.resolve(undefined);
  }

  public updateApplication(modelXml: string): Application {
    return undefined;
  }

  public cache(apps?: boolean): void {
    super.cache(apps);

    const entities = this.getEntities();
    const listColumns = _.flatMap(entities, 'listColumns');
    this.listColumns = _.zipObject(listColumns.map(t => t.id), listColumns);
    const kanbanColumns = _.flatMap(entities, 'kanbanColumns');
    this.kanbanColumns = _.zipObject(kanbanColumns.map(t => t.id), kanbanColumns);

    const sectionFields = _.flatMap(entities, 'sectionFields');
    this.sectionFields = _.zipObject(sectionFields.map(t => t.id), sectionFields);

    const layoutSections = _.flatMap(entities, 'layoutSections');
    this.layoutSections = _.zipObject(layoutSections.map(t => t.id), layoutSections);

    const sectionTabs = _.flatMap(entities, 'tabs');
    this.sectionTabs = _.zipObject(sectionTabs.map(t => t.id), sectionTabs);

    const buttons = _.flatMap(this.getApplications(), 'buttons');
    this.buttons = _.zipObject(buttons.map(t => t.id), buttons);

    const webComponents = _.flatMap(this.getApplications(), 'webComponents');
    this.webComponents = _.zipObject(webComponents.map(t => t.id), webComponents);

    const entityToListColumns = _.flatMap(entities, 'entityToListColumns');
    const colsByEntity = _.groupBy(entityToListColumns, 'entityId');
    for (const [entityId, cols] of Object.entries(colsByEntity)) {
      const columns = cols.map(t => this.listColumns[t.listColumnId]);
      this.defaultColumns[entityId] = _.zipObject(columns.map(t => t.name), columns);
    }
  }

  public getDefaultListColumn(entityId: string, fieldName: string): ListColumn {
    return this.defaultColumns[entityId]?.[fieldName];
  }

  public getKanbanColumn(id: string): KanbanColumn {
    return this.kanbanColumns[id];
  }

  public getListColumn(id: string): ListColumn {
    return this.listColumns[id];
  }

  public getSectionField(id: string): SectionField {
    return this.sectionFields[id];
  }

  public getSectionTab(id: string): SectionTab {
    return this.sectionTabs[id];
  }

  public getLayoutSection(id: string): LayoutSection {
    return this.layoutSections[id];
  }

  public getButton(id: string): Button {
    return this.buttons[id];
  }

  public getButtons(): Button[] {
    return Object.values(this.buttons);
  }

  public getWebComponent(id: string): CustomWebComponent {
    return this.webComponents[id];
  }

  public getWebComponents(): CustomWebComponent[] {
    return Object.values(this.webComponents);
  }
}
