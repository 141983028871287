<login-authentication-page (onEnter)="save()">
  <ng-template #header>
    <span class="one-login__greeting--big one-login__greeting--welcome">{{'MultiAuth.Greeting' | translate}} </span>
    <span>{{'MultiAuth.Welcome' | translate}}</span>
  </ng-template>
  <ng-template #content>
    <form #form="ngForm" class="one-login__form--token" method="post">
      <ng-container ngModelGroup="token">
        <ui-input-text [(ngModel)]="token" [require]="true" [updateWhileTyping]="true" label="{{'MultiAuth.TokenInput' | translate}}"
                       name="token"></ui-input-text>
      </ng-container>
      <ui-button (clicked)="resend(); $event.cb()" *ngIf="resendToken" class="one-login__button--resend" icon="fas fa-fw fa-redo-alt" label="{{'MultiAuth.ResendToken' |
          translate}}" type="icon-only"></ui-button>
      <footer class="one-login__footer one-token-login__footer">
        <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt" label="{{'MultiAuth.Submit' |
          translate}}" styleClass="one-login__button" type="primary"></ui-button>
        <ui-button (clicked)="logout()" [spinner]="false" class="one-login__button--full-width" icon="fas fa-fw fa-sign-out-alt" label="{{'LeftMenu.UserDropdown.Logout' |
          translate}}" styleClass="one-login__button" type="cancel"></ui-button>
        <div *ngIf="showMultiAuthErrorMessage" class="one-login__info">
          <span [innerHTML]="settingsService.platformSettings.multiAuthErrorMessage | safe: 'html'"></span>
        </div>
      </footer>
    </form>
  </ng-template>
</login-authentication-page>
