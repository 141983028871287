import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  AbstractRuntimeModelService,
  Attribute,
  ChoiceValue,
  CriteriaQuery,
  CriteriaType,
  Picklist,
  Relation
} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {MenuItem} from 'primeng/api';
import {CircularService, CriteriaFactory, ModelService, ModelTranslationService} from '../../../../api';
import {ChoiceValueConverterService} from '../converter/choice-value-converter.service';

@Component({
  selector: 'ui-entity-steps',
  templateUrl: './entity-steps.component.html',
  styleUrls: ['./entity-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChoiceValueConverterService]
})
/**
 * Wraps the ui-steps component and calculates the necessary data.
 * */
export class EntityStepsComponent implements OnInit {
  @Input()
  public entityName: string;
  @Input()
  public style: { [p: string]: any };
  @Input()
  public phaseProgressValueField: string;
  @Input()
  public phaseProgressValueScript: string;
  @Input()
  public data: any;
  @Input()
  public compactMode: boolean = false;
  public index: number;
  public model: MenuItem[];

  @HostBinding('class.one-steps')
  private cssClass: boolean = true;

  public constructor(private modelService: ModelService, private circularService: CircularService,
                     private modelTranslationService: ModelTranslationService, private criteriaFactory: CriteriaFactory,
                     private translateService: TranslateService, private choiceConverter: ChoiceValueConverterService,
                     private cdr: ChangeDetectorRef) {
  }

  public getStepAttribute(): Attribute {
    return this.modelService.getFieldInfo(this.entityName, this.phaseProgressValueField).field as Attribute;
  }

  public ngOnInit(): void {
    this.model = [];
    const stepAttribute = this.getStepAttribute();


    if (this.modelService.getTypeName(stepAttribute) === AbstractRuntimeModelService.CHOICE) {
      this.createModelFromChoice(stepAttribute);
    } else {
      (async () => {
        await this.createModelFromEntity(stepAttribute);
      })();
    }
  }

  /**
   * execute the phase script with all required parameters
   */
  private runProgressScript<T>(query?: CriteriaQuery<any>,
                               customList?: T[]): MaybePromise<any> {
    if (!this.phaseProgressValueScript) {
      return customList;
    }
    // might be used on normal lists, too. so there is not current Record, we are cheating here, otherwise we are abusing the module structure
    return this.circularService.scriptExecutor.runScript(this.phaseProgressValueScript, {
      records: customList,
      record: this.data,
      query
    }, undefined, `Steps:${this.entityName}:phaseProgressValueScript`).result;
  }

  private async createModelFromEntity(stepAttribute: Relation): Promise<void> {
    // create query, apply filter script and get results
    const query = this.criteriaFactory.getFrontendQuery<Picklist>(stepAttribute.targetId, CriteriaType.SELECT);
    query.addOrder('ordnum');

    const result = this.runProgressScript(query);
    if (_.isPromise(result)) {
      await result;
    }

    const entities = await query.getResults();

    for (const entity of entities) {
      this.model.push({
        label: this.modelTranslationService.translateObjectValue(entity, 'representativeString', this.translateService.currentLang) as string,
        icon: entity.icon,
        color: entity.color
      } as any);
    }
    await this.calcEntityIndex(entities);
  }

  private async calcEntityIndex(picklists: Picklist[]): Promise<void> {
    const value: any = await this.data[this.phaseProgressValueField];
    this.index = picklists.findIndex(x => x.id === value?.id);
    this.cdr.detectChanges();
  }

  private createModelFromChoice(stepAttribute: Attribute): void {
    const choiceId: string = stepAttribute.typeId;
    const choice = this.modelService.getChoice(choiceId);
    this.choiceConverter.init(choice);
    let choiceValues = choice.values;
    _.maybeAwait(this.runProgressScript(undefined, choiceValues),
      (x) => {
        choiceValues = (typeof x[0] === 'string' ? this.choiceConverter.getAsObject(x) : x);

        for (const choiceValue of choiceValues) {
          this.model.push({
            label: this.modelTranslationService.translateChoiceValue(choiceValue, choiceId),
            icon: choiceValue.icon,
            color: choiceValue.color
          } as any);
        }
        this.calcChoiceIndex(choiceValues);
      });
  }

  private calcChoiceIndex(choiceValues: ChoiceValue[]): void {
    const value: any = this.data[this.phaseProgressValueField];
    this.index = choiceValues.findIndex(x => x.value === value);
    this.cdr.detectChanges();
  }
}
