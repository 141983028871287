import {Injectable} from '@angular/core';
import {AbstractKolibriSecurityService} from '@wspsoft/frontend-backend-common';

@Injectable()
export class KolibriSecurityService implements AbstractKolibriSecurityService {

  /**
   * @see AbstractKolibriSecurityService.calcHash
   */
  public async calcHash(s: string): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(s);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  /**
   * @see AbstractKolibriSecurityService.calcMd5Hash
   */
  public async calcMd5Hash(s: string): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(s);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('MD5', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
}
