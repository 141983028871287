import {_} from '@wspsoft/underscore';
import {KolibriEntity} from '../../../model/database/kolibri-entity';
import {SecurityHandler} from './security-handler';

export enum EnhancementLevel {
  First, Second, Third, Variable
}

export const transientFields: string[] = ['_rev', '_id', '_key', '_securityInfo', 'persisted', 'deleted', 'localExports'];

export abstract class TransientHandler<T extends KolibriEntity> extends SecurityHandler<T> {
  public enhanced: EnhancementLevel = EnhancementLevel.First;

  protected constructor(public record: T, securityInfo: any) {
    super(record, securityInfo);

    this.record.localExports ??= {};
  }

  public toJSON(): any {
    const cloneProperties = _.cloneProperties(this, true);

    for (const key of Object.keys(cloneProperties)) {
      if (cloneProperties[key]?.toJSON) {
        cloneProperties[key] = this[key].toJSON();
      }
    }

    return cloneProperties;
  }

  public keys(): string[] {
    return Object.keys(this.record);
  }
}

for (const i of transientFields) {
  Object.defineProperty(TransientHandler.prototype, i, {
    get() {
      return this.record[i];
    },
    set(v) {
      this.record[i] = v;
    },
    enumerable: true
  });
}
