import {CriteriaQuery} from '../criteria/criteria-query';
import {KolibriEntity} from '../model/database/kolibri-entity';
import {Layout} from '../model/xml/layout';
import {AbstractSessionService} from '../service/util/abstract-session.service';
import {AbstractKolibriScriptContext} from './abstract-kolibri-script-context';
import {ScriptParams, ScriptResult} from './script-objects';

export abstract class AbstractKolibriScriptExecutor {
  protected constructor(protected context: AbstractKolibriScriptContext, protected sessionService: AbstractSessionService) {
  }

  /**
   * instantly return boolean values to improve performance
   */
  public static instantScriptEval(script: string): ScriptResult<any> {
    if (!script || script === 'true' || script === 'false' || typeof script === 'boolean') {
      return {
        usedFields: {},
        // @ts-ignore
        result: typeof script === 'boolean' ? script : (script === 'true' || !script)
      };
    }
  }

  /**
   * get the current layout able component
   */
  private static getLayoutComponent(formDatum): { layout: Layout; viewMode: number } {
    return formDatum.currentList ||
      formDatum.currentForm ||
      formDatum.currentFullCalendar ||
      formDatum.currentLayoutMap ||
      formDatum.currentWebsite ||
      formDatum.currentLayoutGraph;
  }

  /**
   * detect view mode of layout and add this data, also add info about the layout itself
   */
  private static getRootCauseForLayout(currentLayout: { layout: Layout; viewMode: number }, formDatum: any, cause: string[]): void {
    if (currentLayout) {
      // detect view mode and add this info
      switch (currentLayout.viewMode) {
        case 1:
          if (formDatum.scriptWizard) {
            cause.push('Wizard', formDatum.scriptWizard.name);
          } else {
            cause.push('Dialog');
          }
          break;
        case 2:
          cause.push('PageRight');
          break;
        case 3:
          cause.push('Nested');
          break;
        case 4:
          cause.push('Widget');
          break;
        case 0:
        case 5:
          // full page und presentation
          break;
      }

      cause.push(currentLayout.layout.entityClass, currentLayout.layout.name);
    }
  }

  public runScript<T>(script: string, params: ScriptParams, formId?: string, rootCause?: string, allowErrors?: boolean,
                      forceSync: boolean = false): ScriptResult<T> {
    const instantScriptResult = AbstractKolibriScriptExecutor.instantScriptEval(script);
    if (!!instantScriptResult) {
      return instantScriptResult;
    }

    const cause = [rootCause];

    if (formId) {
      cause.unshift(this.getRootCause(formId));
    }

    return this.context.runScript(script, {
      ...this.getScriptParams(formId), ...params
    }, formId, cause.join(':'), allowErrors, forceSync);
  }

  public evalScriptButton(formId: string, script: string, subrootCause: string, additionalParams?: ScriptParams): ScriptResult<void> {
    const params = {
      ...additionalParams,
    };
    return this.runScript((script || ''), params, formId, `Button:${subrootCause}`);
  }

  public evalOnChange(formId: string, script: string,
                      oldValue: any, newValue: any, rootCause: string): ScriptResult<void> {
    if (!script) {
      script = '';
    }

    return this.runScript(script, {
      oldValue,
      newValue
    }, formId, `${rootCause}:onChange`);
  }

  public evalValue<T>(formId: string, script: string, rootCause: string): ScriptResult<T> {
    return this.runScript(script, {}, formId, rootCause);
  }

  public evalCondition<T extends KolibriEntity>(formId: string, script: string, rootCause: string, records: T[] = [],
                                                additionalParams?: ScriptParams): ScriptResult<boolean> {
    return this.runScript(script, {
      records,
      ...additionalParams,
    }, formId, rootCause);
  }

  /**
   * runs a filter script
   *
   * @param formId the id of the form
   * @param script the script to execute
   * @param rootCause the script rootCause for debugging
   * @param query the query to modify
   * @param customList records attribute in script
   * @param queryString used entered query value
   */
  public evaluateFilterScript<T>(formId: string, script: string, rootCause: string, query?: CriteriaQuery<any>,
                                 customList?: T[], queryString?: string): ScriptResult<T[]> {
    if (!script) {
      script = customList ? 'return records' : 'return;';
    }
    // might be used on normal lists, too. so there is no current Record
    return this.runScript(script, {
      records: customList,
      query,
      data: queryString
    }, formId, `${rootCause}:filterScript`);
  }

  public evalOnSubmit(formId: string, script: string, rootCause: string, params: ScriptParams = {}): ScriptResult<boolean> {
    script += '\n return true';
    return this.runScript(script, params, formId, `${rootCause}:onSubmit`);
  }

  public clearLocalStorage(id: string): void {
    delete this.context.exports.local[id];
  }

  /**
   * calculate default script params
   */
  private getRootCause(formId?: string): string {
    const formDatum = this.sessionService.viewData[formId] || {};
    const recordParent = this.sessionService.viewData[formDatum.parentFormId] || {};
    const currentLayout = AbstractKolibriScriptExecutor.getLayoutComponent(formDatum);
    const parentLayout = AbstractKolibriScriptExecutor.getLayoutComponent(recordParent);
    const cause: string[] = [];
    AbstractKolibriScriptExecutor.getRootCauseForLayout(parentLayout, formDatum, cause);
    AbstractKolibriScriptExecutor.getRootCauseForLayout(currentLayout, formDatum, cause);

    return cause.join(':');
  }

  /**
   * calculate default script params
   */
  private getScriptParams(formId?: string): Partial<ScriptParams> {
    const formDatum = this.sessionService.viewData[formId] || {};
    const recordParent = this.sessionService.viewData[formDatum.parentFormId] || {};
    return {
      record: formDatum.currentRecord,
      layout: AbstractKolibriScriptExecutor.getLayoutComponent(formDatum)?.layout,
      recordOld: formDatum.currentRecord?.recordOld,
      recordParent: recordParent.currentRecord,
      wizard: formDatum.scriptWizard || recordParent.scriptWizard,
      kf: formDatum.currentForm?.scriptForm || recordParent.currentForm?.scriptForm,
      kolibriList: formDatum.currentList?.scriptList || recordParent.currentList?.scriptList,
      kolibriCalendar: formDatum.currentFullCalendar?.calendarComponent?.getApi() || recordParent.currentFullCalendar?.calendarComponent?.getApi(),
      oneGraph: formDatum.currentLayoutGraph?.scriptGraph || recordParent.currentLayoutGraph?.scriptGraph,
      oneWebsite: formDatum.currentWebsite?.scriptWebsite || recordParent.currentWebsite?.scriptWebsite,
      data: formDatum.data,
      dialog: formDatum.scriptDialog
    };
  }
}
