import {MaybePromise} from '@wspsoft/underscore';
import {DesignerEntity} from './designer.entity';

export enum ButtonType {
  SUBMIT = 'submit', DELETE = 'delete', BACK = 'back', URL = 'url', SCRIPT = 'script', DIALOG = 'dialog',
  REDIRECT_PAGE = 'redirectPage', WIZARD = 'wizard'
}

export enum ButtonPosition {
  FAB = 'fab', MENU = 'menu', ROW_ACTION = 'row action', TRAY = 'tray', TOOLBAR = 'toolbar', CANCEL = 'cancel'
}

export enum DisplayButtonType {
  PRIMARY = 'primary',
  CANCEL = 'cancel',
  OTHER = 'other',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  ICON_ONLY = 'icon-only',
  ICON_RIGHT = 'icon-right',
  LAYOUT_BUTTON = 'layout',
  INLINE = 'inline',
  FAB = 'fab',
  FAB_ITEM = 'fab-item',
  TRAY = 'tray',
  SNIPPET_RIGHT = 'snippet-right',
  SNIPPET_LEFT = 'snippet-left',
  TAB = 'tab',
  TAB_ICON_ONLY = 'tab-icon-only',
  TAB_RIGHT = 'tab-right',
  TAB_LEFT = 'tab-left',
  TAB_UP = 'tab-left',
  FORM = 'form'
}

export abstract class Button extends DesignerEntity {
  public hotkeys?: string;
  public entityId?: string;
  public parentId?: string;

  public layoutId?: string;
  public afterAction?: AfterButtonAction;
  public afterActionScript?: any;
  public submitEnabled?: boolean;
  public position?: ButtonPosition[];
  public type?: ButtonType;
  public scriptType?: 'client-side' | 'server-side';
  public displayButtonType?: DisplayButtonType[];
  public script?: any;
  public condition?: string;
  public readonly?: string;
  public skipLeaveMessage?: boolean;
  public children?: Button[];

  public command?: (data: any) => MaybePromise<void>;
}

export enum AfterButtonAction {
  VIEW = 'VIEW', SCRIPT = 'SCRIPT', RELOAD = 'RELOAD', BACK = 'BACK'
}
