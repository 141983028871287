import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {
  AbstractBackendSessionService,
  OneHistory,
  OneLayoutState,
  OneMenuState,
  OnePageRightState,
  OneTableState,
  RecordDiff,
  User,
  UserHotkeyOverwriteData,
  UserProfile
} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class BackendSessionService extends AbstractService implements AbstractBackendSessionService {
  private static readonly MAX_RECENT_GLOBAL_SEARCHES: number = 20;

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/portal/session';
  }

  public changeAvatar(attachmentId: string): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<void>(`${this.basePath}/changeAvatar/${attachmentId}`, {}, queryParameters, headers);
  }

  public changeActiveProfile(profile: UserProfile): Promise<void> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPut<void>(`${this.basePath}/activeProfile`, profile, queryParameters, headers);
  }

  public saveCurrentDashboard(dashboardId: string, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.currentDashboardId = dashboardId;

    void this.doPost<void>(`${this.basePath}/currentDashboard/${dashboardId}`, {}, queryParameters, headers);
  }

  /**
   * @see AbstractBackendSessionService.saveRecentGlobalSearch
   */
  public saveRecentGlobalSearch(query: string, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    const globalSearches = user.activeProfile.sessionSettings.globalSearches;
    _.remove(globalSearches, {query});
    globalSearches.unshift({query});
    if (globalSearches.length > BackendSessionService.MAX_RECENT_GLOBAL_SEARCHES) {
      globalSearches.pop();
    }

    void this.doPost<void>(`${this.basePath}/globalSearches`, {query}, queryParameters, headers);
  }

  public saveTableState(tableId: string, tableState: OneTableState, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.tableStates[tableId] = tableState;

    void this.doPost<void>(`${this.basePath}/tableState/${tableId}`, tableState, queryParameters, headers);
  }

  public saveLeftMenuState(leftMenuState: OneMenuState, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.leftMenuState = leftMenuState;

    void this.doPost<void>(`${this.basePath}/leftMenuState`, leftMenuState, queryParameters, headers);
  }

  public saveUserHotkeyOverwrites(userHotkeyOverwrites: UserHotkeyOverwriteData, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.userHotkeyOverwrites = userHotkeyOverwrites;

    void this.doPost<void>(`${this.basePath}/userHotkeyOverwrites`, userHotkeyOverwrites, queryParameters, headers);
  }

  public saveOneHistory(oneHistory: OneHistory, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.oneHistory = oneHistory;

    void this.doPost<void>(`${this.basePath}/oneHistory`, oneHistory, queryParameters, headers);
  }

  public saveLayoutState(layoutName: string, tabState: OneLayoutState, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.layoutStates[layoutName] = tabState;

    if (tabState && tabState.tabStates) {
      tabState.tabStates = Object.values(tabState.tabStates).reduce((acc, current) => {
        if (current && !current.tabState) {
          acc[current.tabId] = current;
        }
        return acc;
      }, {});
    }

    void this.doPut<void>(`${this.basePath}/layoutState/${layoutName}`, tabState, queryParameters, headers);
  }

  public savePageRightState(layoutName: string, state: OnePageRightState, user: User): void {
    const {queryParameters, headers} = this.getHeaders();

    user.activeProfile.sessionSettings.pageRightStates[layoutName] = state;

    void this.doPut<void>(`${this.basePath}/pageRightState/${layoutName}`, state, queryParameters, headers);
  }

  public updateUser(changes: RecordDiff[]): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();
    return this.doPut<any>(`${this.basePath}/updateUser`, changes,
      queryParameters, headers);
  };
}
