import {EntityModel} from '../../service/entities/entity-model';
import {Field} from '../response/field';
import {DesignerEntity} from './designer.entity';
import {Entity} from './entity';

export enum Analyzer {
  Identity = 'identity', Normalize = 'normalize'
}

export abstract class ViewEntityToField extends DesignerEntity {
  public entity?: Entity;
  public entityId?: string;
  public field?: Field;
  public fieldId?: string;
  public analyzers?: Analyzer[];
  public displayField?: boolean;
  public subFields?: string[];
}

export abstract class ViewToEntity extends DesignerEntity {
  public view?: View;
  public viewId?: string;
  public viewEntityToFields?: ViewEntityToField[];
  public entity?: Entity;
  public entityId?: string;
  public includeActivityLogs?: boolean;
}

export abstract class View extends DesignerEntity {
  public viewToEntities?: ViewToEntity[];
  public descendantEntity?: EntityModel;
}
