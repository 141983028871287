<image-cropper (imageCropped)="imageCropped($event)" [aspectRatio]="aspectRatio" [canvasRotation]="canvasRotation" [containWithinAspectRatio]="true"
               [imageFile]="image" [maintainAspectRatio]="maintainAspectRatio" [onlyScaleDown]="false" [resizeToHeight]="300" [roundCropper]="roundCropper"
               [transform]="transform" format="png"></image-cropper>
<ui-button (clicked)="onCrop.emit();" [label]="'ImageCropper.Apply' | translate" [spinner]="false" [type]="['layout']" icon="fas fa-fw fa-check"></ui-button>
<ng-container *ngIf="enableZoom">
  <ui-button (clicked)="zoomIn()" [label]="'ImageCropper.ScaleUp' | translate" [spinner]="false" [type]="['layout']"
             icon="fas fa-fw fa-search-plus"></ui-button>
  <ui-button (clicked)="zoomOut();" [label]="'ImageCropper.ScaleDown' | translate" [spinner]="false" [type]="['layout']"
             icon="fas fa-fw fa-search-minus"></ui-button>
  <ui-button (clicked)="rotateLeft()" [label]="'ImageCropper.RotateLeft' | translate" [spinner]="false" [type]="['layout']"
             icon="fas fa-fw fa-undo"></ui-button>
  <ui-button (clicked)="rotateRight()" [label]="'ImageCropper.RotateRight' | translate" [spinner]="false" [type]="['layout']"
             icon="fas fa-fw fa-redo"></ui-button>
</ng-container>
