export enum SectionTypes {
  TAB_LAYOUT_SECTION = 'TabLayoutSection',
  STANDARD_LAYOUT_SECTION = 'StandardLayoutSection',
  RELATED_LIST_LAYOUT_SECTION = 'RelatedListLayoutSection',
  VARIABLES_LAYOUT_SECTION = 'VariablesLayoutSection',
  WIDGET_LAYOUT_SECTION = 'WidgetLayoutSection',
  NESTED_LAYOUT_SECTION = 'NestedLayoutSection',
  ATTACHMENT_LAYOUT_SECTION = 'AttachmentLayoutSection'
}

export enum FieldTypes {
  TEXTAREA_SECTION_FIELD = 'TextareaSectionField',
  CUSTOM_WEB_COMPONENT_SECTION_FIELD = 'CustomWebComponentSectionField',
  FIELD_VALUE_SELECTOR_SECTION_FIELD = 'FieldValueSelectorSectionField', CONDITION_BUILDER_SECTION_FIELD = 'ConditionBuilderSectionField',
  LOW_CODE_EDITOR_SECTION_FIELD = 'LowCodeEditorSectionField',
  BUTTON_SECTION_FIELD = 'ButtonSectionField', ICON_AUTO_COMPLETE_SECTION_FIELD = 'IconAutocompleteSectionField', INPUT_SECTION_FIELD = 'InputSectionField',
  INPUT_NUMBER_SECTION_FIELD = 'InputNumberSectionField', GALLERY_SECTION_FIELD = 'GallerySectionField',
  FILE_UPLOAD_SECTION_FIELD = 'FileUploadSectionField', FIELD_AUTO_COMPLETE_SECTION_FIELD = 'FieldAutocompleteSectionField',
  ENTITY_AUTOCOMPLETE_SECTION_FIELD = 'EntityAutocompleteSectionField', DATE_PICKER_SECTION_FIELD = 'DatePickerSectionField',
  DURATION_PICKER_SECTION_FIELD = 'DurationPickerSectionField',
  ENTITY_MULTI_SELECT_AUTOCOMPLETE_SECTION_FIELD = 'EntityMultiSelectAutocompleteSectionField', CHOICE_OPTION_SECTION_FIELD = 'ChoiceOptionSectionField',
  COLOR_PICKER_SECTION_FIELD = 'ColorPickerSectionField', PICKLIST_SECTION_FIELD = 'PicklistSectionField',
  CHOICE_AUTO_COMPLETE_SECTION_FIELD = 'ChoiceAutocompleteSectionField', CHECKBOX_SECTION_FIELD = 'CheckboxSectionField',
  CUSTOM_LIST_AUTOCOMPLETE_SECTION_FIELD = 'CustomListAutocompleteSectionField', PASSWORD_SECTION_FIELD = 'PasswordSectionField'
}
