import {EntityServiceOptions, EntityWriteOptions, User, Utility} from '..';

import {KolibriEntity} from '../model/database/kolibri-entity';

import {AbstractEntityServiceFactory} from '../service/generated/abstract-entity-service-factory';


export abstract class AbstractKolibriRecordFactory {
  protected constructor(protected serviceFactory: AbstractEntityServiceFactory) {
  }

  /**
   * mass inserts a list of records
   *
   * @param entityName the target entity for the given data
   * @param records    the target records to insert
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<T[]>
   */
  public massInsert<T extends KolibriEntity>(entityName: string, records: T[], options?: EntityServiceOptions & EntityWriteOptions,
                                             user?: User): Promise<T[]> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.createEntity(records, options);
  }

  /**
   * inserts a specific record
   *
   * @param entityName the target entity for the given data
   * @param record     the target record to insert
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<T>
   */
  public insert<T extends KolibriEntity>(entityName: string, record: T, options?: EntityServiceOptions & EntityWriteOptions, user?: User): Promise<T> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.createEntity(record, options);
  }

  /**
   * mass updates a list of records
   *
   * @param entityName the target entity for the given data
   * @param records    the target records to update
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<T[]>
   */
  public massUpdate<T extends KolibriEntity>(entityName: string, records: T[], options?: EntityServiceOptions & EntityWriteOptions,
                                             user?: User): Promise<T[]> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.updateEntity(records, options);
  }

  /**
   * updates a specific record
   *
   * @param entityName the target entity for the given data
   * @param record     the target record to update
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<T>
   */
  public update<T extends KolibriEntity>(entityName: string, record: T, options?: EntityServiceOptions & EntityWriteOptions, user?: User): Promise<T> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.updateEntity(record, options);
  }

  /**
   * mass delete a list of records
   *
   * @param entityName the target entity for the given data
   * @param records    the target records to delete
   * @param hard       decision to soft or hard delete
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<void>
   */
  public massDelete<T extends KolibriEntity>(entityName: string, records: T[], hard?: boolean, options?: EntityServiceOptions & EntityWriteOptions,
                                             user?: User): Promise<void> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.deleteEntities(records.map(e => e.id), options, hard);
  }

  /**
   * deletes a specific record
   *
   * @param entityName the target entity for the given data
   * @param record     the target record to delete
   * @param hard       decision to soft or hard delete
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return Promise<void>
   */
  public delete<T extends KolibriEntity>(entityName: string, record: T, hard?: boolean, options?: EntityServiceOptions & EntityWriteOptions,
                                         user?: User): Promise<void> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    return service.deleteEntity(record.id, options, hard);
  }

  /**
   * returns a fetched entity with given id or if not present creates a new shell
   *
   * @param entityName the target entity for the given data
   * @param uuid       the id to search for a specific record
   * @param options    various special entity service options
   * @param user       a specific user to inject if needed
   * @return T
   */
  // eslint-disable-next-line require-await
  public async get<T extends KolibriEntity>(entityName: string, uuid?: string, options?: EntityServiceOptions, user?: User): Promise<T> {
    const service = this.serviceFactory.getService<T>(entityName, user, options);
    if (uuid) {
      return service.getEntityById(uuid);
    } else {
      return service.getNewEntity();
    }
  }

  /**
   * returns an enhanced record which means that it gets a handful of special abilities
   *
   * @param entityNameOrId the target entity for the given data
   * @param record         the json like data structure to enhance
   * @param user           a specific user to inject if needed
   * @return T
   */
  public enhance<T extends KolibriEntity>(entityNameOrId: string, record: T = {} as T, user?: User): T {
    const service = this.serviceFactory.getService<T>(entityNameOrId, user);
    const enhancedVersion = service.entityEnhancer.firstLevelEnhancing({
      entityClass: service.entityMeta.name
    } as T, false);
    enhancedVersion.merge(record);
    enhancedVersion.recordOld = enhancedVersion;
    return enhancedVersion;
  }

  /**
   * loads dot walk relations in bulk
   */
  public async getEntityRelations<T extends KolibriEntity>(entities: T[], nameWithDotWalk: string, user?: User): Promise<T[]> {
    if (!entities.length) {
      // @ts-ignore
      return entities;
    }

    const service = this.serviceFactory.getService<T>(entities[0].entityClass, user);
    const relations = await service.getEntityRelations<T>(entities, nameWithDotWalk);

    for (let i = 0; i < relations.length; i++) {
      // fill caches for every object
      Utility.doDotWalk(entities[i], nameWithDotWalk, () => undefined, relations[i]);
    }
    return relations;
  }
}
