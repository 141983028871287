import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AccessControl, AccessControlType, ChoiceValue, CriteriaOperator, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {SelectItem} from 'primeng/api';
import {CriteriaFactory, ModelService, ModelTranslationService} from '../../../../../../../api';
import {Converter} from '../../../../converter/converter';
import {CustomWriter} from '../../../custom-writer';

@Component({
  selector: 'ui-acl-selector',
  templateUrl: './acl-selector.component.html',
  styleUrls: ['./acl-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AclSelectorComponent extends CustomWriter<AccessControl> implements OnInit {
  public static VALID_TYPES = [AccessControlType.entity, AccessControlType.custom, AccessControlType.share, AccessControlType.tenancy];
  @Input()
  public disable: boolean;
  public operationConverter: Converter<SelectItem>;
  public entityConverter: Converter<SelectItem>;
  private possibleCrudOperations: SelectItem[] = [];
  private possibleShareOperations: SelectItem[] = [];

  public constructor(cdr: ChangeDetectorRef, private modelService: ModelService,
                     private modelTranslationService: ModelTranslationService, private criteriaFactory: CriteriaFactory) {
    super(cdr);

    this.entityConverter = {
      getAsObject(stringToConvert: string): SelectItem {
        if (!stringToConvert) {
          return;
        }

        if (stringToConvert === '*') {
          return {label: '*', value: '*'};
        }
        const entity = modelService.getEntity(stringToConvert);
        return {
          label: modelTranslationService.translateEntity(entity),
          value: entity.name,
          icon: entity.icon,
          color: entity.color
        } as any;
      },
      getAsString(objectToConvert: SelectItem): string {
        return objectToConvert?.value;
      }
    } as Converter<SelectItem>;

    this.operationConverter = {
      getAsObject: (stringToConvert: string): SelectItem => {
        const crudOperation = _.find(this.possibleCrudOperations, {value: stringToConvert});
        if (crudOperation) {
          return crudOperation;
        }
        const shareOperation = _.find(this.possibleShareOperations, {value: stringToConvert});
        if (shareOperation) {
          return shareOperation;
        }
        return {label: stringToConvert, value: stringToConvert, icon: 'fas fa-fw fa-user'};
      },
      getAsString(objectToConvert: SelectItem): string {
        return objectToConvert?.value;
      }
    };
  }

  public ngOnInit(): void {
    this.valueBinding ??= {
      type: null,
      operation: null,
      resource: null
    };
    this.setupOperationSelectItems();
  }

  /**
   * set for valid types
   *
   * we are excluding layout and menu item for now, these shouldn't be relevant for the acl
   */
  public searchTypes(types: ChoiceValue[]): ChoiceValue[] {
    return types.filter(type => AclSelectorComponent.VALID_TYPES.includes(type.value as AccessControlType));
  }

  /**
   * search for available operations when the user uses the ac
   */
  public async searchOperations(values: SelectItem[], query: string): Promise<SelectItem[]> {
    if (this.value.type === AccessControlType.custom) {
      const customAcls = await this.criteriaFactory.get<AccessControl>('AccessControl')
        .and('type', CriteriaOperator.EQUAL, AccessControlType.custom)
        .addPercentBasedCondition('operation', query)
        .getResults();

      return _.sortBy(_.flatMap(customAcls, acl => acl.operation.map(operation => ({
        label: operation,
        value: operation,
        icon: 'fas fa-fw fa-user'
      }))), 'label');
    }
    if (this.value.type === AccessControlType.share) {
      return this.possibleShareOperations.filter(operation => Utility.matches(operation.label, query));
    }
    return this.possibleCrudOperations.filter(
      operation => (this.value.type === AccessControlType.entity ? true : operation.value !== 'GLOBAL_SEARCH') && Utility.matches(
        operation.label, query));
  }

  /**
   * search for possible resources for the type
   * right now only entities are supported
   */
  public searchResources(resources: SelectItem[], query: string): SelectItem[] {
    const entities = this.modelService.getEntities();

    for (const entity of entities) {
      entity.label = this.modelTranslationService.translateEntity(entity);
    }

    return [{label: '*', value: '*'}, ...entities
      .filter(entity => Utility.matches(entity.label, query)).map(entity => ({
        label: entity.label,
        value: entity.name,
        icon: entity.icon,
        color: entity.color
      }))];
  }

  public forceUpdate(): void {
    this.value = this.value;
    super.forceUpdate();
  }

  /**
   * convert the available operations of the choice to select items
   */
  private setupOperationSelectItems(): void {
    const accessControlOperation = this.modelService.getChoice('AccessControlOperation');
    const operations = accessControlOperation.values;
    const shareOperation = this.modelService.getChoice('AccessControlShareOperation');
    const shareOperations = shareOperation.values;

    // translate operations
    for (const operation of operations) {
      operation.label = this.modelTranslationService.translateChoiceValue(operation, accessControlOperation.name);
    }
    for (const operation of shareOperations) {
      operation.label = this.modelTranslationService.translateChoiceValue(operation, shareOperation.name);
    }

    this.possibleCrudOperations = _.sortBy(operations, 'label') as any;
    this.possibleShareOperations = _.sortBy(operations.filter(x => x.value !== 'GLOBAL_SEARCH').concat(shareOperations), 'label') as any;
  }
}
