import {ScriptCallable} from '../../api/script-objects';
import {DesignerEntity} from './designer.entity';

export abstract class Script extends DesignerEntity {
  public entityId?: string;
  public layoutId?: string;
  public trigger?: ScriptTrigger;
  public actions?: ScriptAction[];
  public callable?: ScriptCallable;
  public type?: ScriptType;
  public payload?: any;
  public conditionScript?: string;
  public tsPayload?: string;
  public scope?: string;
  public fields?: string;
  public fieldsOnly?: boolean;
}

export enum ScriptType {
  SERVER_SIDE = 'server-side', CLIENT_SIDE = 'client-side', BOTH = 'both'
}

export enum ScriptAction {
  INSERT = 'insert', UPDATE = 'update', DELETE = 'delete', DISPLAY = 'display', SEARCH = 'search', AUTH = 'auth'
}


export enum ScriptTrigger {
  ON_BEFORE = 'onBefore', ON_AFTER = 'onAfter', ON_FIELD_CHANGE = 'onFieldChange', ON_VALIDATION = 'onValidation',
  ON_PAGE_LOAD = 'onPageLoad', ON_LOGIN = 'onLogin', ON_FRAMEWORK_INIT = 'onFrameworkInit', ON_FRAMEWORK_LOAD = 'onFrameworkLoad'
}
