import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CriteriaConditionJson, CriteriaOperator, Entity, Field} from '@wspsoft/frontend-backend-common';

@Component({
  selector: 'ui-condition-overlay',
  templateUrl: './condition-overlay.component.html',
  styleUrls: ['./condition-overlay.component.scss']
})
export class ConditionOverlayComponent implements AfterViewInit {
  @Input()
  public multiple: boolean = false;
  @Output()
  public selectedEntityChanged: EventEmitter<Entity> = new EventEmitter<Entity>();
  @Input()
  public entities: Entity[];
  @Input()
  public entityOverlayVisible: boolean = false;
  @Output()
  public selectedFieldChanged: EventEmitter<Field> = new EventEmitter<Field>();
  @Input()
  public fields: Field[];
  @Input()
  public fieldsOverlayVisible: boolean = false;
  @Output()
  public selectedOperatorChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public operators: string[];
  @Input()
  public operatorOverlayVisible: boolean = false;
  @Output()
  public selectedValueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  public allValues: string[];
  @Input()
  public valuesOverlayVisible: boolean = false;
  public loaded: boolean = false;
  @Output()
  public valueChanged: EventEmitter<CriteriaConditionJson<CriteriaOperator>> = new EventEmitter<CriteriaConditionJson<CriteriaOperator>>();
  @Output()
  public onDelete: EventEmitter<CriteriaConditionJson<CriteriaOperator>> = new EventEmitter<CriteriaConditionJson<CriteriaOperator>>();

  public constructor(public cdr: ChangeDetectorRef) {
  }

  private pselectedEntity: Entity = {};

  @Input()
  public get selectedEntity(): Entity {
    return this.pselectedEntity;
  }

  public set selectedEntity(value: Entity) {
    this.pselectedEntity = value;
    if (this.loaded) {
      this.selectedEntityChanged.emit(value);
    }
  }

  private pvalue: CriteriaConditionJson;

  @Input()
  public get value(): CriteriaConditionJson {
    return this.pvalue;
  }

  public set value(value: CriteriaConditionJson) {
    this.pvalue = value;
    this.valueChanged.emit(this.pvalue);
  }

  public ngAfterViewInit(): void {
    this.loaded = true;
  }

  public clearCondition(): void {
    this.onDelete.emit(this.value);
  }
}
