import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractKolibriTranslate} from '@wspsoft/frontend-backend-common';
import {Observable} from 'rxjs';
import {ModelTranslationService} from '../../../api';

@Injectable()
export class KolibriTranslate extends AbstractKolibriTranslate {
  public constructor(protected translate: TranslateService, model: ModelTranslationService) {
    super(translate, model);
  }

  /**
   * Allows to reload the lang file from the file
   */
  public reloadLang(lang: string): Observable<any> {
    return this.translate.reloadLang(lang);
  }

  /**
   * Deletes inner translation
   */
  public resetLang(lang: string): void {
    return this.translate.resetLang(lang);
  }
}
