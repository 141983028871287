import {Operator, Options, ReleaseType} from 'semver';
import * as semver from 'semver';
import {NormalizeVersion} from './annotations';

export abstract class Semantic {
  /**
   * returns true if version a valid version
   * @param version semantic version
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion([0])
  public static valid(version: string, optionsOrLoose?: Options): boolean {
    try {
      return !!semver.valid(version, optionsOrLoose);
    } catch (e) {
      return false;
    }
  }

  /**
   * returns if v1 is grater than v2
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static gt(v1: string, v2: string, optionsOrLoose?: Options): boolean {
    return semver.gt(v1, v2, optionsOrLoose);
  }

  /**
   * returns if v1 is grater or equal than v2
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static gte(v1: string, v2: string, optionsOrLoose?: Options): boolean {
    return semver.gte(v1, v2, optionsOrLoose);
  }

  /**
   * returns if v1 is less than v2
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static lt(v1: string, v2: string, optionsOrLoose?: Options): boolean {
    return semver.lt(v1, v2, optionsOrLoose);
  }

  /**
   * returns if v1 is less or equal than v2
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static lte(v1: string, v2: string, optionsOrLoose?: Options): boolean {
    return semver.lte(v1, v2, optionsOrLoose);
  }

  /**
   * returns if v1 is equal v2
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static eq(v1: string, v2: string, optionsOrLoose?: Options): boolean {
    return semver.eq(v1, v2, optionsOrLoose);
  }

  /**
   * Pass in a comparison string, and it'll call the corresponding semver comparison function.<br>
   *   “===” and “!==” do simple string comparison, but are included for completeness.<br>
   *   Throws if an invalid comparison string is provided
   * @param v1 semantic version 1
   * @param operator operator like "==="
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion([0, 2])
  public static cmp(v1: string, operator: Operator, v2: string, optionsOrLoose?: Options): boolean {
    return semver.cmp(v1, operator, v2, optionsOrLoose);
  }

  /**
   * compares two versions excluding build identifiers (the bit after + in the semantic version string).
   * Sorts in ascending order when passed to Array.sort().
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   * @param optionsOrLoose semver options
   */
  @NormalizeVersion()
  public static compare(v1: string, v2: string, optionsOrLoose?: Options): 1 | 0 | -1 {
    return semver.compare(v1, v2, optionsOrLoose);
  }

  /**
   * Returns difference between two versions by the release type (major, premajor, minor, preminor, patch, prepatch, or prerelease),
   *   or null if the versions are the same.
   * @param v1 semantic version 1
   * @param v2 semantic version 2
   */
  @NormalizeVersion()
  public static diff(v1: string, v2: string): ReleaseType {
    return semver.diff(v1, v2);
  }

  /**
   * Returns cleaned (removed leading/trailing whitespace, remove '=v' prefix) and parsed version, or null if version is invalid.
   * @param version version to clean
   * @param optionsOrLoose semver options
   */
  public static clean(version: string, optionsOrLoose?: Options): string {
    return semver.clean(version, optionsOrLoose);
  }

}
