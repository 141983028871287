import {AbstractControl, FormGroup} from '@angular/forms';
import {AbstractRuntimeModelService, Button, KolibriEntity, LayoutToButton, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {EntityService, ModelService} from '../../../api';
import {DatatableColumn} from '../components/structure/datatable/datatable/datatable.component';
import {LayoutViewMode} from '../entities/layout-view-mode';

export enum ButtonHotkeyWeightOffset {
  Input = 0, System = 5, Form = 7
}

export abstract class UiUtil {
  public static loadPugVariable<T = any>(variable: string, hide: boolean = true): T {
    const result = (window as any)[variable];
    if (hide) {
      delete (window as any)[variable];
      document.getElementById(variable)?.remove();
    }

    return result;
  }

  public static isMobile(width: number = window.innerWidth): boolean {
    return width <= 960;
  }

  public static getColor(variable: string): string {
    return this.getVariable(variable).replace(/\s?#/, '');
  }

  public static getVariable(variable: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue('--' + variable).trim();
  }

  public static getPxFromVariable(variable: string): string {
    const value = this.getVariable(variable);
    if (value.includes('px')) {
      return value;
    } else if (value.includes('rem')) {
      const baseFontSize = this.getVariable('basefont');
      const px = `${parseFloat(baseFontSize) * parseFloat(value)}px`;
      return px;
    }

    return '';
  }

  public static getAllCSSVariables(): string[] {
    return Array.from(document.styleSheets)
      .filter(
        sheet =>
          sheet.href === null || sheet.href.startsWith(window.location.origin)
      )
      .reduce(
        (acc, sheet) =>
          (acc = [
            ...acc,
            ...Array.from(sheet.cssRules).reduce(
              (def, rule) =>
                (def =
                  // @ts-ignore
                  rule.selectorText === ':root'
                    ? [
                      ...def,
                      // @ts-ignore
                      ...Array.from(rule.style).filter((name: string) =>
                        name.startsWith('--')
                      )
                    ]
                    : def),
              []
            )
          ]),
        []
      );
  }


  /**
   * bulk load relations when columns have dot walks
   */
  public static async loadRelationData(value: KolibriEntity[], service: EntityService<KolibriEntity>, fields: string[]): Promise<void> {
    const relations = await _.parallelMap(fields, dotWalkField => service.getEntityRelations(value, dotWalkField));

    for (let fieldIndex = 0; fieldIndex < relations.length; fieldIndex++) {
      const relationsBulk = relations[fieldIndex];
      // fields are submitted to task pool in sequence so result should match
      const column = Utility.wordifyDotWalk(fields[fieldIndex]);
      for (let entityIndex = 0; entityIndex < relationsBulk.length; entityIndex++) {
        const relationValue = relationsBulk[entityIndex];
        const val = value[entityIndex];
        // ids are collected in sequence so result should match, don't pass null otherwise it will try to fetch it again
        if (relationValue !== null) {
          val[column] = relationValue;
        } else if (this.getIdValue(val, fields[fieldIndex]) === null) {
          // only set undefined if there is no id set in value to not override the id
          val[column] = undefined;
        }
      }
    }
  }

  public static isDeadValue<T>(value: T, entity: KolibriEntity, fieldName: string): boolean {
    return _.isNullOrEmpty(value) && !_.isNull(UiUtil.getIdValue(entity, fieldName));
  }

  /**
   * returns the id value of the given relation, there is no check if the field is a relation
   * @param entity the kolibri entity record holding the values
   * @param relationName the relation name that should be used to get the id
   */
  public static getIdValue(entity: KolibriEntity, relationName: string): string {
    if (_.isNullOrEmpty(entity) || _.isNullOrEmpty(relationName)) {
      return null;
    }
    return entity[Utility.parameterizeEntityName(relationName)];
  }

  public static getDotWalkFields(columns: DatatableColumn[]): string[] {
    return _.uniq(columns
      .filter(value => value.typeName === AbstractRuntimeModelService.KOLIBRI_ENTITY ||
        value.typeName === AbstractRuntimeModelService.KOLIBRI_ENTITY_ARRAY ||
        Utility.isDotWalk(value.field))
      .map(value => Utility.isDotWalk(value.field) &&
      value.typeName !== AbstractRuntimeModelService.KOLIBRI_ENTITY &&
      value.typeName !== AbstractRuntimeModelService.KOLIBRI_ENTITY_ARRAY ?
        Utility.getDotWalkPath(value.field).join('.') :
        value.field)
    );
  }

  /**
   * Groups the passed entities intoSelectItemGroups by the specified string. The passed entities are either grouped by the groupBy string when the field is an
   * attribute or grouped by the repString in the case of a relation.
   * Please note: The items in the SelectItemGroups are not real SelectItems. They are the entities themselves.
   * @param entities the entities to group
   * @param groupBy the attribute to group by
   * @param modelService service to get the field
   * @param entityService service to get the Relations in case of a toOneRelation
   * @return the grouped Entities
   */
  public static async groupEntitiesIntoSelectItemGroups(entities: any[], groupBy: string, modelService?: ModelService,
                                                        entityService?: EntityService<any>): Promise<any[]> {
    const field = modelService.getField(entities[0].entityClass, groupBy);
    // if toOneRelation return entities grouped by repString of the Relation
    if (Utility.isToOneRelation(field)) {
      await Utility.bulkDotWalk(entities, groupBy, entityService);
    }
    const groups = _.groupBy(entities, x => {
      const value = x[groupBy];
      return value?.representativeString ?? value;
    });
    return Object.entries(groups).map(([key, value]) => ({
      label: key,
      items: value
    }));
  }

  /**
   * Checks if an object implements the interface 'SelectItemGroup'
   */
  public static isSelectItemGroup(obj: any): boolean {
    if (_.isNull(obj) || typeof obj !== 'object') {
      return false;
    }
    return 'label' in obj && 'items' in obj;
  }

  /**
   * returns a fake event for our logic that should open a page right.
   */
  public static getFakePageRightEventForOs(): Partial<MouseEvent> {
    return this.isOS('Mac') ? {metaKey: true, altKey: true} : {ctrlKey: true, altKey: true};
  }

  /**
   * this function maps the key to the OS and returns whether the mapped key is pressed or not.
   * @param event the event to look for
   * @param key the key to look for
   */
  public static hasKeyPressedForOs(event: MouseEvent | TouchEvent | KeyboardEvent, key: 'Control' | 'Meta' | 'Alt' | 'Shift'): boolean {
    if (!event) {
      return false;
    }
    if (this.isOS('Mac')) {
      // for Mac
      switch (key) {
        case 'Control':
          return event.metaKey; // 'meta';
        case 'Meta':
          return event.ctrlKey; // 'control';
        case 'Shift':
          return event.shiftKey;
        case 'Alt':
        default:
          return event.altKey;
      }
    } else {
      // for other os
      switch (key) {
        case 'Control':
          // @ts-ignore
          return event.ctrlKey; // 'control';
        case 'Meta':
          return event.metaKey; // 'meta';
        case 'Shift':
          return event.shiftKey;
        case 'Alt':
        default:
          return event.altKey;
      }
    }
  }

  public static getHotkeyWeightBasedOnViewMode(viewMode: LayoutViewMode): number {
    switch (viewMode) {
      case LayoutViewMode.DIALOG:
        return 60;
      case LayoutViewMode.PRESENTATION:
      case LayoutViewMode.FULL_PAGE:
        return 40;
      case LayoutViewMode.PAGE_RIGHT:
        return 50;
      case LayoutViewMode.WIDGET:
        return 30;
      case LayoutViewMode.NESTED:
        return 20;
      default:
        return 40;
    }
  }

  /**
   * returns an array of all keys of the given hotkey.
   * It should look like this: 'Control+Alt+Shift+A'.
   * @param hotkey the hotkey string to get the keys from
   */
  public static getKeysFromHotkey(hotkey: string): string[] {
    // split the hotkey by '+' but replaces the Control++ to Control+<<PLUS>> to prevent splitting issues
    return (hotkey ?? '').replace('++', '+<<PLUS>>').split('+').map(key => key.trim().replace('<<PLUS>>', '+'));
  }

  public static isOS(str: 'Mac' | 'Linux' | 'Windows' | 'iOS' | 'Android'): boolean {
    // @ts-ignore
    const platform = navigator.platform; // navigator.userAgentData.platform; not really good supported
    switch (str) {
      case 'Linux':
        return platform.includes('Linux');
      case 'Mac':
        return platform === 'MacIntel';
      case 'iOS':
        return platform === 'iPhone' || platform === 'iPod' || platform === 'iPad';
      case 'Android':
        return platform === 'Android';
      case 'Windows':
        return platform === 'Windows';

    }
  }


  public static markAsDirty(controls: { [p: string]: AbstractControl }): void {
    for (const control of Object.values(controls)) {
      if (control instanceof FormGroup) {
        this.markAsDirty(control.controls);
        if (_.isEmpty(control.controls)) {
          control.markAsDirty();
          control.markAsTouched();
          control.updateValueAndValidity();
        }
      } else {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      }
    }
  }

  /**
   * search for a specific field in the layout and validate it
   */
  public static validateInput(controls: { [p: string]: AbstractControl }, name: string): boolean {
    const control = controls[name];

    if (!control) {
      return Object.values(controls).some(value => value instanceof FormGroup && this.validateInput(value.controls, name));
    }
    if (control instanceof FormGroup) {
      for (const subControl of Object.values(controls)) {
        subControl.markAsDirty();
        subControl.markAsTouched();
        subControl.updateValueAndValidity();
      }
      return control.valid;
    }
  }

  public static getSanitizedButtons(modelService: ModelService, layoutToButtons: LayoutToButton[], layoutName: string): Button[] {
    const buttons = [];
    for (const layoutToButton of layoutToButtons) {
      const button = modelService.getButton(layoutToButton.buttonId);
      if (!button) {
        console.warn(`Connection ${layoutToButton.name} with id ${layoutToButton.id} not found or may be corrupted on layout ${layoutName}`);
        continue;
      }
      buttons.push(button);
    }
    return buttons;
  }

  /**
   * waits for the ifFn to turn true, then executes the optional thenFn.
   * Waits until the given security break milliseconds and sets the timeout for the next try to the given waitingTimeout
   * @param ifFn the function to wait to be true
   * @param thenFn the function to be executed when the ifFn is true (optional)
   * @param elseFn the function to be executed when the securityTimeout is reached (optional)
   * @param securityTimeout the time in milliseconds to wait (ca.) until this function abort
   * @param waitingTimeout the time in milliseconds to wait for the next try (need to be smaller than securityTimeout)
   */
  public static async waitFor(ifFn: () => boolean, thenFn: () => void = () => undefined, elseFn: () => void = () => undefined,
                              securityTimeout: number = 2000, waitingTimeout: number = 10): Promise<void> {
    let securityBreakOn = securityTimeout;
    if (waitingTimeout > 0) {
      // calculate the security break
      securityBreakOn /= waitingTimeout;
    }
    for (let securityCounter = 0; securityCounter < securityBreakOn; securityCounter++) {
      if (await new Promise(resolve => {
        if (ifFn()) {
          thenFn();
          resolve(true);
        } else {
          setTimeout(() => resolve(false), waitingTimeout);
        }
      })) {
        return;
      }
    }
    return elseFn();
  }

  public static waitForScrollEnd(timeout: number = 20): Promise<void> {
    let lastChangedFrame = 0;
    let lastX = window.scrollX;
    let lastY = window.scrollY;

    return new Promise(resolve => {
      function tick(frames: number): void {
        // We requestAnimationFrame either for 500 frames or until 20 frames with
        // no change have been observed.
        if (frames >= 500 || frames - lastChangedFrame > 20) {
          resolve(undefined);
        } else {
          if (window.scrollX !== lastX || window.scrollY !== lastY) {
            lastChangedFrame = frames;
            lastX = window.scrollX;
            lastY = window.scrollY;
          }
          setTimeout(tick.bind(null, frames + 1), timeout);
        }
      }

      tick(0);
    });
  }
}
