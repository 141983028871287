import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomInput} from '../custom-input';

@Component({
  selector: 'ui-inputswitch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputSwitchComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputSwitchComponent extends CustomInput<any> {
  @Input()
  public renderInputGroup: boolean = true;
}
