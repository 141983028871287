import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Optional} from '@angular/core';
import {lastValueFrom} from 'rxjs';
import {environment} from '../../../config/environments/environment';
import {Configuration} from '../configuration';
import {CustomHttpUrlEncodingCodec} from '../encoder';

import {BASE_PATH} from '../variables';

export abstract class AbstractService {
  protected configuration: Configuration = new Configuration();
  private defaultHeaders: HttpHeaders = new HttpHeaders();

  protected constructor(protected httpClient: HttpClient,
                        @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.pbasePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.configuration.withCredentials = true;
      this.pbasePath = basePath || configuration.basePath || this.pbasePath;
    }
  }

  private pbasePath: string = '';

  public get basePath(): string {
    return this.pbasePath;
  }

  public set basePath(value: string) {
    this.pbasePath = value;
  }

  protected getHeaders(contentType: boolean = true): { headers: HttpHeaders; queryParameters: HttpParams } {
    const queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // we need to set the window location as original url in case we have to redirect e.g. timeouts
    headers = headers.set('originalUrl', window.location.href.substring(window.location.origin.length));

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined && contentType) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined && contentType) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return {queryParameters, headers};
  }

  protected doDelete(url: string, queryParameters: HttpParams, headers: HttpHeaders, body?: any): Promise<void> {
    return lastValueFrom(this.httpClient.request<void>('delete', environment.serverAdd + url,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        body
      }
    )) as Promise<void>;
  }

  protected doPut<T>(url: string, body: any = null, queryParameters: HttpParams, headers: HttpHeaders): Promise<T> {
    return lastValueFrom(this.httpClient.put<T>(environment.serverAdd + url,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers
      }
    )) as Promise<T>;
  }

  protected doPost<T>(url: string, body: any = null, queryParameters: HttpParams, headers: HttpHeaders, responseType?: string): Promise<T> {
    // @ts-ignore
    return lastValueFrom(this.httpClient.post<T>(environment.serverAdd + url,
      body,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers, observe: 'body',
        // @ts-ignore
        responseType
      }
    )) as Promise<T>;
  }

  protected doGet<T>(url: string, queryParameters?: HttpParams, headers?: HttpHeaders, responseType?: string): Promise<T> {
    return lastValueFrom(this.httpClient.get<T>(environment.serverAdd + url,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers, observe: 'body',
        // @ts-ignore
        responseType
      }
    )) as Promise<T>;
  }
}
