import * as moment from 'moment';
import {KolibriEntity} from './kolibri-entity';

export class QueryIndex extends KolibriEntity {
  public conditions: string[];
  public indexCreated: boolean;
  public persistent: boolean;
  public expiresOn: string | moment.Moment;
  public entityName: string;
  public usages: number;
  public documentsExpireAfter: number;
  public type: string;
  public name: string;
}
